import { Img, Heading, Button, Text } from "./..";
import { SelectBox } from "components/SelectBox";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import '../../styles/apiCustom.css';



export default function Header({ ...props }) {
  const apiUrl = process.env.REACT_APP_LOCAL_SERVER;
  const navigate = useNavigate();
  const pRef = useRef(null);
  const [update, setUpdate] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [playlistKey, setPlaylistKey] = useState(null);
  const [sliderData, setSliderData] = useState([]);
  const [idArticle, setIdArticle] = useState([]);
  const [dropDownItem, setDropDownItem] = useState([])
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  console.log("asdsfsdfsffdfgdfgfd", dropDownItem);







  const fetchKey = async () => {
    try {
      const response = await fetch(`${apiUrl}/page/page/1?siteId=822`);
      // const response = await fetch(`${apiUrl}/page/1?siteId=785`);
      const jsonResponse = await response.json();
      setPlaylistKey(jsonResponse.data?.playlist?.[0]?.key || null);
      // console.log(jsonResponse.data?.playlist?.[0]?.key, "key");
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  // jsonResponse.data?.playlist?.[1]?.key

  useEffect(() => {
    fetchKey();
  }, [apiUrl]);

  const getDataFromKeys = async () => {
    if (!playlistKey) return;

    try {
      const response = await fetch(`${apiUrl}/playlist/playlist/${playlistKey}?siteId=822`);
      // const response = await fetch(`${apiUrl}/playlist/${playlistKey}?siteId=785`);
      const resContent = await response.json();
      setSliderData(resContent.data?.content?.[0]?.key || []);
      console.log(resContent, "sliderImg");
      // console.log(resContent.data?.content[0]?.key, "id");

    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    if (playlistKey) {
      getDataFromKeys();
    }
  }, [playlistKey]);

  const getDataFromContent = async () => {
    if (!sliderData) return;


    try {
      const response = await fetch(`${apiUrl}/content/content-block/${sliderData}?siteId=822`);
      // http://35.244.47.173:3003/api/frontend/v2/content/articles/66b1cff00bf8835dfe074c01?siteId=785
      // http://35.244.47.173:3003/api/frontend/v2/content-block/66cedc685d2a58eb1d5f06a3?siteId=785

      const resContent = await response.json();
      // setSliderData(resContent.data?.content?.[0].key || []);
      console.log(resContent, "sliderImg");

      const parser = new DOMParser();
      const doc = parser.parseFromString(resContent?.data?.pageContent, 'text/html');
      console.log(doc);


      // Select all <a> tags
      const links = doc.querySelectorAll('a');
      const tags = doc.querySelectorAll('p');
      const classNames = ['classAboutus', 'classOurWork', 'classOurTeam', 'classNewsStories', 'classReports', 'classGetInvolved'];
      console.log(tags);
      tags.forEach((p, index) => {
        if (index < classNames.length) {
          p.className = classNames[index]; // Assign className based on index
        } else {
          p.className = 'default-class'; // Assign a default class if there are more <p> elements than class names
        }
      });

      // Remove the target="_blank" attribute from each <a> tag
      links.forEach(link => {
        link.removeAttribute('target');
        // link.classList.add('home_nav');
      });

      // Serialize the DOM back into a string
      const updatedHtmlString = doc.body.innerHTML;

      console.log(updatedHtmlString);
      setIdArticle(updatedHtmlString);
      setUpdate(1)

    } catch (err) {
      setError(err);
    }
  };

  useEffect(() => {
    if (sliderData) {
      getDataFromContent();
    }
  }, [sliderData]);

  useEffect(() => {
    const targetElement = document.querySelector('p.classOurWork');
    if (targetElement) {
      const newDiv = document.createElement('div');
      newDiv.textContent = 'New Div'; // Optional: Add some text to the new div
      newDiv.id = 'about_dropDown'; // Add an id
      newDiv.className = 'about_dropDown'; // Add a class name
      newDiv.innerHTML = `
             <div class="dropDown_bg_opacity">
                <ul class="dropDown1 ">
                 <li class="enhancing_dropDown">
                 <a rel="noopener noreferrer">Enhancing Sustainable Livelihoods<span> ></span> </a>
                   <ul class="sub_dropDown">
                    <li><a href="/edif/ourwork/climate-smart-agriculture" rel="noopener noreferrer">Agriculture</a></li>
                    <li><a href="/edif/ourwork/livestock" rel="noopener noreferrer">Livestock</a></li>
                    <li><a href="/edif/ourwork/fisheries" rel="noopener noreferrer">Fisheries</a></li>
                   </ul>
                 </li>
                <li><a href="/edif/ourwork/india-climate-leadership" rel="noopener noreferrer">India Climate Leadership</a></li>
                <li><a href="/edif/ourwork/climate-tech-convening" rel="noopener noreferrer">Climate Tech Convening</a></li>
               </ul>
               <span class="bg_opacity"></span>
               </div>
              

                `;

      targetElement.append(newDiv);

    } else {
      console.error('Element with class "two" not found');
    }
  }, [update]);






  // const dropDownContent = async () => {
  //   const dropdownButton = document.getElementById('dropdownButton');
  //   const dropdownContent = document.getElementById('dropdownContent');
  //   try {
  //     const response = await fetch(`${apiUrl}/contents/ourWork/default?siteId=822`);
  //     // const response = await fetch(`${apiUrl}/page/1?siteId=785`);
  //     const jsonResponse = await response.json();     

  //     console.log("dropDownItem", jsonResponse.data);
  //     setDropDownItem(jsonResponse.data.map(item => item.name) || null);
  //     console.log("dropDownItem", jsonResponse.data?.name);

  //     const list = document.getElementById('data-list');

  //     // Iterate over the data and create list items
  //     for (const key in dropDownItem) {
  //         if (dropDownItem.hasOwnProperty(key)) {
  //             const listItem = document.createElement('li');
  //             listItem.textContent = dropDownItem[key];
  //             list.appendChild(listItem);
  //         }
  //     }

  //   } catch (err) {
  //     setError(err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };



  // useEffect(() => {
  //   dropDownContent();
  // }, [apiUrl]);



  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  const handleArticlePage = () => {
    // console.log(currentData, "name");

    // localStorage.setItem('articlePageApi',currentData.id)

    // navigate(`${currentData.category}/articles/${currentData.slug}`)
    // navigate(`/article?id=${currentData.id}&name=${convertedUrlName}`)

  }




  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const HomePageRedirect = () => {
    navigate("/");
    closeMobileMenu(); // Close menu on navigation
  };

  const AboutPageRedirect = () => {
    // navigate('/about');
    navigate('/about-us');
  };

  const TeamPageRedirect = () => {
    // navigate('/ourLeadershipTeam');
    navigate('/board-members');
  };

  const ClimatePageRedirect = () => {
    // navigate('/ourWork/climatesmartagriculture');
    navigate('/our-work');
  };

  const BoardPageRedirect = () => {
    // navigate('/ourBoard');
    navigate('/get-involved');
  };

  const EventsPageRedirect = () => {
    // navigate('/events');
    navigate('/new-and-stories');
  };

  const dropDownOptions = [
    { label: "$10 / Month", value: "$10" },
    { label: "$15 / Month", value: "$15" },
    { label: "$25 / Month", value: "$25" },
    { label: "$50 / Month", value: "$50" },
    { label: "other", value: "other" },
  ];

  const aboutDropDown = [
    { label: "Overview of our work in India", value: "Overview" },
    { label: "Areas we work in", value: "Areas" },
    { label: "Climate Smart Agriculture", value: "Climate" },
    { label: "Leadership Team", value: "Team" },
    { label: "Board", value: "Board" },
  ];

  return (
    <header
      {...props}
      className={`${props.className} relative z-10 sm:w-75vw flex self-stretch justify-center items-center py-[1.00rem] md:pt-[-1rem] sm:pt-[0rem] sm:py-[0rem] bg-gradient7 mainHeader`}
    >
      <div className="container-xs mx-auto flex flex-wrap items-center gap-[6rem]  p-4 lg:gap-[1rem] sm:p-[0.5rem] md:flex-row headerCont1 sm:pt-[2rem]">
        <div className="flex items-center  md:gap-[30rem] sm:gap-[6rem] mhg">
          <div className="flex gap-[10px]">
            <a onClick={HomePageRedirect} className="cursor-pointer w-[20%] lg:w-[16%]">
              <Img
                src="../../images/Frame logo.svg"
                alt="Footer Image"
                className="w-[100%] self-start object-contain img1"
              />
            </a>
            <a onClick={HomePageRedirect} className="cursor-pointer w-[42%] lg:w-[39%]">
              <Img
                src="../../images/Frame name.svg"
                alt="Footer Image"
                className="w-[100%] self-start object-contain img2"
              />
            </a>
          </div>

          {/* Mobile Menu Toggle Button */}
          {/* Mobile Menu Toggle Button */}
          <button
            onClick={toggleMobileMenu}
            className="hidden lg:hidden md:block sm:block text-white focus:outline-none"
          >
            <svg
              className="w-8 h-8"
              fill="none"
              color="white"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {isMobileMenuOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              )}
            </svg>
          </button>


        </div>

        {/* Trail---  Desktop Navigation */}

        <div className="md:hidden flex flex-wrap gap-[5rem] lg:gap-[1.5rem] md:gap-[1.25rem] navbarMenu "

          dangerouslySetInnerHTML={{ __html: idArticle }} >


        </div>
        {/* <div id="dropDown-content">
          <ul>
            <li className="Enhancing_dropDown">Enhancing sustainale Livelihoods<span> </span> 
              <ul className="sub_dropDown">
                <li>Agriculture</li>
                <li>Livestock</li>
                <li>Fisheries</li>
              </ul>
            </li>
            <li>India Climate Leadership</li>
            <li>Climate Tech Convening</li>
          </ul>
        </div> */}

        {/* Desktop Navigation */}
        {/* <div className="md:hidden  ">
          <ul className=" flex flex-wrap gap-[6rem] lg:gap-[3rem] md:gap-[1.25rem] navLinkBar">
            <li>
              <a onClick={AboutPageRedirect}>
                <Text
                  size="textmd"
                  as="p"
                  className="!font-medium !text-white-a700 cursor-pointer"
                >
                  About Us
                </Text>
              </a>
            </li>
            <li>
              <a onClick={ClimatePageRedirect}>
                <Text
                  size="textmd"
                  as="p"
                  className="!font-medium !text-white-a700 cursor-pointer"
                >
                  Our Work
                </Text>
              </a>
            </li>
            <li>
              <a onClick={TeamPageRedirect}>
                <Text
                  size="textmd"
                  as="p"
                  className="!font-medium !text-white-a700 cursor-pointer"
                >
                  Our Team
                </Text>
              </a>
            </li>
            <li>
              <a onClick={BoardPageRedirect}>
                <Text
                  size="textmd"
                  as="p"
                  className="!font-medium !text-white-a700 cursor-pointer"
                >
                  Get Involved
                </Text>
              </a>
            </li>
            <li>
              <a onClick={EventsPageRedirect}>
                <Text
                  size="textmd"
                  as="p"
                  className="!font-medium !text-white-a700 cursor-pointer"
                >
                  News and Stories
                </Text>
              </a>
            </li>
          </ul>
        </div> */}

        {/* Mobile Navigation */}
        {isMobileMenuOpen && (
          <div className="fixed inset-0 opacity-90 bg-blue-800 text-white transform translate-y-0 transition-transform duration-300" >
            <div className="container text-center p-4">
              <button
                onClick={closeMobileMenu}
                className="text-white text-2xl mb-4  "
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <ul className="flex flex-col space-y-4 text-center">
                <li>
                  <a onClick={AboutPageRedirect}>
                    <Text
                      size="textmd"
                      as="p"
                      className="!font-medium !text-white-a700 cursor-pointer"
                    >
                      About Us
                    </Text>
                  </a>
                </li>
                <li>
                  <a onClick={ClimatePageRedirect}>
                    <Text
                      size="textmd"
                      as="p"
                      className="!font-medium !text-white-a700 cursor-pointer"
                    >
                      Our Work
                    </Text>
                  </a>
                </li>
                <li>
                  <a onClick={TeamPageRedirect}>
                    <Text
                      size="textmd"
                      as="p"
                      className="!font-medium !text-white-a700 cursor-pointer"
                    >
                      {/* Our Impact */}
                      News and Stories
                    </Text>
                  </a>
                </li>
                <li>
                  <a onClick={BoardPageRedirect}>
                    <Text
                      size="textmd"
                      as="p"
                      className="!font-medium !text-white-a700 cursor-pointer"
                    >
                      {/* Get Involved */}
                      Reports and Publications
                    </Text>
                  </a>
                </li>
                <li>
                  <a onClick={EventsPageRedirect}>
                    <Text
                      size="textmd"
                      as="p"
                      className="!font-medium !text-white-a700 cursor-pointer"
                    >
                      {/* News and Stories */}
                      Get Involved
                    </Text>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </header>
  );
}



