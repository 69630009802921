import { Heading, Text } from "./..";
import React from "react";

export default function StatisticsDisplay({
  statisticText = "650+",
  descriptionText = "Companies and organizations that have hosted fellows since the program was founded in 2008",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-start w-[32%] md:w-full gap-[0.13rem] px-[1.88rem] py-[1.25rem] sm:px-[1.00rem] bg-white-a700_cc shadow-2xl rounded-[28px]`}
    >
      <Text size="text8xl" as="p" className="!text-[4.31rem] !text-gray-800_01">
        {statisticText}
      </Text>
      <div className="mb-[1.75rem] flex self-stretch">
        <Heading as="p" className="mb-[0.75rem] w-[96%] leading-[1.88rem] !text-gray-800_01">
          {descriptionText}
        </Heading>
      </div>
    </div>
  );
}
