import { Img, Heading } from "./..";
import React from "react";

export default function VitalSignsProfile({
  vitalSignsImage = "images/img_image_placeholder_268x464.png",
  vitalSignsHeading = "Vital Signs",
  vitalSignsDescription = "Our digital magazine brings you fresh, in-depth reporting about the people and ideas making a difference.",
  readTheMagazineText = "Read the magazine",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col w-[32%] md:w-full gap-[1.75rem] bg-white-a700_e5 shadow-xl rounded-[18px]`}
    >
      <Img
        src={vitalSignsImage}
        alt="Vital Signs Image"
        className="h-[16.75rem] w-full rounded-tl-[18px] rounded-tr-[18px] object-cover"
      />
      <div className="mb-[2.38rem] flex flex-col gap-[0.63rem] self-stretch px-[2.25rem] sm:px-[1.25rem]">
        <div className="flex flex-col items-center gap-[0.63rem]">
          <Heading size="headingxl" as="h4" className="!text-[1.63rem] leading-[130%] tracking-[0.00rem]">
            {vitalSignsHeading}
          </Heading>
          <Heading as="p" className="mb-[1.50rem] w-full !font-normal leading-[150%]">
            {vitalSignsDescription}
          </Heading>
        </div>
        <div className="flex items-center">
          <Heading as="p">{readTheMagazineText}</Heading>
          <Img src="images/img_arrow_blue_gray_900_02.svg" alt="Arrow Image" className="h-[2.88rem]" />
        </div>
      </div>
    </div>
  );
}
