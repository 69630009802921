import { Text, Heading, Img } from "./..";
import React from "react";

export default function UserProfile10({
  userImage = "images/img_ellipse_29.png",
  userName = "Britt Groosman",
  userTitle = "Vice President, Climate-Smart Agriculture",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center w-full gap-[1.00rem] py-[1.75rem] sm:py-[1.00rem] bg-white-a700 shadow-xs rounded-[16px]`}
    >
      <Img src={userImage} alt="Profile Image" className="h-[9.38rem] w-[9.38rem] rounded-[74px] object-cover" />
      <div className="mb-[0.63rem] flex flex-col items-center gap-[0.38rem] self-stretch px-[1.25rem] md:px-[1.25rem]">
        <Heading size="headingmd" as="h5" className="tracking-[0.00rem]">
          {userName}
        </Heading>
        <Text size="textmd" as="p" className="self-stretch text-center leading-[140%]">
          {userTitle}
        </Text>
      </div>
    </div>
  );
}
