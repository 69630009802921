import { Text, Heading, Img } from "./..";
import React from "react";

export default function ExpertReviewSection({
  reviewText = "Expert review of the science underlying nature based climate solutions",
  reviewDate = "March 21, 2024, Nature",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center gap-[1.38rem] px-[1.00rem] py-[1.25rem] bg-white-a700 shadow-lg flex-1 rounded-[20px]`}
    >
      <Img src="images/img_arrowleft_gray_600.svg" alt="Left Arrow" className="h-[0.88rem] w-[0.88rem] self-end" />
      <div className="mx-[4.25rem] mb-[1.88rem] flex flex-col items-start gap-[0.38rem] self-stretch sm:mx-0 sm:gap-[0.38rem]">
        <Heading as="p" className="w-full leading-[150%] sm:w-full sm:text-[1.06rem]">
          {reviewText}
        </Heading>
        <Text size="textmd" as="p" className="capitalize tracking-[0.00rem] !text-blue-900 sm:text-[0.94rem]">
          {reviewDate}
        </Text>
      </div>
    </div>
  );
}
