import { Text, Heading, Img } from "./..";
import React from "react";

export default function UserProfile9({
  userImage = "images/img_ellipse_28_150x150.png",
  userName = "Sean Cook",
  userDescription = (
    <>
      Executive Vice President
      <br />
      Chief Human Resources &<br />
      Administration Officer
    </>
  ),
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center w-full gap-[1.00rem] py-[1.25rem] bg-white-a700 shadow-xs rounded-[16px]`}
    >
      <Img src={userImage} alt="Profile Image" className="h-[9.38rem] w-[9.38rem] rounded-[74px] object-cover" />
      <div className="flex flex-col items-center gap-[0.63rem] self-stretch px-[3.25rem] md:px-[1.25rem]">
        <Heading size="headinglg" as="h4" className="tracking-[0.00rem] !text-blue-900">
          {userName}
        </Heading>
        <Text size="textmd" as="p" className="text-center leading-[140%]">
          {userDescription}
        </Text>
      </div>
    </div>
  );
}
