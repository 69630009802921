import React, { Suspense, useEffect, useState, useRef  } from 'react';
import { Button, Img, Text, Heading, TextArea, Input } from "../../components/index";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useNavigate } from "react-router-dom";





export default function LatestStories() {
  const intervalRef = useRef(null);
  const apiUrl = process.env.REACT_APP_LOCAL_SERVER;
  const navigate = useNavigate();
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [playlistKey, setPlaylistKey] = useState(null);
  const [sliderHeader, setSliderHeader] = useState({});
  const [sliderData, setSliderData] = useState([]);
  const [contentID, setContentID] = useState(null)
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadS, setLoadS] = useState(false)
  // console.log(playlistKey, 'module5');


  const fetchKey = async () => {
    try {
      const response = await fetch(`${apiUrl}/page/page/1?siteId=822`);
      // const response = await fetch(`${apiUrl}/page/1?siteId=785`);
      const jsonResponse = await response.json();
      setPlaylistKey(jsonResponse?.data?.playlist?.[4]?.key || null);
      console.log("data",jsonResponse)
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKey();
  }, [apiUrl]);

  const getDataFromKeys = async () => {
    if (!playlistKey) return;
    try {
      const mresponse = await fetch(`${apiUrl}/playlist/playlist/${playlistKey}?siteId=822`);
      // const mresponse = await fetch(`${apiUrl}/playlist/${playlistKey}?siteId=785`);
      const mresponseContent = await mresponse.json();
      console.log(mresponseContent?.data, "adjasfdasfda")
      setSliderHeader(mresponseContent?.data || {})
      console.log(mresponseContent?.data?.content, "slider")
      setSliderData(mresponseContent?.data?.content || []);
      setContentID(mresponseContent?.data?.content?.[0] || []);
      setLoadS(true)


    } catch (err) {
      setError(err);
    }

  };

  useEffect(() => {
    if (playlistKey) {
      getDataFromKeys();
    }
  }, [playlistKey]);



 

  // Ensure activeImageIndex is within the bounds of sliderData
  // const safeIndex = Math.max(0, Math.min(activeImageIndex, sliderData.length - 1));
  // const currentData = sliderData[safeIndex] || {};
  // const { name = '', excerpt = '', images = [] } = issueData;
//   useEffect(() => {
//     const nextSlide = () => {
//       // setActiveImageIndex((prevIndex) => (prevIndex + 1) % sliderData.length);
//       handleNextClick();

//     };

//     // Set the interval to change slides every 3 seconds
//     intervalRef.current = setInterval(nextSlide, 3000);

//     // Clear interval on component unmount
//     return () => {
//         if (intervalRef.current) {
//             clearInterval(intervalRef.current);
//         }
//     };
// }, []);

// const getDataForId = async () => {
//   if (!contentID) return;

//   try {
//       const mresponse = await fetch(`${apiUrl}/content/content-block/${contentID}?siteId=822`);         
//       const mresponseContent = await mresponse.json();
//       console.log(mresponseContent, "ID")
//       console.log(mresponseContent.data?.content?.[0]?.id, "ID")
//       setContentBlockId(mresponseContent.data?.content || []);

//       // setIssueID(mresponseContent.data?.content?.[0] || null)
//       // subjectContent.content.


//   } catch (err) {
//       console.log('errrr1', err)
//       setError(err);
//   }

// };

// useEffect(() => {
//   if (contentID) {
//       getDataForId();
//   }
// }, [contentID]);



// if (loading) return <div>Loading...</div>;
// if (error) return <div>Error: {error.message}</div>;

// const handleArticlePage = () => { 
//       console.log(contentID.id);
//       localStorage.setItem('articlePageApi', contentID.id)
//       navigate(`${contentID.category}/article/${contentID.slug}`)
// }

useEffect(() => {
  if (loadS) {
       intervalRef.current = setInterval(() => {
      setActiveImageIndex(prevIndex => (prevIndex + 1) % sliderData?.length);
    }, 3000);

     return () => clearInterval(intervalRef.current);
  }
}, [loadS, sliderData.length]);

  const handlePrevClick = () => {
    setActiveImageIndex((prevIndex) => (prevIndex === 0 ? sliderData?.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setActiveImageIndex((prevIndex) => (prevIndex + 1) % sliderData?.length);
  };


  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;


  const safeIndex = Math.max(0, Math.min(activeImageIndex, sliderData?.length - 1));
    const currentData = sliderData[safeIndex] || {};
    const { name = '', excerpt = '', images = [] } = currentData;

    const progress = ((activeImageIndex + 1) / sliderData?.length) * 100; // Percentage progress
    const progressNumber = activeImageIndex + 1; // Current slide number


  return (
    <>
      <div className="flex justify-center self-stretch py-[1.38rem] sm:p-[0rem]">
        <div className="mb-[5.75rem] flex w-[94%] flex-col items-center gap-[0.75rem] lg:w-full md:w-full md:mb[3.75rem]">
          <div className="flex flex-col items-center self-stretch py-[3.38rem] md:py-[1.25rem] sm:py-[1.00rem]">
            <div className="container-xs mt-[0.25rem]  text-center flex flex-col items-center gap-[0.38rem] px-[3.50rem] lg:p-[1.25rem] md:px-[0.5rem] sm:px-[1.00rem]">
              <Text as="h2" className="lg:text-[3.00rem]">
                {/* Latest Stories */}
                {sliderHeader?.name}
              </Text>
              <Text size="text3xl" as="p" className="!text-gray-800_01">
                We bring you in-depth reporting about the people and ideas making a difference.
                {sliderData?.headline}
              </Text>
            </div>
          </div>

          <div className="w-[90%] lg:py-[1.25rem] md:p-[1.25rem] ml-[6.25rem] md:ml-[0rem]">
            <div className="flex items-start md:flex-col">
              <div className='w-[54%] h-[380px] md:w-[100%] '>
              {images?.length > 0 ? 
                <img
                // onClick={handleArticlePage}
                    src={images[0].imageUrl[0]}
                    alt="Slider Image"
                    className="w-full h-[380px] object-cover rounded-[30px]"
                    
                /> :
                " "     }
              </div>

              <div className="relative ml-[-11.25rem] flex w-[46%] flex-col items-end gap-[1.88rem] md:ml-0 md:w-full">
                <button
                  onClick={handlePrevClick}
                  className="absolute right-[3rem] md:top-[-26rem] transform -translate-y-1/2 rounded-l-[15px] p-[0.9rem] bg-[#f0f0f1] lg:px-[1rem] lg:py-[0.5rem]"
                >
                  <ArrowRightAltIcon sx={{ fontSize: '20px', color: '#grey', transform: 'rotate(180deg)' }} />
                </button>
                <button
                  onClick={handleNextClick}
                  className="absolute md:top-[-26rem] transform -translate-y-1/2 rounded-r-[15px] bg-blue-800 p-[0.9rem] lg:px-[1rem] lg:py-[0.5rem] "
                >
                  <ArrowRightAltIcon sx={{ fontSize: '20px', color: '#fff' }} />
                </button>
                <div className=" absolute top-[4rem] md:top-[-7rem] sm:top-[-7rem] self-stretch rounded-[20px] md:w-[85%] md:left-[7%] bg-white-a700_cc p-[1.00rem] shadow-lg md:p-[1.25rem] sm:p-[1.00rem]">
                  <div className=" mb-[1.13rem] flex flex-col items-start gap-[0.75rem] " >
                    <Heading size="headingmd" as="h2" className="uppercase tracking-[0.00rem] !text-blue-900">
                      {name}
                    </Heading>
                    <Text
                      size="text4xl"
                      as="h3"
                      className="w-full leading-[120%] tracking-[0.06rem] md:text-[2.00rem] sm:text-[1.63rem]"
                    >
                      {excerpt}
                    </Text>
                  </div>

                </div>

              </div>
            </div>
          </div>

          <div className="flex items-center justify-end w-[35%] lg:ml-[32.5rem] lg:mt-[-65px]  md:ml-[0rem] md:mt-[0px] md:w-[60%] md:pt-[3rem] sm:w-[60%] sm:pt-[3rem]   self-stretch ">
            <Text size="text_2" as="p" className="!text-black">
              {/* 03 */}
              {progressNumber.toString().padStart(2, '0')} {/* Display current slide number */}
            </Text>
            <div className="ml-[1.50rem] flex w-[18%] rounded bg-gray-300">
              <div className="h-[0.50rem] w-[38%] rounded bg-blue-900" style={{ width: `${progress}%` }} />
            </div>
            <Text size="text_2" as="p" className="ml-[1.50rem] !text-black">
              {/* 08 */}
              {sliderData?.length?.toString().padStart(2, '0')} {/* Total number of slides */}
            </Text>
          </div>
        </div>
      </div>


    </>

  )
}   