import { Helmet } from "react-helmet";
import { Button, Img, Text, Heading, TextArea, Input } from "../../components";
import AdvisoryButtons from "../../components/AdvisoryButtons";
import AnnualReportButtons from "../../components/AnnualReportButtons";
import BlogSection from "../../components/BlogSection";
import BusinessCard from "../../components/BusinessCard";
import ButtonList from "../../components/ButtonList";
import ContactCard from "../../components/ContactCard";
import DateNavigation from "../../components/DateNavigation";
import EventDetails from "../../components/EventDetails";
import ExpertReviewSection from "../../components/ExpertReviewSection";
import FeatureCard from "../../components/FeatureCard";
import FeatureCard1 from "../../components/FeatureCard1";
import FeatureCardList from "../../components/FeatureCardList";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import InfoCard from "../../components/InfoCard";
import LocalBusinessProfile from "../../components/LocalBusinessProfile";
import MercuryStandardsInfo from "../../components/MercuryStandardsInfo";
import NaturalSolutionsProfile from "../../components/NaturalSolutionsProfile";
import OpEdArticle from "../../components/OpEdArticle";
import PodcastProfile from "../../components/PodcastProfile";
import PresidentBidenProfile from "../../components/PresidentBidenProfile";
import StatementRelease from "../../components/StatementRelease";
import StatisticsDisplay from "../../components/StatisticsDisplay";
import UserProfile from "../../components/UserProfile";
import UserProfile1 from "../../components/UserProfile1";
import UserProfile10 from "../../components/UserProfile10";
import UserProfile2 from "../../components/UserProfile2";
import UserProfile3 from "../../components/UserProfile3";
import UserProfile4 from "../../components/UserProfile4";
import UserProfile5 from "../../components/UserProfile5";
import UserProfile6 from "../../components/UserProfile6";
import UserProfile7 from "../../components/UserProfile7";
import UserProfile8 from "../../components/UserProfile8";
import UserProfile9 from "../../components/UserProfile9";
import UserProfileCard from "../../components/UserProfileCard";
import UserProfileList from "../../components/UserProfileList";
import VitalSignsProfile from "../../components/VitalSignsProfile";
import React, { Suspense } from "react";

const OurTeam = () => {
  return (
    <>
      <div className="flex flex-col items-center">
        <div className="self-stretch bg-text_and_main-white">
          <div className="mb-[2.50rem] flex flex-col items-center gap-[9.13rem] lg:gap-[6.81rem] md:gap-[6.81rem] sm:gap-[4.56rem]">
            <Header />
            <div className="relative z-[18] mt-[-8rem] flex flex-col items-center bg-text_and_main-white py-[2.25rem] sm:py-[1.00rem]">
              <div className="container-xs mb-[2.00rem] flex flex-col items-center gap-[1.63rem] lg:p-[1.25rem] md:p-[1.25rem]">
                <div className="self-stretch">
                  <div className="flex flex-col gap-[2.50rem] bg-text_and_main-white py-[3.13rem] md:py-[1.25rem] sm:py-[1.00rem]">
                    <div className="flex justify-center px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                      <Text as="h1" className="!text-color_black-900 lg:text-[3.00rem]">
                        Complete list of expert staff
                      </Text>
                    </div>
                    <div className="mx-[2.25rem] flex gap-[1.88rem] md:mx-0 md:flex-col">
                      <div className="flex w-full flex-col gap-[2.50rem] px-[0.25rem] py-[0.88rem]">
                        <div className="flex flex-col items-center gap-[1.25rem] rounded-[20px] bg-white-a700 px-[2.38rem] py-[3.63rem] shadow-xs md:py-[1.25rem] sm:p-[1.00rem]">
                          <div className="relative ml-[0.38rem] h-[4.50rem] self-stretch md:ml-0">
                            <div className="absolute bottom-[0.00rem] left-0 right-0 m-auto flex flex-1 flex-col items-start gap-[0.25rem]">
                              <Heading
                                size="headingmd"
                                as="h3"
                                className="uppercase tracking-[0.00rem] !text-blue-900"
                              >
                                Ahmed, Iqra
                              </Heading>
                              <Text size="text_2" as="p">
                                Manager, Equitable Food and Water Resources
                              </Text>
                            </div>
                            <Img
                              src="../../images/img_arrowleft.svg"
                              alt="Left Arrow Image"
                              className="absolute right-[0.00rem] top-[0.00rem] m-auto h-[0.88rem] w-[0.88rem]"
                            />
                          </div>
                          <Text
                            size="text_2"
                            as="p"
                            className="mb-[0.38rem] w-[98%] leading-[150%] lg:w-full md:w-full"
                          >
                            Areas of expertise: ecology, policy, equity, biodiversity conservation, community-based
                            environmental management, environmental justice
                          </Text>
                        </div>
                        <div className="flex flex-col items-end gap-[0.13rem] rounded-[20px] bg-white-a700 px-[2.38rem] py-[3.63rem] shadow-xs md:py-[1.25rem] sm:p-[1.00rem]">
                          <Img
                            src="../../images/img_arrowleft.svg"
                            alt="Left Arrow Column"
                            className="h-[0.88rem] w-[0.88rem]"
                          />
                          <div className="mb-[1.13rem] mr-[0.38rem] flex flex-col gap-[1.25rem] self-stretch md:mr-0">
                            <div className="flex flex-col items-start gap-[0.25rem]">
                              <Heading
                                size="headingmd"
                                as="h4"
                                className="uppercase tracking-[0.00rem] !text-blue-900"
                              >
                                Allen, Michelle
                              </Heading>
                              <Text size="text_2" as="p">
                                Manager, Community Engagement
                              </Text>
                            </div>
                            <Text size="text_2" as="p" className="leading-[150%]">
                              Areas of expertise: Environmental policy, clean energy, petrochemical solutions,
                              community and civic engagement
                            </Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex w-full flex-col gap-[2.50rem] px-[0.25rem] py-[0.88rem]">
                        <div className="flex flex-col items-end gap-[0.13rem] rounded-[20px] bg-white-a700 px-[2.38rem] py-[3.63rem] shadow-xs md:py-[1.25rem] sm:p-[1.00rem]">
                          <Img
                            src="../../images/img_arrowleft.svg"
                            alt="Left Arrow Column"
                            className="h-[0.88rem] w-[0.88rem]"
                          />
                          <div className="mb-[1.13rem] mr-[0.38rem] flex flex-col gap-[1.25rem] self-stretch md:mr-0">
                            <div className="flex flex-col items-start gap-[0.25rem]">
                              <Heading
                                size="headingmd"
                                as="h5"
                                className="uppercase tracking-[0.00rem] !text-blue-900"
                              >
                                Alkafaji, Roya
                              </Heading>
                              <Text size="text_2" as="p">
                                Manager, Healthy Communities
                              </Text>
                            </div>
                            <Text size="text_2" as="p" className="leading-[150%]">
                              Areas of expertise: Lead in drinking water, water quality, environmental health,
                              children’s health
                            </Text>
                          </div>
                        </div>
                        <div className="flex flex-col items-center justify-center gap-[1.25rem] rounded-[20px] bg-white-a700 px-[2.38rem] py-[3.00rem] shadow-xs md:py-[1.25rem] sm:p-[1.00rem]">
                          <div className="ml-[0.38rem] flex items-start justify-center self-stretch md:ml-0">
                            <div className="flex flex-1 flex-col items-start gap-[0.25rem] self-center">
                              <Heading
                                size="headingmd"
                                as="h6"
                                className="uppercase tracking-[0.00rem] !text-blue-900"
                              >
                                Alpert, Alice
                              </Heading>
                              <Text size="text_2" as="p">
                                Senior Scientist
                              </Text>
                            </div>
                            <Img
                              src="../../images/img_arrowleft.svg"
                              alt="Left Arrow Image"
                              className="relative ml-[-0.38rem] mt-[0.63rem] h-[0.88rem] w-[0.88rem]"
                            />
                          </div>
                          <Text size="text_2" as="p" className="w-[98%] leading-[150%] lg:w-full md:w-full">
                            Areas of expertise: Climate change science, climate change mitigation, methane measurement
                            and abatement, United Nations framework convention on climate change, intergovernmental
                            panel on climate change
                          </Text>
                        </div>
                      </div>
                    </div>
                    <div className="mx-[12.13rem] flex justify-center px-[3.50rem] md:mx-0 md:px-[1.25rem] sm:px-[1.00rem]">
                      <div className="flex gap-[1.25rem] px-[3.50rem] md:flex-col md:px-[1.25rem] sm:px-[1.00rem]">
                        <Button
                          color="gray_100_01"
                          shape="round"
                          rightIcon={
                            <Img
                              src="../../images/img_arrowleft.svg"
                              alt="Arrow Left"
                              className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
                            />
                          }
                          className="min-w-[9.00rem] gap-[0.63rem] !rounded-[16px] !text-blue-900"
                        >
                          About us
                        </Button>
                        <Button
                          color="gray_100_01"
                          shape="round"
                          rightIcon={
                            <Img
                              src="../../images/img_arrowleft_gray_600.svg"
                              alt="Arrow Left"
                              className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
                            />
                          }
                          className="min-w-[13.50rem] gap-[0.63rem] !rounded-[16px] !text-blue-900"
                        >
                          Leadership team
                        </Button>
                        <Button
                          color="gray_100_01"
                          shape="round"
                          rightIcon={
                            <Img
                              src="../../images/img_arrowleft_gray_600.svg"
                              alt="Arrow Left"
                              className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
                            />
                          }
                          className="min-w-[8.50rem] gap-[0.63rem] !rounded-[16px] !text-blue-900"
                        >
                          Careers
                        </Button>
                        <Button
                          color="gray_100_01"
                          shape="round"
                          rightIcon={
                            <Img
                              src="../../images/img_arrowleft_gray_600.svg"
                              alt="Arrow Left"
                              className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
                            />
                          }
                          className="min-w-[12.75rem] gap-[0.63rem] !rounded-[16px] !text-blue-900"
                        >
                          Media contacts
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex w-[38%] justify-center lg:w-full md:w-full sm:flex-col">
                  <Button
                    color="gray_200_01"
                    size="xl"
                    className="w-[4.00rem] rotate-[-180deg] rounded-br-[24px] rounded-tr-[24px]"
                  >
                    <Img src="../../images/img_arrow_gray_500.svg" />
                  </Button>
                  <div className="flex flex-1 flex-wrap justify-center gap-[1.75rem] bg-white-a700 p-[0.75rem] sm:self-stretch">
                    <Text size="textxl" as="p" className="!text-[1.31rem] !text-black">
                      1
                    </Text>
                    <Text size="textxl" as="p" className="!text-[1.31rem] !text-black">
                      2
                    </Text>
                    <Text size="textxl" as="p" className="!text-[1.31rem] !text-black">
                      3
                    </Text>
                    <Text size="textxl" as="p" className="!text-[1.31rem] !text-black">
                      4
                    </Text>
                    <Text size="textxl" as="p" className="!text-[1.31rem] !text-black">
                      5
                    </Text>
                    <Text size="textxl" as="p" className="!text-[1.31rem] !text-black">
                      6
                    </Text>
                    <Text size="textxl" as="p" className="!text-[1.31rem] !text-black">
                      7
                    </Text>
                    <Text size="textxl" as="p" className="!text-[1.31rem] !text-black">
                      8
                    </Text>
                  </div>
                  <Button color="blue_900" size="xl" className="w-[4.00rem] rounded-br-[24px] rounded-tr-[24px]">
                    <Img src="../../images/img_arrow.svg" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default OurTeam
