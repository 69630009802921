import { Text, Heading, Img } from "./..";
import React from "react";

export default function NaturalSolutionsProfile({
  userImage = "images/img_image_placeholder.png",
  headingText = "NATURAL SOLUTIONS",
  descriptionText = "Boosting nature’s ability to capture and store carbon",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col w-full lg:gap-[1.0rem] gap-[1.13rem] bg-white-a700_e5 shadow-md rounded-[16px]`}
    >
      <Img
        src={userImage}
        alt="Placeholder Image"
        className="h-[10.00rem] w-full rounded-tl-[16px] rounded-tr-[16px] object-cover"
      />
      <div className="mb-[1.50rem] flex flex-col items-start gap-[0.25rem] self-stretch px-[1.50rem] sm:px-[1.25rem]">
        <Heading size="headingmd" as="h5" className="tracking-[0.00rem]">
          {headingText}
        </Heading>
        <Text size="text_2" as="p" className="w-full leading-[150%]">
          {descriptionText}
        </Text>
      </div>
    </div>
  );
}
