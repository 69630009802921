import { Img, Text, Button } from "./..";
import React from "react";

export default function DateNavigation({
  nov26 = "Nov 26",
  nov29 = "Nov 29",
  novCounterText = "Nov 30",
  decCounterText = "Dec 1",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex md:flex-col items-center gap-[1.88rem] px-[2.25rem] sm:px-[1.00rem] flex-1`}
    >
      <Button
        shape="round"
        rightIcon={
          <Img
            src="images/img_arrowleft.svg"
            alt="Arrow Left"
            className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
          />
        }
        className="w-full gap-[0.63rem] !rounded-[16px]"
      >
        {nov26}
      </Button>
      <Button
        shape="round"
        rightIcon={
          <Img
            src="images/img_arrowleft_gray_600.svg"
            alt="Arrow Left"
            className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
          />
        }
        className="w-full gap-[0.63rem] !rounded-[16px]"
      >
        {nov29}
      </Button>
      <div className="flex w-full items-center justify-center gap-[0.69rem] rounded-[16px] bg-white-a700 shadow-xs">
        <Text size="textmd" as="p" className="!text-gray-800_01 sm:text-[0.94rem]">
          {novCounterText}
        </Text>
        <Img src="images/img_arrowleft_gray_600.svg" alt="Image Arrowleft" className="h-[0.88rem] w-[0.88rem]" />
      </div>
      <div className="flex w-full items-center justify-end gap-[0.63rem] rounded-[16px] bg-white-a700 px-[1.25rem] shadow-xs">
        <Text size="textmd" as="p" className="!text-gray-800_01 sm:text-[0.94rem]">
          {decCounterText}
        </Text>
        <Img src="images/img_arrowleft_gray_600.svg" alt="Image Arrowleft" className="h-[0.88rem] w-[0.88rem]" />
      </div>
    </div>
  );
}
