import { Helmet } from "react-helmet";
import { Button, Img, Text, Heading, TextArea, Input } from "../../components";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import UserProfile from "../../components/UserProfile";
import UserProfile1 from "../../components/UserProfile1";
import UserProfile2 from "../../components/UserProfile2";
import UserProfile3 from "../../components/UserProfile3";
import InfoCard from "../../components/InfoCard";
import React, { Suspense } from "react";
import { useNavigate } from "react-router-dom";
import OurApproachToGetting from "./module-3AP";
import OurPeople from "./module-4AP";
import WhoWeAre from "./module-2AP";
import '../../styles/index.css';
import OurStrategy from "./module-3(1)AP";

// const whoWeAre = ['Our Story', 'Mission and values', 'commitment to diversity', 'sustaina']

const projectCardsRow = [
  {
    vitalSignsImage: "images/img_image_placeholder_268x464.png",
    vitalSignsHeading: "Vital Signs",
    vitalSignsDescription:
      "Our digital magazine brings you fresh, in-depth reporting about the people and ideas making a difference.",
    readTheMagazineText: "Read the magazine",
  },
  {
    vitalSignsImage: "images/img_image_placeholder_268x466.png",
    vitalSignsHeading: "Degrees podcast",
    vitalSignsDescription:
      "Degrees is your podcast community for advice and inspiration to guide you on a purpose-driven journey to find a green job.",
    readTheMagazineText: "Listen to the podcast",
  },
  {
    vitalSignsImage: "images/img_image_placeholder_11.png",
    vitalSignsHeading: "Anti-Misinformation Brigade",
    vitalSignsDescription:
      "Fight for the truth! Our newsletter will arm you with tips, tools and facts to debunk climate misinformation.",
    readTheMagazineText: "Get the newsletter",
  },
];
const executiveTeamGrid = [
  {
    userImage: "images/img_ellipse_28_150x150.png",
    userName: "Sean Cook",
    userDescription: (
      <>
        Executive Vice President
        <br />
        Chief Human Resources &<br />
        Administration Officer
      </>
    ),
  },
  {
    userImage: "images/img_ellipse_28_1.png",
    userName: "Angela Churie Kallhauge",
    userDescription: (
      <>
        Executive Vice President
        <br />
        Impact
      </>
    ),
  },
  {
    userImage: "images/img_ellipse_28_2.png",
    userName: "Pete Harrison",
    userDescription: (
      <>
        Executive Vice President
        <br />
        Regions
      </>
    ),
  },
  {
    userImage: "images/img_ellipse_28_3.png",
    userName: "Tom Murray",
    userDescription: (
      <>
        Executive Vice President
        <br />
        Solutions
      </>
    ),
  },
  {
    userImage: "images/img_ellipse_28_4.png",
    userName: "Joe Bonfiglio",
    userDescription: (
      <>
        Executive Director
        <br />
        U.S. Region
      </>
    ),
  },
  {
    userImage: "images/img_ellipse_28_5.png",
    userName: "Lea Borkenhagen",
    userDescription: (
      <>
        Senior Vice President
        <br />
        EDF+Business
      </>
    ),
  },
  {
    userImage: "images/img_ellipse_28_6.png",
    userName: "Margot Brown",
    userDescription: (
      <>
        Senior Vice President
        <br />
        Justice and Equity
      </>
    ),
  },
  {
    userImage: "images/img_ellipse_28_7.png",
    userName: "Mark Brownstein",
    userDescription: (
      <>
        Senior Vice President
        <br />
        Energy Transition
      </>
    ),
  },
  {
    userImage: "images/img_ellipse_28_8.png",
    userName: "Steven Hamburg",
    userDescription: (
      <>
        Senior Vice President
        <br />
        Chief Scientist
      </>
    ),
  },
  {
    userImage: "images/img_ellipse_28_9.png",
    userName: "Suzi Kerr",
    userDescription: (
      <>
        Senior Vice President
        <br />
        Chief Economist
      </>
    ),
  },
  {
    userImage: "images/img_ellipse_28_10.png",
    userName: "QIN Hu",
    userDescription: (
      <>
        Chief Representative
        <br />
        China
      </>
    ),
  },
  {
    userImage: "images/img_ellipse_28_11.png",
    userName: "Hisham Mundol",
    userDescription: (
      <>
        Chief Advisor
        <br />
        India
      </>
    ),
  },
  {
    userImage: "images/img_ellipse_28_12.png",
    userName: "Amy Middleton",
    userDescription: (
      <>
        Senior Vice President
        <br />
        Chief Marketing & Communications Officer
      </>
    ),
  },
  {
    userImage: "images/img_ellipse_28_13.png",
    userName: "Lou Mkanganwi",
    userDescription: (
      <>
        Senior Vice President
        <br />
        Chief Finance & Technology Officer
      </>
    ),
  },
  { userImage: "images/img_ellipse_28_14.png", userName: "Jill Isenbarger", userDescription: "Chief of Staff" },
  {
    userImage: "images/img_ellipse_28_15.png",
    userName: "Elizabeth Mattila",
    userDescription: (
      <>
        Vice President
        <br />
        Global HR & Infrastructure
      </>
    ),
  },
  {
    userImage: "images/img_ellipse_28_16.png",
    userName: "Mandy Rambharos",
    userDescription: (
      <>
        Vice President
        <br />
        Global Climate Cooperation
      </>
    ),
  },
  {
    userImage: "images/img_ellipse_28_17.png",
    userName: "Helen Spence-Jackson",
    userDescription: (
      <>
        Executive Director
        <br />
        Europe
      </>
    ),
  },
  {
    userImage: "images/img_ellipse_28_18.png",
    userName: "Sarah Vogel",
    userDescription: (
      <>
        Senior Vice President
        <br />
        Healthy Communities
      </>
    ),
  },
  { userImage: "images/img_ellipse_28_19.png", userName: "Rory Muhammad", userDescription: "Chief Diversity Officer" },
];
const environmentalProjectsGrid = [
  {
    userImage: "images/img_image_placeholder.png",
    headingText: "NATURAL SOLUTIONS",
    descriptionText: "Boosting nature’s ability to capture and store carbon",
  },
  {
    userImage: "images/img_image_placeholder_224x298.png",
    headingText: "METHANE",
    descriptionText: "Slashing methane to slow global warming right now",
  },
  {
    userImage: "images/img_image_placeholder_1.png",
    headingText: "SUSTAINABLE FISHING",
    descriptionText: "Making fishing communities stronger and fish more plentiful",
  },
  {
    userImage: "images/img_image_placeholder_2.png",
    headingText: "AGRICULTURE",
    descriptionText: "Transforming agriculture to benefit billions of people",
  },
  {
    userImage: "images/img_image_placeholder_3.png",
    headingText: "CLEAN AIR",
    descriptionText: "Helping a billion people breathe cleaner air in this decade",
  },
  {
    userImage: "images/img_image_placeholder_4.png",
    headingText: "SAFER CHEMICALS",
    descriptionText: "Moving from toxic chemicals to safer, sustainable alternatives",
  },
  {
    userImage: "images/img_image_placeholder_5.png",
    headingText: "ENERGY TRANSITION",
    descriptionText: "Advancing toward a clean energy future",
  },
  {
    userImage: "images/img_image_placeholder_6.png",
    headingText: "HYDROGEN",
    descriptionText: "Helping the world get hydrogen energy right",
  },
];
const allyJoinList = [
  { userImage: "images/img_image_placeholder.png", userMessage: "Join a powerful force of over 3 million allies" },
  {
    userImage: "images/img_image_placeholder_224x298.png",
    userMessage: "Get tips on how you can protect the environment",
  },
  {
    userImage: "images/img_image_placeholder_224x298.png",
    userMessage: "Get alerts when there are crucial petitions to sign",
  },
];
const climateFeatureList = [
  {
    websiteText1: "website",
    climateVulnerabilityText: "The U.S. Climate Vulnerability Index",
    websiteText2: "website",
    fleetElectrificationText: "Fleet Electrification Solutions Center",
    collectionText: "COLLECTION",
    naturalClimateText: (
      <>
        Natural Climate Solutions Crediting
        <br />
        Handbook
      </>
    ),
  },
  {
    websiteText1: "website",
    climateVulnerabilityText: "Chemical Exposure Action Map",
    websiteText2: "website",
    fleetElectrificationText: "Inflation Reduction Act tracker",
    collectionText: "INTERACTIVE TOOL",
    naturalClimateText: (
      <>
        Air tracker shows path pollution takes
        <br />
        through neighborhoods
      </>
    ),
    websitetext1: "INTERACTIVE TOOL",
    websitetext2: "INTERACTIVE TOOL",
  },
  {
    websiteText1: "website",
    climateVulnerabilityText: "Net Zero Action Accelerator",
    websiteText2: "website",
    fleetElectrificationText: "Fleet Electrification Solutions Center",
    collectionText: "WEBSITE",
    naturalClimateText: (
      <>
        PermianMAP: Mapping methane leaks
        <br />
        from the planet&#39;s largest oil field
      </>
    ),
    websitetext1: "website",
  },
];
const featureCardsList = [
  {
    titleEDFBusiness: "EDF+Business",
    descriptionEDFBusiness: "Making green business the new business as usual.",
    titleMarketForces: "Market Forces",
    descriptionMarketForces: "Showing how economic incentives equalenvironmental gains.",
    titleClimate411: "CLIMATE 411",
    descriptionClimate411: "Insights on the science, law and economics ofglobal climate change.",
  },
  {
    titleEDFBusiness: "Energy EXchange",
    descriptionEDFBusiness: "News and commentary on global energy issues.",
    titleMarketForces: "Market Forces",
    descriptionMarketForces: "Showing how economic incentives equalenvironmental gains.",
    titleClimate411: "CLIMATE 411",
    descriptionClimate411: "Insights on the science, law and economics ofglobal climate change.",
    titleclimate411: "Global Clean Air",
    descriptionclimate411: "Data arid insights on driving down air pollution around the world.",
  },
  {
    titleEDFBusiness: "EDF HEALTH",
    descriptionEDFBusiness:
      "Science, health and business experts discuss the Chemical and airpoIlution issues of the day.",
    titleMarketForces: "gROWING RETURNS",
    descriptionMarketForces: "Building resilient land and water systems for a changing climate.",
    titleClimate411: "CLIMATE 411",
    descriptionClimate411: "Insights on the science, law and economics ofglobal climate change.",
    titleclimate411: "ON THE WATER FRONT",
    descriptionclimate411: "The latest water management strategies, solutions and insights forthe western U.S.",
  },
];
const featureCardsGrid = [
  {
    userIcon: "images/img_grid.svg",
    userTitle: "Economics",
    userContactInfo: (
      <>
        Sommer Yesenofski
        <br />
        (949) 257-8768 (cell)
      </>
    ),
    email: "Email",
  },
  {
    userIcon: "images/img_call.svg",
    userTitle: "Science",
    userContactInfo: (
      <>
        Anne Marie Borrego
        <br />
        (202) 572-3508 (office)
      </>
    ),
    email: "Email",
  },
  {
    userIcon: "images/img_settings_gray_900.svg",
    userTitle: "Advocacy",
    userContactInfo: (
      <>
        Keith Gaby
        <br />
        (202) 572-3336 (office)
      </>
    ),
    email: "Email",
  },
  {
    userIcon: "images/img_brightness.svg",
    userTitle: "Climate justice",
    userContactInfo: (
      <>
        Lauren Bills
        <br />
        (212) 624-8841 (office)
      </>
    ),
    email: "Email",
  },
  {
    userIcon: "images/img_laptop.svg",
    userTitle: "Innovation",
    userContactInfo: (
      <>
        Chandler Green
        <br />
        (803) 981-2211 (office)
      </>
    ),
    email: "Email",
  },
];
const environmentalProjectsList = [
  {
    userImage: "images/img_image_placeholder_224x456.png",
    headlineText: "Tell EPA: Our Children Deserve a Clean Ride to School",
    subheadlineText: "Boosting nature’s ability to capture and store carbon",
    learnMoreText: "Learn more",
  },
  {
    userImage: "images/img_image_placeholder_16.png",
    headlineText: "Don&#39;t pour more fuel on the fire. Reduce methane emissions!",
    subheadlineText:
      "We need your help to win the fight against climate change. Sign EDF&#39;s Methane Pledge and show your support for commonsense climate solutions!",
    learnMoreText: "Learn more",
  },
  {
    userImage: "images/img_image_placeholder_17.png",
    headlineText: "Climate solutions on the farm",
    subheadlineText:
      "Take action today to ask your members of Congress to prioritize on-farm climate solutions for America&#39;s farmers and ranchers.",
    learnMoreText: "Learn more",
  },
];
const climateNewsList = [
  {
    headlineText: "President Biden’s Vital Climate Leadership “Delivered Real, Positive Benefits for Everyone”",
    dateText: "July 21, 2024",
    arrowImage: "images/img_arrowleft.svg",
  },
  {
    headlineText: "Unanimous Appeals Court Denies Attempt to Block Climate Pollution Standards for Power Plants",
    dateText: "July 19, 2024",
    arrowImage: "images/img_arrowleft.svg",
  },
  {
    headlineText:
      "Local business and conservation interests jointly intervene in Mid-Barataria Sediment Diversion lawsuit",
    dateText: "July 15, 2024",
    arrowImage: "images/img_arrowleft.svg",
  },
  {
    headlineText: "House Republicans’ Funding Bills Would Harm Our Air and Water",
    dateText: "July 10, 2024",
    arrowImage: "images/img_arrowleft.svg",
  },
  {
    headlineText:
      "U.S. Court of Appeals Denies Attempt by Industry, Allied States to Block Oil and Gas Methane Protections",
    dateText: "July 9, 2024",
    arrowImage: "images/img_arrowleft.svg",
  },
  {
    headlineText: "EDF Will Oppose Louisiana’s Legal Attacks on EPA’s Vital Clean Air Standards for Toxic Pollution",
    dateText: "July 2, 2024",
    arrowImage: "images/img_arrowleft.svg",
  },
  {
    headlineText: "New Jersey State Legislators Introduce Bill to Reduce Air Pollution Linked to Warehouses and Ports",
    dateText: "July 1, 2024",
    arrowImage: "images/img_arrowleft.svg",
  },
  {
    headlineText: "Climate Bond Agreement Will Give Voters the Chance to Support Proven Climate Solutions",
    dateText: "July 1, 2024",
    arrowImage: "images/img_arrowleft.svg",
  },
  {
    headlineText: "Supreme Court Decision Threatens Clean Air and Clean Water for All",
    dateText: "June 28, 2024",
    arrowImage: "images/img_arrow_left_gray_600_3.svg",
  },
  {
    headlineText: "Appeals Court Refuses to Block EPA Action Slashing Toxic Air Pollution in Louisiana",
    dateText: "June 27, 2024",
    arrowImage: "images/img_arrowleft.svg",
  },
];
const solutionFeaturesGrid = [
  {
    clockImage: "images/img_clock.svg",
    headingText: "Scientific evidence",
    descriptionText: "Applying evidence to identify the most effective solutions",
  },
  {
    clockImage: "images/img_clock_gray_900.svg",
    headingText: "Economic sustainability",
    descriptionText: "Using economic forces to protect our environment",
  },
  {
    clockImage: "images/img_television.svg",
    headingText: "Powerful partnerships",
    descriptionText: "Creating alliances across a wide range of allies",
  },
  {
    clockImage: "images/img_diversity_2.svg",
    headingText: "Commitment to diversity",
    descriptionText: "Making transformative change for diversity, equity and inclusion",
  },
  {
    clockImage: "images/img_balance.svg",
    headingText: "Climate justice",
    descriptionText: "Building a more equitable home on this planet we share",
  },
  {
    clockImage: "images/img_search.svg",
    headingText: "Strong advocacy",
    descriptionText: "Shaping public policy and taking legal action",
  },
];
const environmentalAwarenessList = [
  {
    userImage: "images/img_image_placeholder_8.png",
    userDescription: (
      <>
        Celebrating World Environment Day with inspiring
        <br />
        photography
      </>
    ),
  },
  {
    userImage: "images/img_image_placeholder_9.png",
    userDescription: (
      <>
        Two huge US climate laws signed by BiDen are already
        <br />
        changing lives
      </>
    ),
  },
  {
    userImage: "images/img_image_placeholder_10.png",
    userDescription: (
      <>
        Fishing communities, scientists work together to restore
        <br />
        mangroves
      </>
    ),
  },
];
const projectCardsList = [
  {
    userImage: "images/img_image_placeholder_116x402.png",
    headingText: "Corporate outreach",
    contactInfo: (
      <>
        Amy Morse
        <br />
        (603) 568-5541 (office)
      </>
    ),
    emailButtonText: "Email",
  },
  {
    userImage: "images/img_image_placeholder_12.png",
    headingText: "Global climate cooperation",
    contactInfo: (
      <>
        Raul Arce-Contreras
        <br />
        (212) 616-1428 (office)
      </>
    ),
    emailButtonText: "Email",
  },
  {
    userImage: "images/img_image_placeholder_13.png",
    headingText: "Community partnerships",
    contactInfo: (
      <>
        Bobbie Green
        <br />
        504-478-3501 (office)
      </>
    ),
    emailButtonText: "Email",
  },
];
const annualReportsList = [
  {
    annualReport2023: "2023 Annual Report",
    annualReport2020: "2020 Annual Report",
    annualReport2017: "2017 Annual Report",
  },
  {
    annualReport2023: "2023 Annual Report",
    annualReport2020: "2020 Annual Report",
    annualReport2017: "2017 Annual Report",
    annualreport2023: "2022 Annual Report",
    annualreport2020: "2019 Annual Report",
    annualreport2017: "2016 Annual Report",
  },
  {
    annualReport2023: "2023 Annual Report",
    annualReport2020: "2020 Annual Report",
    annualReport2017: "2017 Annual Report",
    annualreport2023: "2021 Annual Report",
    annualreport2020: "2018 Annual Report",
    annualreport2017: "Annual Report Archive",
  },
];
const eventDetailsList = [
  {
    eventTitle: "Ecological Civilization and Beautiful China Practice",
    eventTimeLocation: "9:00 - 10:30 GST, China Pavilion",
    eventDescription:
      "This session will introduce China’s policies and actions to address climate change and tell China’s story in an international language.",
    eventDetailsLinkText: "See full event details",
  },
  {
    eventTitle: "Ecological Civilization and Beautiful China Practice",
    eventTimeLocation: "9:00 - 10:30 GST, China Pavilion",
    eventDescription:
      "Co-hosted by the Policy Research Center for Environment and Economy, Ministry of Ecology and Environment of the People’s Republic of China, Natural Resources Defense Council’s Beijing Representative Office, and EDF’s Beijing Representative Office, this event will have researchers and experts from universities and institutes, governments, and nongovernmental organizations, discussing China’s policies and actions for addressing climate change.",
    eventDetailsLinkText: "See full event details",
  },
  {
    eventTitle: "Ecological Civilization and Beautiful China Practice",
    eventTimeLocation: "9:00 - 10:30 GST, China Pavilion",
    eventDescription:
      "This event spotlights human rights and the role of communities in shaping a rights-based approach on ocean conservation. It brings attention to tenure rights, the importance of inclusionary local governance, and the significance of sustainable management of marine ecosystems for coastal communities well-being, livelihoods, and food security. It will serve to foster a comprehensive understanding of overlooked perspectives from women, local communities and Indigenous people from the Global South who are reliant on ocean resources for their livelihoods.",
    eventDetailsLinkText: "See full event details",
  },
];
const businessLeadershipFeaturesList = [
  {
    homeIcon: "images/img_home.svg",
    businessTitle: "Business leadership",
    businessDescription:
      "Successful business leaders are raising the bar on corporate sustainability. Raise it higher. Our EDF+Business experts can help.",
  },
  {
    homeIcon: "images/img_bar_chart_4_bars.svg",
    businessTitle: "Project development",
    businessDescription:
      "Host an EDF Climate Corps fellow to design practical ways for your company to boost energy efficiency and more.",
  },
  {
    homeIcon: "images/img_user.svg",
    businessTitle: "Marketing and fundraising",
    businessDescription:
      "Inspire your customers, partners and staff to support our critical work through cause marketing and workplace fundraising.",
  },
];
const faqList = [
  { userQuestion: "What is EDF&#39;s taxpayer ID number?" },
  { userQuestion: "How can I confirm that a solicitation I received came from EDF?" },
  { userQuestion: "Do you have volunteer opportunities at EDF?" },
  { userQuestion: "How do I receive less mail from EDF?" },
  { userQuestion: "Where is my gift item?" },
  { userQuestion: "May I use an EDF photo or article?" },
];
const blogHighlightsGrid = [
  {
    blogTitle: "BLOG",
    blogDescription: "Growing Returns: EDF blog featuring discussions of resilient land and water systems",
  },
  {
    blogTitle: "BLOG",
    blogDescription: "Growing Returns: EDF blog featuring discussions of resilient land and water systems",
  },
  {
    blogTitle: "BLOG",
    blogDescription: "Growing Returns: EDF blog featuring discussions of resilient land and water systems",
  },
  {
    blogTitle: "BLOG",
    blogDescription: "Growing Returns: EDF blog featuring discussions of resilient land and water systems",
  },
  {
    blogTitle: "BLOG",
    blogDescription: "Growing Returns: EDF blog featuring discussions of resilient land and water systems",
  },
  {
    blogTitle: "BLOG",
    blogDescription: "Growing Returns: EDF blog featuring discussions of resilient land and water systems",
  },
];
const fellowshipStatsList = [
  {
    statisticText: "650+",
    descriptionText: "Companies and organizations that have hosted fellows since the program was founded in 2008",
  },
  { statisticText: "1,700+", descriptionText: "Graduate students who have participated in EDF Climate Corps" },
  {
    statisticText: "$1.6 billion",
    descriptionText: "Companies and organizations that have hosted fellows since the program was founded in 2008",
  },
];
const globalOutreachStatsList = [
  { distanceText: "3M", dedicatedText: "Dedicated members and activists who inspire us and make what we do possible" },
  {
    distanceText: "30+",
    dedicatedText: "Number of countries where we're taking on the biggest environmental challenges",
  },
  {
    distanceText: "1K",
    dedicatedText: "Staff members from different disciplines working together to develop new ideas",
  },
];
const scienceReviewList = [
  {
    reviewText: "Expert review of the science underlying nature based climate solutions",
    reviewDate: "March 21, 2024, Nature",
  },
  {
    reviewText: "Expert review of the science underlying nature based climate solutions",
    reviewDate: "March 21, 2024, Nature",
  },
  {
    reviewText:
      "Improving ecosystem health in highly altered river basins: a generalizedframework and its application to the Mississippi-Atchafalaya River Basin",
    reviewDate: "February 22, 2024, Frontiers in Environmental Science",
  },
];
const teamMembersGrid = [
  {
    userImage: "images/img_ellipse_29.png",
    userName: "Britt Groosman",
    userTitle: "Vice President, Climate-Smart Agriculture",
  },
  { userImage: "images/img_ellipse_29_150x150.png", userName: "Eileen McLellan", userTitle: "Lead Senior Scientist" },
  {
    userImage: "images/img_ellipse_29_1.png",
    userName: "Maggie Monast",
    userTitle: "Senior Director, Climate-Smart Agriculture",
  },
  {
    userImage: "images/img_ellipse_29_2.png",
    userName: "Peri Rosenstein",
    userTitle: "Senior Scientist, Livestock Systems",
  },
  {
    userImage: "images/img_ellipse_30.png",
    userName: "John Tauzel",
    userTitle: "Senior Director, Global Agriculture Methane",
  },
  { userImage: "images/img_ellipse_31.png", userName: "Ben Thomas", userTitle: "Senior Policy Director, Agriculture" },
];
const partnerCompaniesList = [
  {
    albertsonsButton: "Albertsons",
    americanRedCrossButton: "American Red Cross",
    bydButton: "BYD",
    cityOfHoustonButton: "City of Houston",
  },
  {
    albertsonsButton: "City of Seattle",
    americanRedCrossButton: "Google",
    bydButton: "Hershey’s",
    cityOfHoustonButton: "Kickstarter",
  },
  {
    albertsonsButton: "Lyft",
    americanRedCrossButton: "Mahindra Group",
    bydButton: "PepsiCo",
    cityOfHoustonButton: "Walmart",
  },
];
const partnerProfiles = [
  {
    userName1: "G. Leonard Baker, Jr.",
    userTitle1: "Limited Partner, Sutter Hill Ventures",
    userName2: "Joshua Bekenstein",
    userTitle2: "Managing Director, Bain Capital",
    userName3: "Georges C. Benjamin, MD",
    userTitle3: "Executive Director, American Public Health Association",
  },
  {
    userName1: "G. Leonard Baker, Jr.",
    userTitle1: "Limited Partner, Sutter Hill Ventures",
    userName2: "Joshua Bekenstein",
    userTitle2: "Managing Director, Bain Capital",
    userName3: "Georges C. Benjamin, MD",
    userTitle3: "Executive Director, American Public Health Association",
    username2: "Shelby W. Bonnie",
    usertitle2: "Co-founder, CNET Networks",
  },
  {
    userName1: "G. Leonard Baker, Jr.",
    userTitle1: "Limited Partner, Sutter Hill Ventures",
    userName2: "Joshua Bekenstein",
    userTitle2: "Managing Director, Bain Capital",
    userName3: "Georges C. Benjamin, MD",
    userTitle3: "Executive Director, American Public Health Association",
  },
  {
    userName1: "G. Leonard Baker, Jr.",
    userTitle1: "Limited Partner, Sutter Hill Ventures",
    userName2: "Joshua Bekenstein",
    userTitle2: "Managing Director, Bain Capital",
    userName3: "Georges C. Benjamin, MD",
    userTitle3: "Executive Director, American Public Health Association",
    username2: "Susan Ford Dorsey",
    usertitle2: "President, Sand Hill Foundation",
  },
  {
    userName1: "G. Leonard Baker, Jr.",
    userTitle1: "Limited Partner, Sutter Hill Ventures",
    userName2: "Joshua Bekenstein",
    userTitle2: "Managing Director, Bain Capital",
    userName3: "Georges C. Benjamin, MD",
    userTitle3: "Executive Director, American Public Health Association",
  },
  {
    userName1: "G. Leonard Baker, Jr.",
    userTitle1: "Limited Partner, Sutter Hill Ventures",
    userName2: "Joshua Bekenstein",
    userTitle2: "Managing Director, Bain Capital",
    userName3: "Georges C. Benjamin, MD",
    userTitle3: "Executive Director, American Public Health Association",
    username1: "Coddy Johnson",
    usertitle1: "Partner, Goodwater Capital",
  },
  {
    userName1: "G. Leonard Baker, Jr.",
    userTitle1: "Limited Partner, Sutter Hill Ventures",
    userName2: "Joshua Bekenstein",
    userTitle2: "Managing Director, Bain Capital",
    userName3: "Georges C. Benjamin, MD",
    userTitle3: "Executive Director, American Public Health Association",
    username2: "Abby Leigh",
    usertitle2: "Artist",
    username3: "Frank Loy",
    usertitle3: "Former Under Secretary of State for Global Affairs",
  },
  {
    userName1: "G. Leonard Baker, Jr.",
    userTitle1: "Limited Partner, Sutter Hill Ventures",
    userName2: "Joshua Bekenstein",
    userTitle2: "Managing Director, Bain Capital",
    userName3: "Georges C. Benjamin, MD",
    userTitle3: "Executive Director, American Public Health Association",
    username1: "Secretary Ray Mabus",
    usertitle1: "Former Secretary of the Navy",
    username2: "Susan Mandel",
    usertitle2: "ZOOM Foundation",
  },
  {
    userName1: "G. Leonard Baker, Jr.",
    userTitle1: "Limited Partner, Sutter Hill Ventures",
    userName2: "Joshua Bekenstein",
    userTitle2: "Managing Director, Bain Capital",
    userName3: "Georges C. Benjamin, MD",
    userTitle3: "Executive Director, American Public Health Association",
    username2: "Kathryn Murdoch",
    usertitle2: "President, Quadrivium Foundation",
    username3: "Susan Oberndorf",
    usertitle3: "President, Susan and William Oberndorf Foundation",
  },
  {
    userName1: "G. Leonard Baker, Jr.",
    userTitle1: "Limited Partner, Sutter Hill Ventures",
    userName2: "Joshua Bekenstein",
    userTitle2: "Managing Director, Bain Capital",
    userName3: "Georges C. Benjamin, MD",
    userTitle3: "Executive Director, American Public Health Association",
    username1: "Virginia Sall",
    usertitle1: "Co-founder, Sall Family Foundation",
    username2: "Lise Strickler",
    usertitle2: "Three Cairns Group",
  },
  {
    userName1: "G. Leonard Baker, Jr.",
    userTitle1: "Limited Partner, Sutter Hill Ventures",
    userName2: "Joshua Bekenstein",
    userTitle2: "Managing Director, Bain Capital",
    userName3: "Georges C. Benjamin, MD",
    userTitle3: "Executive Director, American Public Health Association",
  },
  {
    userName1: "G. Leonard Baker, Jr.",
    userTitle1: "Limited Partner, Sutter Hill Ventures",
    userName2: "Joshua Bekenstein",
    userTitle2: "Managing Director, Bain Capital",
    userName3: "Georges C. Benjamin, MD",
    userTitle3: "Executive Director, American Public Health Association",
    username3: "Alice Hill",
    usertitle3: "David M. Rubenstein Senior Fellow for Energy and the Environment, Council on Foreign Relations",
  },
  {
    userName1: "G. Leonard Baker, Jr.",
    userTitle1: "Limited Partner, Sutter Hill Ventures",
    userName2: "Joshua Bekenstein",
    userTitle2: "Managing Director, Bain Capital",
    userName3: "Georges C. Benjamin, MD",
    userTitle3: "Executive Director, American Public Health Association",
    username3: "Ruth DeFries, Ph.D.",
    usertitle3:
      "Denning Family Professor of Sustainable Development, Department of Ecology, Evolution, and Environmental Biology, Columbia University",
  },
];
const environmentalInitiativesList = [
  {
    userImage: "images/img_jason_mathers_square_0.png",
    userTitle: "ENERGY TRANSITION",
    userDescription: "Jason Mathers directs a global campaign to speed up adoption of electric trucks and buses.",
  },
  {
    userImage: "images/img_jennifer_chow_square.png",
    userTitle: "METHANE",
    userDescription: "Jason Mathers directs a global campaign to speed up adoption of electric trucks and buses.",
  },
  {
    userImage: "images/img_sergio_sanchez_square.png",
    userTitle: "HEALTHY COMMUNITIES",
    userDescription: "Jason Mathers directs a global campaign to speed up adoption of electric trucks and buses.",
  },
  {
    userImage: "images/img_stefan_schwietzke_square.png",
    userTitle: "SCIENCE",
    userDescription: "Jason Mathers directs a global campaign to speed up adoption of electric trucks and buses.",
  },
];
const climateImpactProjectsList = [
  {
    userImage: "images/img_carolyn_square_0.png",
    userTitle: "ECONOMICS",
    userDescription:
      "Carolyn Kousky studies climate risks, like floods, and ways to make disaster recovery more equitable.",
  },
  {
    userImage: "images/img_hongming_liu_square.png",
    userTitle: "PARTNERSHIPS",
    userDescription:
      "Carolyn Kousky studies climate risks, like floods, and ways to make disaster recovery more equitable.",
  },
  {
    userImage: "images/img_lauren_johnson_square.png",
    userTitle: "CLIMATE JUSTICE",
    userDescription:
      "Carolyn Kousky studies climate risks, like floods, and ways to make disaster recovery more equitable.",
  },
  {
    userImage: "images/img_jolette_square.png",
    userTitle: "ADVOCACY",
    userDescription:
      "Carolyn Kousky studies climate risks, like floods, and ways to make disaster recovery more equitable.",
  },
];
const climateChangeProjectsList = [
  {
    userImage: "images/img_image_placeholder_224x338.png",
    userDescription: "This spring saw a flurry of historic US cNmate regulations",
  },
  {
    userImage: "images/img_image_placeholder_224x336.png",
    userDescription: "The New York Times explores MethaneSAT in 3D",
  },
  {
    userImage: "images/img_image_placeholder_7.png",
    userDescription: (
      <>
        Global food companies join EDF for a groundbreaking step
        <br />
        on dairy methane
      </>
    ),
  },
];
const dateSelectionList = [
  { nov26: "Nov 26", nov29: "Nov 29", novCounterText: "Nov 30", decCounterText: "Dec 1" },
  { nov26: "Dec 2", nov29: "Dec 3", novCounterText: "Dec 4", decCounterText: "Dec 5" },
  { nov26: "Dec 6", nov29: "Dec 8", novCounterText: "Dec 9", decCounterText: "Dec 10" },
];

export default function AboutPage() {

  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>About EDF - Join the Fight Against Climate Change</title>
      </Helmet>
      <div className="w-full bg-white-a700">
        <div className="flex flex-col items-center">
          <Header />

          {/* <div className="flex justify-center self-stretch bg-text_and_main-white py-[5.50rem] lg:py-[2.00rem] md:py-[1.25rem] sm:py-[1.00rem]">
            <div className="container-xs mt-[1.50rem] flex justify-center lg:p-[1.25rem] md:p-[1.25rem]">
              <div className="flex w-full items-center md:flex-col">
                <div className="flex w-[40%] lg:w-[45%] md:pr-[0rem] flex-col items-start gap-[2.00rem] md:gap-[0.5rem] md:pb-[2rem] md:w-full sm:pb-[3rem]">
                  <Text size="text6xl" as="h3" className="!text-blue-900 md:text-[2.0rem] sm:text-[3rem]">
                    About Us
                  </Text>
                  <Text
                    size="text9xl"
                    as="h1"
                    className="w-[80%] leading-[120%] !text-color_black-900 lg:w-[65%] lg:w-full lg:text-[3.00rem] md:w-full"
                  >
                    Building a better future together
                  </Text>
                </div>
                <div className="w-[60%] md:w-[100%] ">
                  <iframe
                    className="h-[26.63rem] w-[100%] rounded-[20px] object-contain md:w-full md:h-[15.63rem] sm:h-[13rem]"
                    width="100%"
                    height="auto"
                    src="https://www.youtube.com/embed/2yiNnROYZiw"
                    title="Process of Making an Excavator Tiltrotator"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  >
                  </iframe>
                </div>
              </div>
            </div>
          </div> */}


          <WhoWeAre />
          {/* <div className="flex justify-center self-stretch bg-gray-100_01 py-[4.25rem] lg:py-[2.00rem] md:py-[1.25rem] sm:py-[3.00rem]  ">
            <div className="container-xs flex flex-wrap mb-[2.13rem] justify-center lg:p-[1.25rem] md:p-[0rem]">
              <div className="w-full rounded-[20px] text-center bg-white-a700 px-[0.63rem] pt-[2.13rem] shadow-xs sm:py-[1.00rem]">
                <div className=" flex flex-col ">
                  <div className="flex justify-center  md:justify-center md:pt-[1rem] ">
                    <Text as="h1" className="!text-color_black-900 lg:text-[3.00rem]">
                      Who we are
                    </Text>
                  </div>
                  <div className="flex gap-[1.5rem] px-[3.38rem] my-[2rem] lg:px-[1.38rem] lg:items-center  md:flex-col md:px-[1.25rem] sm:px-[1.00rem]">
                    <div className="w-[40%] lg:w-[80%] md:w-[90%] rounded-[20px]">
                      <Img
                        src="images/img_growing_life_co.png"
                        alt="Lifeco Image"
                        className="h-[20.rem] w-[100%] rounded-[20px] object-contain lg:h-[19.rem] md:w-full"
                      />
                    </div>
                    <div className="flex flex-col w-[55%]  gap-[2.13rem] text-left lg:text-left lg:md:self-stretch md:w-[90%] md:text-center md:self-stretch">
                      <Text size="textxl" as="p" className="leading-[140%] !text-gray-800_01  w-[100%] ">
                        <>
                          We began in 1967, as a scrappy group of scientists and a lawyer on Long Island, New York,
                          fighting to save osprey from the toxic pesticide DDT. Using scientific evidence, our founders
                          got DDT banned in the U.S.
                          <br />
                          <br />
                          Today, we’re one of the world’s top environmental organizations. We deliver bold solutions
                          that cut climate pollution and strengthen the ability of people and nature to thrive — even
                          with the climate change we feel right now.
                          <br />
                          <br />
                          And science still guides everything we do.
                        </>
                      </Text>

                      {Mobile responsive}............
                      <div className="hidden lg:hidden md:hidden sm:block flex gap-[1.5rem] w-[100%] items-start  md:w-full md:text-center md:gap-[2rem] sm:text-center px-[0rem] btn_nav">
                        <Button
                          size="textmd"
                          color="gray_100_01"
                          shape="round"
                          rightIcon={
                            <Img
                              src="images/img_arrowleft.svg"
                              alt="Arrow Left"
                              className=" h-[0.70rem]  w-[0.70rem]"
                            />
                          }
                          className="w-[18%] text-[18px] p-[0.8rem] gap-[0.63rem] !rounded-[25px] md:mb-[1rem] sm:mb-[1rem] md:w-[45%] md:px-[3rem]  md:!rounded-[30px] sm:w-[70%] sm:px-[3rem]  sm:!rounded-[30px] "
                        >
                          <Text size="textmd" as="p" className="!text-gray-800_01  ">
                            Our Story
                          </Text>
                        </Button>
                        <Button
                          size="textmd"
                          color="gray_100_01"
                          shape="round"
                          rightIcon={
                            <Img
                              src="images/img_arrowleft.svg"
                              alt="Arrow Left"
                              className=" h-[0.70rem]  w-[0.70rem]"
                            />
                          }
                          className="w-[100%] text-[18px] p-[0.8rem] gap-[0.63rem] py-[1rem] px-[3rem] !rounded-[30px] " >
                          <Text size="textmd" as="p" className="!text-gray-800_01 ">
                            Mission and Values
                          </Text>
                        </Button>
                        <Button
                          size="textmd"
                          color="gray_100_01"
                          shape="round"
                          rightIcon={
                            <Img
                              src="images/img_arrowleft.svg"
                              alt="Arrow Left"
                              className=" h-[0.70rem]  w-[0.70rem]"
                          }
                          className="w-[100%] text-[18px] p-[0.8rem] gap-[0.63rem] py-[1rem] px-[3rem] !rounded-[30px] "
                        >
                          <Text size="textmd" as="p" className="!text-gray-800_01">
                            Commitment To Diversity
                          </Text>
                        </Button>
                        <Button
                          size="textmd"
                          color="gray_100_01"
                          shape="round"
                          rightIcon={
                            <Img
                              src="images/img_arrowleft.svg"
                              alt="Arrow Left"
                              className=" h-[0.70rem]  w-[0.70rem]"
                            />
                          }
                          className="w-[100%] text-[18px] p-[0.8rem] gap-[0.63rem] py-[1rem] px-[3rem] !rounded-[30px] "
                        >
                          <Text size="textmd" as="p" className="!text-gray-800_01 ">
                            Sustainability at EDF
                          </Text>
                        </Button>
                        <Button
                          size="textmd"
                          color="gray_100_01"
                          shape="round"
                          rightIcon={
                            <Img
                              src="images/img_arrowleft.svg"
                              alt="Arrow Left"
                              className="h-[0.70rem]  w-[0.70rem]"
                            />
                          }
                          className="w-[100%] text-[18px] p-[0.8rem] gap-[0.63rem] py-[1rem] px-[3rem] !rounded-[30px] "
                        >
                          <Text size="textmd" as="p" className="!text-gray-800_01">
                            Code of Ethical Conduct
                          </Text>
                        </Button>
                      </div>

                      {Deskopt responsive} ...........
                      <div className=" sm:hidden  flex flex-col gap-[1.5rem] w-[100%] ">
                        <div className="flex gap-[1.31rem] md:flex-col">
                          <Button
                            size="textmd"
                            color="gray_100_01"
                            shape="round"
                            rightIcon={
                              <Img
                                src="images/img_arrowleft.svg"
                                alt="Arrow Left"
                                className=" h-[0.88rem]  w-[0.88rem] lg:h-[0.70rem]  lg:w-[0.77rem]"
                              />
                            }
                            className="w-[20%] text-[18px] p-[0.7rem] gap-[0.63rem] !rounded-[25px] lg:text-[15px] lg:py-[0.7rem] lg:px-[2.5rem] md:w-[50%] md:p-[0.5rem] "
                          >
                            Our Story
                          </Button>
                          <Button
                            size="textsm"
                            color="gray_100_01"
                            shape="round"
                            rightIcon={
                              <Img
                                src="images/img_arrowleft_gray_600.svg"
                                alt="Arrow Left"
                                className=" h-[0.88rem]  w-[0.88rem] lg:h-[0.70rem]  lg:w-[0.77rem]"
                              />
                            }
                            className="w-[30%] text-[18px] px-[1rem] py-[0.7rem] gap-[0.63rem] !rounded-[25px] lg:text-[15px] lg:py-[0.7rem] lg:px-[2.5rem] md:w-[70%] md:p-[0.5rem]"
                          >
                            Mission and Values
                          </Button>
                          <Button
                            size="textsm"
                            color="gray_100_01"
                            shape="round"
                            rightIcon={
                              <Img
                                src="images/img_arrowleft_gray_600.svg"
                                alt="Arrow Left"
                                className=" h-[0.88rem]  w-[0.88rem] lg:h-[0.70rem]  lg:w-[0.77rem]"
                              />
                            }
                            className="w-[37%] text-[18px] p-[0.7rem] gap-[1rem] !rounded-[25px] lg:text-[15px] lg:py-[0.7rem] lg:px-[2.70rem] lg:gap-[.70rem] md:w-[80%] md:p-[0.5rem]"
                          >
                            Commitment To Diversity
                          </Button>
                        </div>
                        <div className="flex gap-[1.25rem] md:flex-col">
                          <Button
                            size="textsm"
                            color="gray_100_01"
                            shape="round"
                            rightIcon={
                              <Img
                                src="images/img_arrowleft_gray_600.svg"
                                alt="Arrow Left"
                                className=" h-[0.88rem]  w-[0.88rem] lg:h-[0.70rem]  lg:w-[0.77rem]"
                              />
                            }
                            className="w-[32%] text-[18px] p-[0.7rem] gap-[1rem] !rounded-[20px] lg:text-[15px] lg:py-[0.7rem] lg:px-[2.5rem] lg:gap-[0.80remrem] md:w-[60%] md:p-[0.5rem] "
                          >
                            Sustainability at EDF
                          </Button>
                          <Button
                            size="textsm"
                            color="gray_100_01"
                            shape="round"
                            rightIcon={
                              <Img
                                src="images/img_arrowleft_gray_600.svg"
                                alt="Arrow Left"
                                className=" h-[0.88rem]  w-[0.88rem] lg:h-[0.70rem]  lg:w-[0.77rem]"
                              />
                            }
                            className="w-[36%] text-[18px] p-[0.7rem]  gap-[1rem] !rounded-[20px] lg:text-[15px] lg:py-[0.7rem] lg:px-[2.5rem] md:w-[85%] md:p-[0.5rem] "
                          >
                            Code of Ethical Conduct
                          </Button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


          < OurApproachToGetting />
          {/* <div className="relative h-[55.13rem] self-stretch bg-gray-100_01 px-[5.13rem] py-[1.25rem] lg:px-[2.00rem] md:px-[1.25rem] sm:px-[1.00rem]">
            <Img
              src="images/img_group_1000001631.svg"
              alt="Image Six"
              className="absolute bottom-[6%] left-0 right-0 m-auto h-[51.25rem] w-[46%] object-contain"
            />
            <div className="absolute left-0 right-0 top-[1.25rem] m-auto flex flex-1 flex-col items-center gap-[1.88rem]">
              <div className="flex flex-col items-center self-stretch sm:py-[1.00rem]">
                <div className="container-xs  flex flex-col items-center gap-[0.50rem] px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
                  <Text as="h2" className="lg:text-[3.00rem]">
                  OUR APPROACH TO GETTING RESULTS THAT LAST
                  </Text>
                  <Text size="text3xl" as="p" className="self-stretch text-center leading-[140%] !text-gray-800_01 lg:px-[4.5rem]">
                    We bring together people from all sides and draw from deep expertise to tackle environmental issues
                    from every angle.
                  </Text>
                </div>
              </div>
              <div className="container-xs flex flex-col items-center px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
                <div className="grid w-[90%] grid-cols-3 justify-center gap-[2.00rem] gap-y-[1.88rem] lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
                  <Suspense fallback={<div>Loading feed...</div>}>
                    {solutionFeaturesGrid.map((d, index) => (
                      <InfoCard {...d} key={"featuresGrid" + index} />
                    ))}
                  </Suspense>
                </div>
              </div>
            </div>

          </div> */}
          
          <OurStrategy />

          {/* < OurPeople /> */}
          {/* <div className="flex justify-center self-stretch bg-white-a700 py-[4.88rem] lg:py-[2.00rem] md:py-[1.25rem] sm:py-[1.00rem]">
            <div className="container-xs mt-[2.75rem] flex justify-center lg:p-[1.25rem] md:p-[1.25rem]">
              <div className="flex w-full flex-col items-center gap-[3.75rem] sm:gap-[1.88rem]">
                <div className="flex flex-col gap-[2.13rem] self-stretch">
                  <div className="mx-[13.75rem] flex flex-col items-center gap-[1.13rem] px-[3.50rem] md:mx-0 md:px-[1.25rem] sm:px-[1.00rem]">
                    <Text as="p" className="lg:text-[3.00rem]">
                      Our People
                    </Text>
                    <Text size="text3xl" as="p" className="!text-gray-800_01">
                      Meet some of the experts who represent key parts of our work.
                    </Text>
                  </div>
                  <div className="flex flex-col gap-[3.75rem] sm:gap-[1.88rem]">
                    <div className="flex gap-[1.88rem] md:flex-col">
                      <Suspense fallback={<div>Loading feed...</div>}>
                        {environmentalInitiativesList.map((d, index) => (
                          <UserProfile1 {...d} key={"firstCardRow" + index} />
                        ))}
                      </Suspense>
                    </div>
                    <div className="flex gap-[1.88rem] md:flex-col">
                      <div className="flex w-[24%] flex-col items-center gap-[1.38rem] rounded-[16px] bg-white-a700 py-[0.75rem] shadow-sm md:w-full">
                        <div className="mt-[1.00rem] flex px-[0.50rem]">
                          <Img
                            src="images/img_ana_suarez_square.png"
                            alt="Profile Image"
                            className="h-[8.88rem] w-[86%] rounded-[66px] object-contain"
                          />
                        </div>
                        <div className="flex flex-col gap-[0.50rem] self-stretch px-[2.50rem] sm:px-[1.00rem]">
                          <Heading size="headingmd" as="h2" className="leading-[130%] tracking-[0.00rem]">
                            DIVERSITY, EQUITY, INCLUSION
                          </Heading>
                          <Text size="textmd" as="p" className="!font-mulish leading-[140%]">
                            <span className="font-poppins text-blue-900">Ana Suárez</span>
                            <span className="font-poppins text-blue_gray-900_02">
                              &nbsp;creates a stronger sense of belonging at EDF by including a global lens in DEI
                              goals.
                            </span>
                          </Text>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col items-center rounded-[16px] bg-gradient6 p-[3.13rem] shadow-xs md:self-stretch md:p-[1.25rem] sm:p-[1.00rem]">
                        <div className="flex w-[92%] items-center justify-between gap-[1.25rem] lg:w-full md:w-full">
                          <Img src="images/img_frame.svg" alt="Frame Image" className="h-[5.38rem] w-[5.38rem]" />
                          <Heading
                            size="headings"
                            as="h3"
                            className="mb-[1.25rem] mr-[24.00rem] self-end !text-amber-300"
                          >
                            STAFF PERSPECTIVE
                          </Heading>
                        </div>
                        <Text
                          size="text5xl"
                          as="p"
                          className="text-center leading-[120%] tracking-[0.00rem] !text-white-a700 md:text-[1.75rem] sm:text-[1.38rem]"
                        >
                          <>
                            I believe there is a path toa safer, more hopeful
                            <br />
                            future, if we do the worknecessary to build it.
                          </>
                        </Text>
                        <Text
                          size="textmd"
                          as="p"
                          className="mb-[1.88rem] mt-[1.38rem] text-center !font-mulish leading-[140%] !text-white-a700"
                        >
                          <span className="font-poppinssemibold font-semibold text-white-a700">
                            <>
                              Amanda Leland
                              <br />
                            </>
                          </span>
                          <span className="font-poppins text-white-a700">Executive Director</span>
                        </Text>
                      </div>
                    </div>
                    <div className="flex gap-[1.88rem] md:flex-col">
                      <Suspense fallback={<div>Loading feed...</div>}>
                        {climateImpactProjectsList.map((d, index) => (
                          <UserProfile2 {...d} key={"secondCardRow" + index} />
                        ))}
                      </Suspense>
                    </div>
                  </div>
                </div>
                <div className="flex w-[36%] flex-col gap-[2.50rem] lg:w-full md:w-full">
                  <Button
                    color="blue_900"
                    size="lg"
                    rightIcon={
                      <Img
                        src="images/img_arrow.svg"
                        alt="Arrow"
                        className="h-[0.13rem] h-[2.38rem] w-[0.13rem] w-[2.50rem]"
                      />
                    }
                    className="mx-[7.50rem] gap-[0.50rem] self-stretch rounded-[30px] font-semibold md:mx-0"
                  >
                    See All Experts
                  </Button>
                  <div className="flex justify-center gap-[1.25rem] sm:flex-col">
                    <a onClick={() => navigate('/about/ourBoard')}>
                      <Button
                        color="gray_100_01"
                        shape="round"
                        rightIcon={
                          <Img
                            src="images/img_arrowleft.svg"
                            alt="Arrow Left"
                            className=" h-[0.88rem]  w-[0.88rem]"
                          />
                        }
                        className="min-w-[8.50rem] gap-[0.63rem] !rounded-[16px]"
                      >
                        Our board
                      </Button>
                    </a>
                    <a onClick={() => navigate('/about/ourTeam')}>
                      <Button
                        color="gray_100_01"
                        shape="round"
                        rightIcon={
                          <Img
                            src="images/img_arrowleft_gray_600.svg"
                            alt="Arrow Left"
                            className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
                          />
                        }
                        className="min-w-[12.50rem] gap-[0.63rem] !rounded-[16px]"
                      >
                        Our Team
                      </Button>
                    </a>
                    <Button
                      color="gray_100_01"
                      shape="round"
                      rightIcon={
                        <Img
                          src="images/img_arrowleft_gray_600.svg"
                          alt="Arrow Left"
                          className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
                        />
                      }
                      className="min-w-[9.25rem] gap-[0.63rem] !rounded-[16px]"
                    >
                      Work at EDF
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


{/* {EDF by the numbers} */}
          {/* <div className="flex justify-center self-stretch bg-white-a700 py-[5.13rem] lg:py-[2.00rem] md:py-[1.25rem] sm:py-[1.00rem]">
            <div className="container-xs mb-[0.63rem] flex justify-center px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
              <div className="flex w-[82%] flex-col items-center gap-[2.38rem] lg:w-full md:w-full">
                <Text as="h1" className="lg:text-[3.00rem]">
                  EDF by the numbers
                </Text>
                <div className="flex flex-col gap-[2.38rem] self-stretch">
                  <div className="flex gap-[2.13rem] md:flex-col">
                    <Suspense fallback={<div>Loading feed...</div>}>
                      {globalOutreachStatsList.map((d, index) => (
                        <UserProfile3 {...d} key={"listOverview" + index} />
                      ))}
                    </Suspense>
                  </div> */}
                  {/* <div className="flex flex-col items-center gap-[0.88rem] px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                    <div className="flex gap-[3.13rem] md:flex-col">
                      <Button
                        color="gray_100_01"
                        shape="round"
                        rightIcon={
                          <Img
                            src="images/img_arrowleft.svg"
                            alt="Arrow Left"
                            className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
                          />
                        }
                        className="min-w-[11.25rem] gap-[0.63rem] !rounded-[16px]"
                      >
                        Where we work
                      </Button>
                      <Button
                        color="gray_100_01"
                        shape="round"
                        rightIcon={
                          <Img
                            src="images/img_arrowleft_gray_600.svg"
                            alt="Arrow Left"
                            className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
                          />
                        }
                        className="min-w-[14.13rem] gap-[0.63rem] !rounded-[16px]"
                      >
                        Latest annual report
                      </Button>
                      <Button
                        color="gray_100_01"
                        shape="round"
                        rightIcon={
                          <Img
                            src="images/img_arrowleft_gray_600.svg"
                            alt="Arrow Left"
                            className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
                          />
                        }
                        className="min-w-[17.00rem] gap-[0.63rem] !rounded-[16px]"
                      >
                        Commitment To Diversity
                      </Button>
                    </div>
                    <div className="flex w-[50%] justify-between gap-[1.25rem] lg:w-full md:w-full">
                      <Button
                        color="gray_100_01"
                        shape="round"
                        rightIcon={
                          <Img
                            src="images/img_arrowleft_gray_600.svg"
                            alt="Arrow Left"
                            className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
                          />
                        }
                        className="min-w-[9.88rem] gap-[0.63rem] !rounded-[16px]"
                      >
                        Our finances
                      </Button>
                      <Button
                        color="gray_100_01"
                        shape="round"
                        rightIcon={
                          <Img
                            src="images/img_arrowleft_gray_600.svg"
                            alt="Arrow Left"
                            className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
                          />
                        }
                        className="mr-[2.00rem] min-w-[15.88rem] gap-[0.63rem] !rounded-[16px]"
                      >
                        Corporate giving policy
                      </Button>
                    </div>
                  </div> */}
                {/* </div>
              </div>
            </div>
          </div> */}

          {/* <div className="flex h-[48.13rem] flex-col items-center self-stretch bg-gray-50 bg-[url(/public/images/img_frame_48096608.png)] bg-cover bg-no-repeat py-[4.38rem] lg:h-auto lg:py-[2.00rem] md:h-auto md:py-[1.25rem] sm:py-[1.00rem]">
            <div className="container-xs mb-[1.88rem] flex flex-col items-center gap-[1.00rem] px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
              <Text as="h2" className="!text-white-a700 lg:text-[3.00rem]">
                THIS SUMMER FIGHT FOR PEOPLE AND THE PLANET
              </Text>
              <div className="flex w-[58%] flex-col items-center rounded-[30px] bg-white-a700_e5 p-[2.75rem] lg:w-full md:w-full md:p-[1.25rem] sm:p-[1.00rem]">
                <Text size="text6xl" as="h3" className="text-center md:text-[2.00rem] sm:text-[1.63rem] ">
                  Donate now to have triple the impact in the climate fight
                </Text>
                <Text size="texts" as="p" className="ml-[12.13rem] mt-[2.00rem] self-start !text-gray-800_01 md:ml-0">
                  You give
                </Text>
                <div className="ml-[4.00rem] mr-[3.88rem] flex w-[94%] justify-center gap-[1.38rem] lg:w-full md:mx-0 md:w-full md:flex-col">
                  <Button color="white_A700_e5" size="md" className="flex-1 rounded-[30px] font-medium md:self-stretch">
                    $10
                  </Button>
                  <Button
                    color="blue_900"
                    size="md"
                    className="flex-1 rounded-[30px] font-medium !text-white-a700 md:self-stretch"
                  >
                    $15
                  </Button>
                  <Button color="white_A700_e5" size="md" className="flex-1 rounded-[30px] font-medium md:self-stretch">
                    $25
                  </Button>
                  <Button color="white_A700_e5" size="md" className="flex-1 rounded-[30px] font-medium md:self-stretch">
                    $50
                  </Button>
                  <Button color="white_A700_e5" size="md" className="min-w-[7.38rem] rounded-[30px] font-medium">
                    Other
                  </Button>
                </div>
                <Text size="texts" as="p" className="ml-[11.13rem] mt-[0.25rem] self-start !text-gray-800_01 md:ml-0">
                  EDF Gets $45
                </Text>
                <div className="mb-[0.38rem] ml-[5.50rem] mr-[4.88rem] mt-[2.13rem] flex w-[94%] justify-center gap-[2.13rem] lg:w-full md:mx-0 md:w-full sm:flex-col">
                  <div className="flex items-center gap-[0.50rem] p-[0.63rem]">
                    <Heading as="h2" className="!text-gray-800">
                      Donate Once

                    </Heading>
                    <Img src="images/img_arrow_gray_800.svg" alt="Arrow Image" className="h-[2.38rem]" />
                  </div>
                  <div className="flex flex-1 items-center justify-center gap-[0.50rem] rounded-[30px] bg-blue-900 p-[0.63rem] sm:self-stretch">
                    <Heading size="headings" as="h3" className="!text-text_and_main-white">
                      Donate Monthly
                    </Heading>
                    <Img src="images/img_arrow.svg" alt="Arrow Image" className="h-[2.38rem]" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}


          {/* {Another easy way to help} */}
          {/* <div className="flex flex-col items-center gap-[3.75rem] self-stretch bg-gray-100_01 py-[3.75rem] md:text-center md:py-[1.25rem] sm:gap-[1.88rem] sm:py-[1.00rem]">
            <div className="flex w-[90%] justify-center lg:w-full lg:p-[1.25rem] md:w-full md:p-[1.25rem]">
              <div className="container-xs flex justify-center px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                <Text as="h2" className="lg:text-[3.00rem]">
                  Another easy way to help
                </Text>
              </div>
            </div>
            <div className="container-xs mb-[6.00rem] flex flex-col items-center px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
              <div className="flex w-[90%] gap-[1.50rem] px-[0.25rem] md:w-full md:flex-col">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {allyJoinList.map((d, index) => (
                    <UserProfile {...d} key={"cardsRow1" + index} />
                  ))}
                </Suspense>
              </div>
            </div>
          </div> */}

          <div className="mt-[2.5rem] lg:mt-[2rem] md:mt-[2rem] sm:pb-[2rem] sm:mt-[5rem]">
            < Footer />
          </div>
        </div>
      </div>
    </>
  );
}
