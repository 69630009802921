// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/fonts/PoppinsMedium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/fonts/PoppinsRegular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("/public/fonts/PoppinsSemiBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("/public/fonts/BebasNeue.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("/public/fonts/BebasNeueRegular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("/public/fonts/PoppinsLightItalic.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-display: swap;
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  font-family: "Poppins";
  font-weight: 500;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  font-family: "Poppins";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  font-family: "Poppins";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
  font-family: "Bebas Neue";
  font-weight: 400;
}
/* @font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/MulishRomanSemiBold.ttf");
  font-family: "Mulish";
  font-weight: 600;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url("/public/fonts/MulishRegular.ttf");
  font-family: "Mulish";
  font-weight: 400;
} */
@font-face {
  font-display: swap;
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
  font-family: "Bebas Neue";
  font-weight: 400;
}
@font-face {
  font-display: swap;
  font-style: normal;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
  font-family: "Poppins";
  font-weight: 300;
}
`, "",{"version":3,"sources":["webpack://./src/styles/font.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,4CAA2C;EAC3C,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,4CAA4C;EAC5C,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,4CAA6C;EAC7C,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,4CAAuC;EACvC,yBAAyB;EACzB,gBAAgB;AAClB;AACA;;;;;;;;;;;;;GAaG;AACH;EACE,kBAAkB;EAClB,kBAAkB;EAClB,4CAA8C;EAC9C,yBAAyB;EACzB,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,4CAAgD;EAChD,sBAAsB;EACtB,gBAAgB;AAClB","sourcesContent":["@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url(\"/public/fonts/PoppinsMedium.ttf\");\n  font-family: \"Poppins\";\n  font-weight: 500;\n}\n@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url(\"/public/fonts/PoppinsRegular.ttf\");\n  font-family: \"Poppins\";\n  font-weight: 400;\n}\n@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url(\"/public/fonts/PoppinsSemiBold.ttf\");\n  font-family: \"Poppins\";\n  font-weight: 600;\n}\n@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url(\"/public/fonts/BebasNeue.ttf\");\n  font-family: \"Bebas Neue\";\n  font-weight: 400;\n}\n/* @font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url(\"/public/fonts/MulishRomanSemiBold.ttf\");\n  font-family: \"Mulish\";\n  font-weight: 600;\n}\n@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url(\"/public/fonts/MulishRegular.ttf\");\n  font-family: \"Mulish\";\n  font-weight: 400;\n} */\n@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url(\"/public/fonts/BebasNeueRegular.ttf\");\n  font-family: \"Bebas Neue\";\n  font-weight: 400;\n}\n@font-face {\n  font-display: swap;\n  font-style: normal;\n  src: url(\"/public/fonts/PoppinsLightItalic.ttf\");\n  font-family: \"Poppins\";\n  font-weight: 300;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
