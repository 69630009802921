import { Button, Img, Text, Heading } from "./..";
import React from "react";

export default function UserProfile6({
  userImage = "images/img_image_placeholder_116x402.png",
  headingText = "Corporate outreach",
  contactInfo = (
    <>
      Amy Morse
      <br />
      (603) 568-5541 (office)
    </>
  ),
  emailButtonText = "Email",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center w-[32%] md:w-full gap-[1.38rem] bg-white-a700 shadow-sm rounded-[16px]`}
    >
      <Img
        src={userImage}
        alt="Card Image"
        className="h-[7.25rem] w-full rounded-tl-[16px] rounded-tr-[16px] object-cover"
      />
      <div className="flex flex-col items-center gap-[0.38rem] self-stretch">
        <Heading size="headingmd" as="h5" className="uppercase tracking-[0.00rem]">
          {headingText}
        </Heading>
        <Text size="text_2" as="p" className="text-center leading-[150%]">
          {contactInfo}
        </Text>
      </div>
      <Button
        color="gray_100_01"
        shape="round"
        rightIcon={
          <Img
            src="images/img_arrowleft_gray_600.svg"
            alt="Arrow Left"
            className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
          />
        }
        className="mb-[1.50rem] min-w-[7.00rem] gap-[0.63rem] !rounded-[16px]"
      >
        {emailButtonText}
      </Button>
    </div>
  );
}
