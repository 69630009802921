import React from "react";
import PropTypes from "prop-types";

const shapes = {
  circle: "rounded-[50%]",
  round: "rounded-[18px]",
};
const variants = {
  fill: {
    blue_gray_50: "bg-blue_gray-50 text-gray-800_01",
    blue_900: "bg-blue-900 text-text_and_main-white",
    text_and_main_white: "bg-text_and_main-white text-gray-800",
    white_A700: "bg-white-a700 shadow-xs text-gray-800_01",
    gray_200_01: "bg-gray-200_01",
    amber_300: "bg-amber-300 text-blue_gray-900_02",
    amber_300_33: "bg-amber-300_33 text-gray-800_01",
    white_A700_e5: "bg-white-a700_e5 text-gray-800_01",
    gray_100_01: "bg-gray-100_01 text-gray-800_01",
  },
  outline: {
    blue_900: "border-blue-900 border-[1.5px] border-solid text-blue-900",
  },
};
const sizes = {
  "2xl": "h-[7.13rem] px-[2.13rem] text-[1.25rem]",
  sm: "h-[3.25rem] px-[0.63rem]",
  xl: "h-[4.00rem] px-[1.00rem]",
  md: "h-[3.75rem] px-[1.63rem] text-[1.50rem]",
  lg: "h-[3.75rem] px-[2.13rem] text-[1.25rem]",
  xs: "h-[2.25rem] px-[1.00rem] text-[1.13rem]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "xs",
  color = "white_A700",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center text-center cursor-pointer whitespace-nowrap ${(shape && shapes[shape]) || ""} ${(size && sizes[size]) || ""} ${(variant && variants[variant]?.[color]) || ""}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["circle", "round"]),
  size: PropTypes.oneOf(["2xl", "sm", "xl", "md", "lg", "xs"]),
  variant: PropTypes.oneOf(["fill", "outline"]),
  color: PropTypes.oneOf([
    "blue_gray_50",
    "blue_900",
    "text_and_main_white",
    "white_A700",
    "gray_200_01",
    "amber_300",
    "amber_300_33",
    "white_A700_e5",
    "gray_100_01",
  ]),
};

export { Button };
