import { Img, Text } from "./..";
import React from "react";

export default function FeatureCardList({
  cultivatingText = "Cultivating Climate-Smart Dairy: A supplier engagement guide to manure management technologies",
  cultivatingDuration = "Blog post, 19 days ago",
  timeZoneText = "Why food companies must act now to protect public funding for climate-smart agriculture",
  timeZoneDuration = "Blog post, 1 month ago",
  priceText = "EMIT LESS Act will support U.S. livestock producers in lowering methane",
  priceDuration = "Press release, 1 month ago",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col w-[50%] md:w-full gap-[2.50rem] px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]`}
    >
      <div className="flex items-start justify-center self-stretch rounded-[20px] bg-white-a700 px-[1.50rem] py-[2.50rem] shadow-xs sm:p-[1.25rem]">
        <div className="mb-[1.25rem] flex flex-1 flex-col items-start gap-[0.50rem] sm:gap-[0.50rem]">
          <Text
            size="textmd"
            as="p"
            className="w-full capitalize leading-[130%] tracking-[0.00rem] !text-blue-900 sm:w-full sm:text-[0.94rem]"
          >
            {cultivatingText}
          </Text>
          <Text size="text_2" as="p" className="sm:text-[0.94rem]">
            {cultivatingDuration}
          </Text>
        </div>
        <Img src="../../images/img_arrowleft.svg" alt="Left Arrow Image" className="h-[0.88rem] w-[0.88rem]" />
      </div>
      <div className="flex items-start justify-center self-stretch rounded-[20px] bg-white-a700 px-[1.50rem] py-[2.50rem] shadow-xs sm:p-[1.25rem]">
        <div className="mb-[1.25rem] flex flex-1 flex-col items-start gap-[0.50rem] sm:gap-[0.50rem]">
          <Text
            size="textmd"
            as="p"
            className="w-full capitalize leading-[130%] tracking-[0.00rem] !text-blue-900 sm:w-full sm:text-[0.94rem]"
          >
            {timeZoneText}
          </Text>
          <Text size="text_2" as="p" className="sm:text-[0.94rem]">
            {timeZoneDuration}
          </Text>
        </div>
        <Img src="../../images/img_arrowleft.svg" alt="Left Arrow Image" className="h-[0.88rem] w-[0.88rem]" />
      </div>
      <div className="flex items-start justify-center self-stretch rounded-[20px] bg-white-a700 px-[1.50rem] py-[2.50rem] shadow-xs sm:p-[1.25rem]">
        <div className="mb-[2.63rem] flex flex-1 flex-col items-start gap-[0.50rem] sm:gap-[0.50rem]">
          <Text
            size="textmd"
            as="p"
            className="w-full capitalize leading-[130%] tracking-[0.00rem] !text-blue-900 sm:w-full sm:text-[0.94rem]"
          >
            {priceText}
          </Text>
          <Text size="text_2" as="p" className="sm:text-[0.94rem]">
            {priceDuration}
          </Text>
        </div>
        <Img src="../../images/img_arrowleft.svg" alt="Left Arrow Image" className="h-[0.88rem] w-[0.88rem]" />
      </div>
    </div>
  );
}
