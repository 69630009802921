import { Heading, Img, Text } from "./..";
import React from "react";

export default function BlogSection({
  blogTitle = "BLOG",
  blogDescription = "Growing Returns: EDF blog featuring discussions of resilient land and water systems",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex items-center w-full px-[1.38rem] py-[2.13rem] sm:p-[1.00rem] bg-white-a700 shadow-lg rounded-[20px]`}
    >
      <div className="flex w-full flex-col gap-[0.38rem]">
        <div className="flex items-start justify-between gap-[1.25rem]">
          <Text size="textmd" as="p" className="self-center uppercase tracking-[0.00rem] !text-blue-900">
            {blogTitle}
          </Text>
          <Img src="../../images/img_arrow_left_gray_600_5.png" alt="Arrow Image" className="h-[0.88rem] object-cover" />
        </div>
        <Heading as="p" className="leading-[150%]">
          {blogDescription}
        </Heading>
      </div>
    </div>
  );
}
