import { Img, Heading, Text } from "./..";
import React from "react";

export default function MercuryStandardsInfo({
  titleText = "Getting rid of mercury",
  descriptionText = "The U.S. Environmental Protection Agency’s Mercury and Air Toxics Standards take effect, following long advocacy by EDF. The new standards, one of the biggest public health advances in a generation, limit emissions of mercury, a dangerous neurotoxin, and other hazardous air pollution from U.S. power plants. ",
  seeMoreLink = "See More",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex items-center py-[8.25rem] lg:py-[2.00rem] md:py-[1.25rem] sm:py-[1.00rem] bg-color_black-900_66 flex-1 rounded-[20px]`}
    >
      <div className="flex w-full flex-col items-center gap-[1.88rem] px-[3.50rem] md:px-[1.25rem]">
        <Text as="p" className="!text-white-a700">
          {titleText}
        </Text>
        <Text size="text3xl" as="p" className="self-stretch text-center leading-[140%] !text-text_and_main-white">
          {descriptionText}
        </Text>
        <div className="flex items-center gap-[0.38rem]">
          <a href="#">
            <Heading size="headings" as="h5" className="!text-white-a700">
              {seeMoreLink}
            </Heading>
          </a>
          <Img src="images/img_arrow.svg" alt="Arrow Image" className="h-[2.38rem]" />
        </div>
      </div>
    </div>
  );
}
