import React, { Suspense, useEffect, useState } from 'react';
import { Button, Img, Text, Heading, TextArea, Input } from "../../components/index";
import { useNavigate } from "react-router-dom";






export default function OurPeople() {
    const apiUrl = process.env.REACT_APP_LOCAL_SERVER;
    const [playlistKey, setPlaylistKey] = useState(null);
    const [subjectContent, setSubjectContent] = useState([])
    const [issueData, setIssueData] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // console.log(playlistKey, 'ourP');

  const navigate = useNavigate();





    const fetchKey = async () => {
        try {
            const response = await fetch(`${apiUrl}/page/page/2?siteId=822`);
            // const response = await fetch(`${apiUrl}/page/2?siteId=785`);
            const jsonResponse = await response.json();
            setPlaylistKey(jsonResponse?.data?.playlist?.[2].key || null);
            // console.log(jsonResponse.data.playlist, "ourPkey")

        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchKey();
    }, [apiUrl]);

    const getDataFromKeys = async () => {
        if (!playlistKey) return;
        try {
            const mresponse = await fetch(`${apiUrl}/playlist/playlist/${playlistKey}?siteId=822`);
            // const mresponse = await fetch(`${apiUrl}/playlist/${playlistKey}?siteId=785`);
            const mresponseContent = await mresponse.json();
            // console.log(mresponseContent.data.content, "ourPjson")
            setIssueData(mresponseContent?.data?.content || []);

            // console.log(mresponseContent.data, " ourPsetSubjectContent");
            setSubjectContent(mresponseContent?.data || []);

        } catch (err) {
            setError(err);
        }

    };

    useEffect(() => {
        if (playlistKey) {
            getDataFromKeys();
        }
    }, [playlistKey]);



    // if (loading) return <div>Loading...</div>;
    // if (error) return <div>Error: {error.message}</div>;





    return (
        <>
        
            <div className="flex justify-center self-stretch bg-white-a700 py-[4.88rem] lg:py-[2.00rem] md:py-[1.25rem] sm:py-[1.00rem]">
                <div className="container-xs mt-[2.75rem] flex justify-center lg:p-[1.25rem] md:p-[1.25rem]">
                    <div className="flex w-full flex-col items-center gap-[3.75rem] sm:gap-[1.88rem]">
                        <div className="flex flex-col gap-[2.13rem] self-stretch">
                            <div className="mx-[13.75rem] flex flex-col items-center gap-[0.13rem] px-[1.50rem] md:mx-0 md:px-[1.25rem] sm:px-[1.00rem]">
                                <Text as="h1" className="lg:text-[3.00rem]">
                                    {subjectContent?.name}
                                </Text>
                                <Text size="textxl" as="p" className="!text-gray-800_01">
                                    {subjectContent?.headline}
                                </Text>
                            </div>
                            <div className="flex flex-col gap-[3.75rem] sm:gap-[1.88rem]">
                                <div className="flex gap-[1.88rem] md:flex-col justify-center">
                                    {issueData?.map((item, index) => (
                                        <div key={index} className="flex flex-col items-center w-[24%] md:w-full gap-[1.00rem] lg:pt-[1.5rem] md:py-[2.5rem] sm:py-[2.5rem] bg-white-a700 shadow-xs rounded-[16px]">
                                            <img src={item?.images[0].imageUrl} alt="Profile Image" className=" w-[60%] rounded-[50%] object-contain" />
                                        <div className="mb-[2.38rem] flex flex-col items-start gap-[0.25rem] self-stretch px-[1.50rem] sm:px-[1.25rem]">
                                            <Heading size="headingmd" as="h5" className="tracking-[0.00rem]">
                                                {item?.name}
                                            </Heading>
                                            <Heading size="headingxs" as="h6" className="w-[90%] !font-mulish leading-[140%] !text-blue-900">
                                                <span className="font-poppins font-normal text-blue-900">Jason Mathers&nbsp;</span>
                                                <span className="font-poppins font-normal text-blue_gray-900_02">
                                                    directs a global campaign to speed up adoption of electric trucks and buses.
                                                </span>
                                            </Heading>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="flex gap-[1.88rem] md:flex-col">

                                  <div className="flex flex-1 flex-col items-center rounded-[16px] bg-gradient6 p-[3.13rem] shadow-xs md:self-stretch md:p-[1.25rem] sm:p-[1.00rem]">
                                        <div className="flex w-[92%] items-center justify-between gap-[1.25rem] lg:w-full md:w-full">
                                            <Img src="images/img_frame.svg" alt="Frame Image" className="h-[5.38rem] w-[5.38rem] md:w-[1.38rem]" />
                                            <Heading
                                                size="headings"
                                                as="h3"
                                                className="mb-[1.25rem] mr-[55.00rem] self-end !text-amber-300 lg:mr-[29rem] md:mb-[1.75rem]  md:mr-[3.5rem]"
                                            >
                                                STAFF PERSPECTIVE
                                            </Heading>
                                        </div>
                                        <Text
                                            size="text5xl"
                                            as="p"
                                            className="text-center leading-[120%] tracking-[0.00rem] !text-white-a700 md:text-[1.3rem] sm:text-[1.1rem]"
                                        >
                                            <>
                                                I believe there is a path to a safer, more hopeful
                                                <br />
                                                future, if we do the worknecessary to build it.
                                            </>
                                        </Text>
                                        <Text
                                            size="textmd"
                                            as="p"
                                            className="mb-[1.88rem] mt-[1.38rem] text-center !font-mulish leading-[140%] !text-white-a700"
                                        >
                                            <span className="font-poppinssemibold font-semibold text-white-a700">
                                                <>
                                                    Amanda Leland
                                                    <br />
                                                </>
                                            </span>
                                            <span className="font-poppins text-white-a700">Executive Director</span>
                                        </Text>
                                    </div>
                                </div>
                                {/* <div className="flex gap-[1.88rem] md:flex-col">
                      <Suspense fallback={<div>Loading feed...</div>}>
                        {climateImpactProjectsList.map((d, index) => (
                          <UserProfile2 {...d} key={"secondCardRow" + index} />
                        ))}
                      </Suspense>
                    </div> */}
                            </div>
                        </div>
                        <div className="flex w-[36%] flex-col gap-[2.50rem] lg:w-full md:w-full">
                            {/* <Button
                    color="blue_900"
                    size="lg"
                    rightIcon={
                      <Img
                        src="images/img_arrow.svg"
                        alt="Arrow"
                        className="h-[0.13rem] h-[2.38rem] w-[0.13rem] w-[2.50rem]"
                      />
                    }
                    className="mx-[7.50rem] gap-[0.50rem] self-stretch rounded-[30px] font-semibold md:mx-0"
                  >
                    See All Experts
                  </Button> */}
                            <div className="flex justify-center gap-[1.25rem] md:items-center sm:items-center sm:flex-col">
                                <a onClick={() => navigate('/get-involved')}>
                                    <Button
                                        color="gray_100_01"
                                        shape="round"
                                        rightIcon={
                                            <Img
                                                src="images/img_arrowleft.svg"
                                                alt="Arrow Left"
                                                className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
                                            />
                                        }
                                        className="min-w-[8.50rem] gap-[0.63rem] !rounded-[16px]"
                                    >
                                        Get Involved
                                    </Button>
                                </a>
                                <a onClick={() => navigate('/our-team')}>
                                    <Button
                                        color="gray_100_01"
                                        shape="round"
                                        rightIcon={
                                            <Img
                                                src="images/img_arrowleft_gray_600.svg"
                                                alt="Arrow Left"
                                                className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
                                            />
                                        }
                                        className="min-w-[12.50rem] gap-[0.63rem] !rounded-[16px]"
                                    >
                                        Our Team
                                    </Button>
                                </a>
                                {/* <Button
                      color="gray_100_01"
                      shape="round"
                      rightIcon={
                        <Img
                          src="images/img_arrowleft_gray_600.svg"
                          alt="Arrow Left"
                          className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
                        />
                      }
                      className="min-w-[9.25rem] gap-[0.63rem] !rounded-[16px]"
                    >
                      Work at EDF
                    </Button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </>

    )
}     