import { Heading, Img } from "./..";
import React from "react";

export default function UserProfile5({
  userImage = "images/img_image_placeholder_8.png",
  userDescription = (
    <>
      Celebrating World Environment Day with inspiring
      <br />
      photography
    </>
  ),
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col w-[32%] md:w-full gap-[1.50rem] bg-white-a700_e5 shadow-xs rounded-[16px]`}
    >
      <Img
        src={userImage}
        alt="Card Image"
        className="h-[14.00rem] w-full rounded-tl-[16px] rounded-tr-[16px] object-cover"
      />
      <div className="mb-[3.25rem] self-stretch px-[1.50rem] sm:px-[1.25rem]">
        <Heading size="headingmd" as="h5" className="uppercase leading-[130%] tracking-[0.00rem]">
          {userDescription}
        </Heading>
      </div>
    </div>
  );
}
