import { Helmet } from "react-helmet";
import { Button, Img, Text, Heading, TextArea, Input } from "../../components/index";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import InfoCard from "../../components/InfoCard";
import NaturalSolutionsProfile from "../../components/NaturalSolutionsProfile";
import UserProfile from "../../components/UserProfile";
import React, { Suspense, useEffect, useState } from "react";
// import { CarouselDefault, CarouselWithContent } from "./slidertest";
import IssueWeAddress from "./module-3HP";
import HowWeGetResults from "./module-4HP";
import UseFetchPlaylistKey from "./useFetchKey";
import SliderOne from "./module-1HP";
import LatestStories from "./module-5HP";





const solutionFeaturesGrid = [
  {
    clockImage: "images/img_clock.svg",
    headingText: "Scientific evidence",
    descriptionText: "Applying evidence to identify the most effective solutions",
  },
  {
    clockImage: "images/img_clock_gray_900.svg",
    headingText: "Economic sustainability",
    descriptionText: "Using economic forces to protect our environment",
  },
  {
    clockImage: "images/img_television.svg",
    headingText: "Powerful partnerships",
    descriptionText: "Creating alliances across a wide range of allies",
  },
  {
    clockImage: "images/img_diversity_2.svg",
    headingText: "Commitment to diversity",
    descriptionText: "Making transformative change for diversity, equity and inclusion",
  },
  {
    clockImage: "images/img_balance.svg",
    headingText: "Climate justice",
    descriptionText: "Building a more equitable home on this planet we share",
  },
  {
    clockImage: "images/img_search.svg",
    headingText: "Strong advocacy",
    descriptionText: "Shaping public policy and taking legal action",
  },
];

const environmentalProjectsGrid = [
  {
    userImage: "images/img_image_placeholder.png",
    headingText: "NATURAL SOLUTIONS",
    descriptionText: "Boosting nature’s ability to capture and store carbon",
  },
  {
    userImage: "images/img_image_placeholder_224x298.png",
    headingText: "METHANE",
    descriptionText: "Slashing methane to slow global warming right now",
  },
  {
    userImage: "images/img_image_placeholder_1.png",
    headingText: "SUSTAINABLE FISHING",
    descriptionText: "Making fishing communities stronger and fish more plentiful",
  },
  {
    userImage: "images/img_image_placeholder_2.png",
    headingText: "AGRICULTURE",
    descriptionText: "Transforming agriculture to benefit billions of people",
  },
  {
    userImage: "images/img_image_placeholder_3.png",
    headingText: "CLEAN AIR",
    descriptionText: "Helping a billion people breathe cleaner air in this decade",
  },
  {
    userImage: "images/img_image_placeholder_4.png",
    headingText: "SAFER CHEMICALS",
    descriptionText: "Moving from toxic chemicals to safer, sustainable alternatives",
  },
  {
    userImage: "images/img_image_placeholder_5.png",
    headingText: "ENERGY TRANSITION",
    descriptionText: "Advancing toward a clean energy future",
  },
  {
    userImage: "images/img_image_placeholder_6.png",
    headingText: "HYDROGEN",
    descriptionText: "Helping the world get hydrogen energy right",
  },
];
const allyJoinList = [
  {
    userImage: "images/img_image_placeholder.png",
    userMessage: "Join a powerful force of over 3 million allies"
  },
  {
    userImage: "images/img_image_placeholder_224x298.png",
    userMessage: "Get tips on how you can protect the environment",
  },
  {
    userImage: "images/img_image_placeholder_224x298.png",
    userMessage: "Get alerts when there are crucial petitions to sign",
  },
];


export default function HomePage() {



  return (
    <>
      <Helmet>
        <title>Home Environment - Join the Fight Against Climate Change</title>
        <meta
          name="description"
          content="Discover how fishing communities are thriving despite climate challenges. Learn about our impactful environmental strategies for a sustainable future. Get involved with our cause for a healthier planet."
        />
      </Helmet>
      <div className="w-full bg-white-a700">
        <div className="flex flex-col items-center">
          <div className="self-stretch bg-text_and_main-white ">
            <div className="relative h-[100vh] lg:h-[100vh] md:h-[92vh] sm:h-[100vh] bg-cover bg-center">
              <div className="absolute w-[100%]  m-auto h-[50.00rem] flex-1 lg:h-auto md:h-auto"> 
          {/* bg-cover bg-no-repeat */}
                <div className="mb-[2.50rem] flex flex-col   sm:gap-[4.56rem]">                
                <Header /><SliderOne />                
                </div>
              </div>
            </div>
          </div>
          
           
          
          <div className="flex flex-col items-center gap-[1.88rem] self-stretch bg-gray-100 pt-[4rem] lg:pt-[2rem] lg:pb-[1.25rem] md:pt-[4rem] md:pb-[1.25rem] sm:pt-[3rem]">
            <div className="flex w-[90%] flex-col items-center py-[1.13rem] lg:w-full lg:p-[1.25rem] md:w-full md:p-[1.25rem]">
              <div className="container-xs mt-[2.63rem] flex flex-col items-center gap-[0.50rem] px-[3.50rem] md:w-[100%] text-center sm:w-[100%] sm:text-center md:px-[1.25rem] sm:px-[1.00rem]">
                <Text as="h2" className="lg:text-[3.00rem]">
                  How we get results that last
                </Text>
                <Text size="text3xl" as="p" className="self-stretch text-center leading-[140%] !text-gray-800_01">
                  We bring together people from all sides and draw from deep expertise to tackle environmental issues
                  from every angle.
                </Text>
              </div>
            </div>
            <div className="container-xs mb-[5.25rem] flex flex-col items-center px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
              <div className="flex w-[90%] justify-center gap-[2.00rem] lg:w-full md:w-full md:flex-col">
                <div className="relative h-[14.50rem] w-full">
                  <Img
                    src="images/img_rectangle_3464568.png"
                    alt="Climate Image"
                    className="absolute bottom-0 left-0 right-0 top-0 m-auto w-[100%] h-[14.50rem] w-full flex-1 rounded-[20px] object-cover"
                  />
                  <div className="absolute left-0 right-0 top-[17%] m-auto flex flex-1 justify-center px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                    <Heading size="headingmd" as="h1" className="uppercase tracking-[0.00rem] !text-white-a700">
                      Stabilize the climate
                    </Heading>
                  </div>
                </div>
                <div className="relative h-[14.50rem] w-full content-center lg:h-auto md:h-auto">
                  <Img
                    src="images/img_rectangle_3464568_232x398.png"
                    alt="Thrive Image"
                    className="mx-auto w-[100%] h-[14.50rem] flex-1 rounded-[20px] object-cover"
                  />
                  <Heading
                    size="headingmd"
                    as="h2"
                    className="absolute left-0 right-0 top-[19%] w-[80%] m-auto flex-1 text-center uppercase leading-[130%] tracking-[0.00rem] !text-white-a700"
                  >
                    Strengthen people and Nature’s ability to thrive
                  </Heading>
                </div>
                <div className="relative h-[14.50rem] w-full">
                  <Img
                    src="images/img_rectangle_3464568_1.png"
                    alt="Health Image"
                    className="absolute bottom-0 left-0 right-0 top-0 m-auto w-[100%] h-[14.50rem] flex-1 rounded-[20px] object-cover"
                  />
                  <div className="absolute left-0 right-0 top-[17%] m-auto flex flex-1 justify-center px-[2.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                    <Heading size="headingmd" as="h3" className="uppercase tracking-[0.00rem] !text-white-a700">
                      Support people’s health
                    </Heading>
                  </div>
                </div>
              </div>
            </div>
          </div>



          <IssueWeAddress />              
          {/* <div className="flex flex-col items-center justify-center gap-[3.75rem] self-stretch bg-gradient1 py-[3.88rem] lg:py-[2.00rem] md:py-[1.25rem] sm:gap-[1.88rem] sm:py-[1.00rem]">
            <div className="mt-[1.00rem] flex w-[90%] flex-col items-center lg:w-full lg:p-[1.25rem] md:w-full md:p-[1.25rem]">
              <div className="container-xs flex flex-col items-center gap-[0.50rem] px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                <Text as="h2" className="!text-white-a700 lg:text-[3.00rem]">
                  Issues we address
                </Text>
                <Text size="text3xl" as="p" className="self-stretch text-center leading-[140%] !text-white-a700">
                  We’re tackling the biggest issues and striving for maximum impact to ensure a clean energy transition,
                  healthy communities and the ability of people and nature to thrive.
                </Text>
              </div>
            </div>
            <div className="container-xs flex flex-col items-center gap-[3.63rem] px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:gap-[1.81rem] sm:px-[1.00rem]">
              <div className="grid w-[90%] grid-cols-4 justify-center gap-[1.50rem] gap-y-[2.50rem] lg:grid-cols- md:grid-cols-2 sm:grid-cols-1">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {environmentalProjectsGrid.map((d, index) => (
                    <NaturalSolutionsProfile {...d} key={"naturalGrid" + index} />
                  ))}
                </Suspense>
              </div>
              <div className="flex w-[25%] flex-col items-center gap-[0.88rem] lg:w-full md:w-full">
                <Text size="text3xl" as="p" className="!text-white-a700">
                  Help Maximize The Impact!
                </Text>
                <Button
                  color="amber_300"
                  size="lg"
                  rightIcon={
                    <Img
                      src="images/img_arrow_blue_gray_900_02.svg"
                      alt="Arrow"
                      className="h-[0.13rem] h-[2.38rem] w-[0.13rem] w-[2.50rem]"
                    />
                  }
                  className="gap-[0.50rem] self-stretch rounded-[30px] font-semibold"
                >
                  Donate
                </Button>
              </div>
            </div>
          </div> */}
          

          <HowWeGetResults />
          {/* <div className="relative lg:h-[60.13rem] self-stretch bg-gray-100_01 px-[5.13rem] py-[1.25rem] lg:px-[2.00rem] md:px-[1.25rem] sm:px-[1.00rem]">
            <Img
              src="images/img_group_1000001631.svg"
              alt="Image Six"
              className="absolute bottom-[6%] left-0 right-0 m-auto h-[51.25rem] w-[46%] object-contain"
            />
            <div className="absolute left-0 right-0 top-[1.25rem] m-auto flex flex-1 flex-col items-center gap-[1.88rem]">
              <div className="flex flex-col items-center self-stretch py-[1.75rem] sm:py-[1.00rem]">
                <div className="container-xs mt-[2.00rem] flex flex-col items-center gap-[0.50rem] px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
                  <Text as="h2" className="lg:text-[3.00rem]">
                    How we get results that last
                  </Text>
                  <Text size="text3xl" as="p" className="self-stretch text-center leading-[140%] !text-gray-800_01">
                    We bring together people from all sides and draw from deep expertise to tackle environmental issues
                    from every angle.
                  </Text>
                </div>
              </div>
              <div className="container-xs flex flex-col items-center px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
                <div className="grid w-[90%] grid-cols-3 justify-center gap-[2.00rem] gap-y-[1.88rem] lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
                  <Suspense fallback={<div>Loading feed...</div>}>
                    {solutionFeaturesGrid.map((d, index) => (
                      <InfoCard {...d} key={"featuresGrid" + index} />
                    ))}
                  </Suspense>
                </div>
              </div>
            </div>

          </div> */}


        {/* LATEST STORIES......  */}


        {/* <LatestStories /> */}

          {/* <div className="flex justify-center self-stretch p-[1.38rem] sm:p-[1.00rem]">
            <div className="mb-[5.75rem] flex w-[94%] flex-col items-center gap-[0.75rem] lg:w-full md:w-full">
              <div className="flex flex-col items-center self-stretch py-[3.38rem] md:py-[1.25rem] sm:py-[1.00rem]">
                <div className="container-xs mt-[0.25rem] flex flex-col items-center gap-[0.38rem] px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
                  <Text as="h2" className="lg:text-[3.00rem]">
                    Latest Stories
                  </Text>
                  <Text size="text3xl" as="p" className="!text-gray-800_01">
                    We bring you in-depth reporting about the people and ideas making a difference.
                  </Text>
                </div>
              </div>
              <div className="container-xs lg:p-[1.25rem] md:p-[1.25rem]">
                <div className="flex items-start md:flex-col">
                  <Img
                    src="images/img_placeholder.png"
                    alt="Placeholder Image"
                    className="h-[35.00rem] w-[54%] self-center rounded-[30px] object-contain md:w-full"
                  />
                  <div className="relative ml-[-11.25rem] flex w-[46%] flex-col items-end gap-[1.88rem] md:ml-0 md:w-full">
                    <Img
                      onClick={handleClickL}
                      src="images/img_arrow_blue_900.svg"
                      alt="Arrow Image"
                      className="h-[5.00rem] w-[20%] object-contain"
                    />
                    <div className="self-stretch rounded-[20px] bg-white-a700_cc p-[3.00rem] shadow-lg md:p-[1.25rem] sm:p-[1.00rem]">
                      <div className="mb-[1.13rem] flex flex-col items-start gap-[0.75rem]">
                        <Heading size="headingmd" as="h2" className="uppercase tracking-[0.00rem] !text-blue-900">
                          Explainer
                        </Heading>
                        <Text
                          size="text6xl"
                          as="h3"
                          className="w-full leading-[120%] tracking-[0.06rem] md:text-[2.00rem] sm:text-[1.63rem]"
                        >
                          Is your neighborhood a toxic chemical hot spot? This new map can tell you.
                        </Text>
                      </div>
                    </div>
                    <div className="flex items-center justify-end self-stretch">
                      <Text size="text_2" as="p" className="!text-black">
                        03
                      </Text>
                      <div className="ml-[1.50rem] flex w-[18%] rounded bg-gray-300">
                        <div className="h-[0.50rem] w-[38%] rounded bg-blue-900" />
                      </div>
                      <Text size="text_2" as="p" className="ml-[1.50rem] !text-black">
                        08
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


          {/* <div className="flex h-[48.13rem] flex-col items-center self-stretch bg-gray-50 bg-[url(/public/images/img_frame_48096608.png)] bg-cover bg-no-repeat py-[4.38rem] lg:h-auto lg:py-[2.00rem] md:h-auto md:py-[1.25rem] sm:py-[1.00rem]">
            <div className="container-xs mb-[1.88rem] flex flex-col items-center gap-[1.00rem] px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
              <Text as="h2" className="!text-white-a700 lg:text-[3.00rem]">
                Maximize your impact
              </Text>
              <div className="flex w-[58%] flex-col items-center rounded-[30px] bg-white-a700_e5 p-[2.75rem] lg:w-full md:w-full md:p-[1.25rem] sm:p-[1.00rem]">
                <Text size="text6xl" as="h3" className="md:text-[2.00rem] sm:text-[1.63rem]">
                  Your gift will have triple the impact
                </Text>
                <Text
                  size="text3xl"
                  as="p"
                  className="mt-[1.13rem] self-stretch text-center leading-[140%] !text-gray-800_01"
                >
                  This Earth Day triple your impact to bring bold, innovative solutions to life in the fight against
                  climate change.
                </Text>
                <Text size="texts" as="p" className="ml-[12.13rem] mt-[2.00rem] self-start !text-gray-800_01 md:ml-0">
                  You give
                </Text>
                <div className="ml-[4.00rem] mr-[3.88rem] flex w-[94%] justify-center gap-[1.38rem] lg:w-full md:mx-0 md:w-full md:flex-col">
                  <Button color="white_A700_e5" size="md" className="flex-1 rounded-[30px] font-medium md:self-stretch  hover:bg-blue-900">
                    $10
                  </Button>
                  <Button color="white_A700_e5" size="md" className="flex-1 rounded-[30px] font-medium md:self-stretch hover:bg-blue-900">
                    $15
                  </Button>
                  <Button color="white_A700_e5" size="md" className="flex-1 rounded-[30px] font-medium md:self-stretch hover:bg-blue-900">
                    $25
                  </Button>
                  <Button color="white_A700_e5" size="md" className="flex-1 rounded-[30px] font-medium md:self-stretch hover:bg-blue-900">
                    $50
                  </Button>
                  <Button color="white_A700_e5" size="md" className="min-w-[7.38rem] rounded-[30px] font-medium hover:bg-blue-900">
                    Other
                  </Button>
                </div>
                <Text size="texts" as="p" className="ml-[11.13rem] mt-[0.25rem] self-start !text-gray-800_01 md:ml-0">
                  EDF Gets $45
                </Text>
                <div className="mb-[0.38rem] ml-[5.50rem] mr-[4.88rem] mt-[2.13rem] flex w-[94%] justify-center gap-[2.13rem] lg:w-full md:mx-0 md:w-full sm:flex-col">
                  <div className="flex items-center gap-[0.50rem] p-[0.63rem]">
                    <Heading as="h2" className="!text-gray-800">
                      Donate Once
                    </Heading>
                    <Img src="images/img_arrow_gray_800.svg" alt="Arrow Image" className="h-[2.38rem]" />
                  </div>
                  <div className="flex flex-1 items-center justify-center gap-[0.50rem] rounded-[30px] bg-blue-900 p-[0.63rem] sm:self-stretch">
                    <Heading size="headings" as="h3" className="!text-text_and_main-white">
                      Donate Monthly
                    </Heading>
                    <Img src="images/img_arrow.svg" alt="Arrow Image" className="h-[2.38rem]" />
                  </div>
                </div>
              </div>
            </div>
          </div> */}

         {/* {Another easy way to help} */}
          {/* <div className="flex flex-col items-center gap-[3.75rem] self-stretch bg-gray-100_01 py-[3.75rem] md:text-center md:py-[1.25rem] sm:gap-[1.88rem] sm:py-[1.00rem]">
            <div className="flex w-[90%] justify-center lg:w-full lg:p-[1.25rem] md:w-full md:p-[1.25rem]">
              <div className="container-xs flex justify-center px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                <Text as="h2" className="lg:text-[3.00rem]">
                  Another easy way to help
                </Text>
              </div>
            </div>
            <div className="container-xs mb-[6.00rem] flex flex-col items-center px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
              <div className="flex w-[90%] gap-[1.50rem] px-[0.25rem] md:w-full md:flex-col">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {allyJoinList.map((d, index) => (
                    <UserProfile {...d} key={"cardsRow1" + index} />
                  ))}
                </Suspense>
              </div>
            </div>
          </div> */}

         
        </div>
        <Footer />
      </div>
    </>
  );
}
