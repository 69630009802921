import { Img, Heading, Text } from "./..";
import React from "react";

export default function UserProfile7({
  userImage = "images/img_image_placeholder_224x456.png",
  headlineText = "Tell EPA: Our Children Deserve a Clean Ride to School",
  subheadlineText = "Boosting nature’s ability to capture and store carbon",
  learnMoreText = "Learn more",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} flex flex-col w-[32%] bg-white-a700_e5 shadow-md rounded-[16px]`}>
      <Img
        src={userImage}
        alt="Card Image"
        className="h-[14.00rem] w-full rounded-tl-[16px] rounded-tr-[16px] object-cover"
      />
      <div className="mt-[2.00rem] flex flex-col gap-[0.88rem] self-stretch px-[2.50rem] sm:px-[1.25rem]">
        <Heading size="headinglg" as="h4" className="leading-[130%] tracking-[0.00rem]">
          {headlineText}
        </Heading>
        <Text size="text_2" as="p" className="leading-[150%]">
          {subheadlineText}
        </Text>
        <div className="flex items-center">
          <a href="#">
            <Heading as="p">{learnMoreText}</Heading>
          </a>
          <Img src="images/img_arrow_blue_gray_900_02.svg" alt="Arrow Image" className="h-[2.38rem]" />
        </div>
      </div>
      <div className="mx-[2.50rem] mb-[1.88rem] mt-[3.50rem] flex items-center justify-end gap-[1.31rem] self-stretch">
        <Img src="images/img_facebook.svg" alt="Facebook Image" className="h-[1.63rem] w-[1.63rem]" />
        <Img src="images/img_airplane.svg" alt="Airplane Image" className="h-[1.38rem] self-end" />
      </div>
    </div>
  );
}
