export const AssistantManager = {
  positionId: "Assistant_Manager_Climate_Resilient_Fisheries",
  organization: {
    name: "Environmental Defense India Foundation (EDIF)",
    type: "Not-for-Profit",
    legal_entity:
      "Tax-exempt legal entity under Section 8 of the India Companies Act, 2013",
    registered_office:
      "Shop 220 Anar Kali Bazar, Cycle Market, Jhande Walan, Videocon Tower, New Delhi, Delhi – 110 005",
    mission:
      "Support an Indian pathway to shared, sustainable prosperity aligned with India’s ambition, combining science, economics, and pragmatism to advance climate action.",
  },
  position: {
    title: "Assistant Manager, Climate Resilient Fisheries",
    team: "Climate Resilient Fisheries",
    reporting_to: "Senior Manager, Climate Resilient Fisheries",
    overall_function: `The Sr Manager will oversee development and implementation of key aspects of EDIF’s fisheries
projects and initiatives, which seek to safeguard the oceans and protect nature while feeding the
planet, creating more secure livelihoods, and improving human well-being. They will develop and
oversee partnerships, lead strategic initiatives, independently guide and manage projects, and
foster collaboration among NGOs, agencies, private sector actors, and partners. Under the
leadership of Trustee EDIF, Hisham Mundol, this position uses experience, knowledge, and a
collaborative approach to advance projects that support the health of our oceans and the people
who depend on them for food and their livelihoods.`,
  },
  keyResponsibilities: [
    "Conduct desk and field research on topics relevant to fisheries and coastal livelihoods, including ocean and fisheries science, science-based fisheries management, climate resilience, seafood supply chains and markets, technologies for sustainable fisheries, and related topics.",
    "Aid in the design and implementation of research projects with partner organizations and diverse stakeholders, and support trust building and idea generation.",
    "Support partnerships with government institutions, academic partners, NGOs, and technical bodies, through coordinated research and pilot projects.",
    "Contribute to the design and implementation of EDIF-sponsored workshops and events.",
    "Represent EDIF at external conferences, workshops, and events.",
    "Develop knowledge products based on new research, collaborative workshops, existing publications, and other sources.",
    "Author reports, white papers, news articles, scientific journal articles, and other publications both independently and in collaboration with internal and external partners.",
    "Communicate findings and insights effectively to non-technical stakeholders in a variety of formats.",
    "Stay up to date on relevant research and build and maintain scientific networks.",
    "Evaluate projects, identify new opportunities, engage in thought-partnership, disseminate and communicate progress and outcomes, and support strategic refinement of plans as part of the broader EDIF team.",
    "Authentically contribute to advancing EDIF’s goals where people from all backgrounds and experiences feel connected, included, and empowered to address the environmental and organizational challenges in alignment with EDIF values.",
  ],
  qualifications: [
    "Bachelor’s degree or equivalent in a relevant field with at least 4 years of relevant experience, or master’s degree or equivalent may substitute for up to 2 years of relevant experience, or Ph.D. or equivalent may substitute for up to 4 years of relevant experience.",
    "Strong technical knowledge of fisheries science and management, climate science, marine ecology, and related approaches and processes.",
    "A flexible learner, willing to adapt and adopt new skills and approaches.",
    "Proactive, attentive to detail, and strong research and analysis skills.",
    "Exceptional critical thinking, problem-solving, and interpersonal skills.",
    "Strong ability to work both independently and as a team player, using independent judgment required to plan, prioritize, and organize diversified workload in a fast-paced multi-tasking environment.",
    "Strong speaking and writing skills, with a high degree of comfort making presentations to senior internal staff and outside stakeholders.",
    "Demonstrates self-awareness, cultural competency, and inclusivity, and ability to work with colleagues and stakeholders across diverse cultures and backgrounds.",
    "English fluency (written and oral) required and multilingual fluency a plus (e.g., Hindi, Marathi, Tamil, etc.).",
    "Ability and willingness to travel both domestically and internationally (30% of the time).",
    "Demonstrates self-awareness, cultural competency, and inclusivity, and ability to work with colleagues and stakeholders across diverse cultures and backgrounds.",
  ],
  language: {
    required: "English fluency (written and oral)",
    preferred: ["Hindi", "Marathi", "Tamil", "others"],
  },
  travel:
    "Ability and willingness to travel domestically and internationally (30% of the time)",
};
export const PortfolioAnalyst = {
  positionId: "Portfolio_Analyst",
  organization: {
    name: "Environmental Defense India Foundation (EDIF)",
    type: "Not-for-Profit",
    legal_entity:
      "Tax-exempt legal entity under Section 8 of the India Companies Act, 2013",
    registered_office:
      "Shop 220 Anar Kali Bazar, Cycle Market, Jhande Walan, Videocon Tower, New Delhi, Delhi – 110 005",
    mission:
      "Support an Indian pathway to shared, sustainable prosperity. We seek to bring scientific and economic expertise and a spirit of diverse, authentic partnerships to advance India's climate agenda.",
  },
  position: {
    title: "Portfolio Analyst",
    team: "EDIF",
    supervisor: "Hisham Mundol, Director, EDIF",
    overall_function_points: [
      "The economic opportunity for methane management, particularly in the Indian oil & gas sector – which is amongst the cheapest, fastest, and easiest options for decarbonising this sector.",
      "How India can go big and right on hydrogen – given the strategic importance of green hydrogen, and the opportunity for India to become a net-exporter of energy for the first time.",
      "Building fit-for-purpose carbon pricing (including carbon markets) in India – to accelerate and incentivise climate action by Indian industry.",
      "Establishing the opportunity for shareholder value creation through responsible business – and supporting Indian companies with authentic, ambitious, and concerted climate ambition.",
      "Increasing domestic R&D spends and catalysing the Indian climate tech ecosystem – to provide fit-for-purpose technologies that India will need to ensure greener growth as India is projected to double most industrial and commercial sectors over the next decade.",
      "Optimising the levels of fertiliser (and other resources) used in Indian agriculture, such that yields are protected, farmer incomes are increased, and government subsidies are used judiciously – all with reduced environmental impacts.",
    ],
    overall_function:
      "The Portfolio Analyst consultant to EDIF will work across a breadth of thematic areas driving high-quality analysis that is useful for constructing narratives, informing policy and inspiring action. The role will focus on supporting India's climate agenda with innovative research and actionable insights, spanning areas like methane management, hydrogen, carbon pricing, responsible business, climate tech, and sustainable agriculture.",
  },
  keyResponsibilities: [
    "Development of rigorous, quality research and analytics across a diverse range of technical, scientific, and economic topics.",
    "Adaptation of technical, scientific and economic collateral into material that is fit-for-purpose for outreach and engagement.",
    "Designing a model to identify and showcase growth-stage climate tech innovations that are climate-science sound and have the potential to scale in India.",
    "Supporting outreach and engagement with national and state level policymakers, government institutions/bodies, think tanks, and other partners.",
    "Building and fostering coalitions, acting as an ambassador for EDIF.",
    "Supporting EDIF’s team and other partners with research and analytics support to align engagement with policymakers and coordinating across stakeholders.",
    "Monitoring progress based on milestones and timelines defined in the work plan.",
    "Preparing and reviewing annual reports on project progress across ongoing projects.",
    "Facilitating the development and deployment of assessment tools for monitoring and impact evaluations, including third-party evaluations.",
    "Increasing the salience of core issues through strategic communication and positioning EDIF as a thought leader.",
    "Assisting with communications materials on ongoing projects, capturing progress and impact in monthly and quarterly reports.",
    "Participating in advancing EDIF’s diversity, equity, and inclusion goals, ensuring inclusivity and empowerment for all stakeholders.",
  ],
  qualifications: [
    "2-5 years of experience in climate change-related areas.",
    "Strong experience and expertise in research and analytics.",
    "Quick and eager learner, comfortable straddling a diverse range of topics.",
    "Highly effective written and oral communication skills.",
    "Understanding of the political economy of India.",
    "Ability and willingness to work with a global team across multiple time zones, including joining calls beyond normal business hours.",
    "Attention to detail and personal organization skills.",
    "Ability to work both independently and as part of a team in a fast-paced, dynamic, and entrepreneurial environment.",
    "Demonstrates self-awareness, cultural competency, and inclusivity, with the ability to work across diverse cultures and backgrounds.",
  ],
  language: {
    required: "English fluency (written and oral)",
    preferred: ["Hindi", "Marathi", "Tamil", "others"],
  },
  travel: "Up to 10% of the time",
  tenure: "12 months",
  consultancyFee: "Up to INR 12 Lacs per annum",
};
export const SenioManager = {
  positionId: "Senior_Manager_Climate_Resilient_Fisheries",
  organization: {
    name: "Environmental Defense India Foundation (EDIF)",
    type: "Not-for-Profit",
    legal_entity:
      "Tax-exempt legal entity under Section 8 of the India Companies Act, 2013",
    registered_office:
      "Shop 220 Anar Kali Bazar, Cycle Market, Jhande Walan, Videocon Tower, New Delhi, Delhi – 110 005",
    mission:
      "Support an Indian pathway to shared, sustainable prosperity aligned with India’s ambition, combining science, economics, and pragmatism to advance climate action.",
  },
  position: {
    title: "Senior Manager, Climate Resilient Fisheries",
    team: "Climate Resilient Fisheries",
    reporting_to: "Trustee EDIF, Hisham Mundol",
    overall_function: `The Sr Manager will oversee development and implementation of key aspects of EDIF’s fisheries
    projects and initiatives, which seek to safeguard the oceans and protect nature while feeding the
    planet, creating more secure livelihoods, and improving human well-being. They will develop and
    oversee partnerships, lead strategic initiatives, independently guide and manage projects, and
    foster collaboration among NGOs, agencies, private sector actors, and partners. Under the
    leadership of Trustee EDIF, Hisham Mundol, this position uses experience, knowledge, and a
    collaborative approach to advance projects that support the health of our oceans and the people
    who depend on them for food and their livelihoods.`,
  },
  keyResponsibilities: [
    {
      category: "Strategy & Leadership",
      responsibilities: [
        "Lead the strategic direction of EDIF’s fisheries-related activities.",
        "Develop and lead projects that advance science-based fisheries management, sustainable seafood supply chains and markets, ocean and fisheries science, technological innovation, fisheries policy and governance, and climate resilience principles and practices to address key challenges in fisheries and coastal livelihoods.",
      ],
    },
    {
      category: "Building Science & Solutions",
      responsibilities: [
        "Lead the development of research and knowledge products that make impactful contributions towards addressing India’s fisheries-related challenges.",
      ],
    },
    {
      category: "Partnerships",
      responsibilities: [
        "Develop partnerships between EDIF and government agencies, academic institutions, NGOs, private sector actors, and fishing organizations to develop and implement cutting-edge approaches for building resilience in fisheries and fishing communities.",
        "Collaborate with partners to develop and apply technical tools and resources to design and implement climate-resilient fisheries policy and management approaches.",
      ],
    },
    {
      category: "Representation",
      responsibilities: [
        "Develop and lead implementation of EDIF-sponsored workshops and events.",
        "Represent EDIF at external conferences, workshops, and events.",
      ],
    },
    {
      category: "Management & Operations",
      responsibilities: [
        "Manage the day-to-day activities of EDIF’s fisheries-related partnerships, projects, and activities.",
        "Develop work plans, manage, and monitor progress towards the successful completion of project activities and grant deliverables.",
        "Manage, mentor, and support a small team of staff and consultants.",
      ],
    },
    {
      category: "Fundraising",
      responsibilities: [
        "Support fundraising efforts to promote long-term sustainability and growth of EDIF’s fisheries and oceans work.",
      ],
    },
    {
      category: "Culture",
      responsibilities: [
        "Authentically contribute to advancing EDIF’s goals where people from all backgrounds and experiences feel connected, included, and empowered to address the environmental and organizational challenges in alignment with EDIF values.",
      ],
    },
  ],
  qualifications: [
    "Bachelor’s degree or equivalent in a relevant field with at least 10 years of relevant experience, or a master’s degree or equivalent may substitute for up to 2 years of relevant experience, or a Ph.D. or equivalent may substitute for up to 4 years of relevant experience.",
    "Strong technical knowledge of fisheries management concepts, approaches, and processes, with the ability to relate concepts and best practices to regional contexts and diverse stakeholders.",
    "Strong technical knowledge of fisheries systems, including seafood markets and supply chains.",
    "A self-starter who can put innovative ideas into action, with the ability to identify challenges and work proactively to address them.",
    "A flexible learner, willing to adapt and adopt new skills and approaches.",
    "Strong partnership development skills, with experience in developing inter-organizational projects.",
    "Excellent ability to coordinate and collaborate with multiple stakeholders, colleagues, and partners of varied backgrounds and experience.",
    "Strong project management skills, with experience in developing and leading projects, initiatives, and partnerships.",
    "Strong ability to work both independently and as a team player, using independent judgment to plan, prioritize, and organize a diversified workload in a fast-paced, multi-tasking environment.",
    "Experience managing a small team, supporting team growth and development, and mentoring junior colleagues.",
    "Superior speaking and writing skills, with a high degree of comfort making presentations to senior internal staff and external stakeholders.",
    "English fluency (written and oral) required, with multilingual fluency (e.g., Hindi, Marathi, Tamil, etc.) considered a plus.",
    "Ability and willingness to travel both domestically and internationally (30% of the time).",
    "Demonstrates self-awareness, cultural competency, and inclusivity, with the ability to work with colleagues and stakeholders across diverse cultures and backgrounds.",
  ],
  language: {
    required: "English fluency (written and oral)",
    preferred: ["Hindi", "Marathi", "Tamil", "others"],
  },
  travel:
    "Ability and willingness to travel domestically and internationally (30% of the time)",
};

export const jobData = [
  { id: "1", ...AssistantManager },
  { id: "2", ...SenioManager },
  { id: "3", ...PortfolioAnalyst },
];
