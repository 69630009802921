import { Heading, Img } from "./..";
import React from "react";

export default function PodcastProfile({
  podcastImage = "images/img_image_placeholder_14.png",
  podcastTitle = "PODCAST",
  podcastDescription = "Want to use your job to save the planet?Degrees is for you.",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col justify-center w-[36%] md:w-full gap-[1.75rem] bg-white-a700_e5 shadow-xs rounded-[18px]`}
    >
      <Img
        src={podcastImage}
        alt="Podcast Image"
        className="h-[16.75rem] w-full rounded-tl-[18px] rounded-tr-[18px] object-cover"
      />
      <div className="mb-[0.25rem] self-stretch px-[1.75rem] sm:px-[1.25rem]">
        <div className="flex flex-col items-start gap-[0.75rem]">
          <Heading as="p" className="!font-normal">
            {podcastTitle}
          </Heading>
          <Heading
            size="headingxl"
            as="h4"
            className="mb-[4.25rem] w-full !text-[1.63rem] leading-[130%] tracking-[0.00rem]"
          >
            {podcastDescription}
          </Heading>
        </div>
      </div>
    </div>
  );
}
