import React, { Suspense, useEffect, useState } from 'react';
import { Button, Img, Text, Heading, TextArea, Input } from "../../components/index";
import MobileCarousel from 'pages/HomePage/MobileCarousel';
import { useNavigate } from "react-router-dom";





export default function OurApproachToGetting() {
  const apiUrl = process.env.REACT_APP_LOCAL_SERVER;
  const navigate = useNavigate();
  const [playlistKey, setPlaylistKey] = useState(null);
  const [subjectContent, setSubjectContent] = useState([])
  const [issueData, setIssueData] = useState([])
  const [contentID, setContentID] = useState(null)
  const [contentBlockId, setContentBlockId] = useState("")
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);






  const fetchKey = async () => {
    try {
      const response = await fetch(`${apiUrl}/page/page/2?siteId=822`);
      // const response = await fetch(`${apiUrl}/page/2?siteId=785`);
      const jsonResponse = await response.json();
      setPlaylistKey(jsonResponse?.data?.playlist?.[1].key || null);
      // console.log(jsonResponse.data.playlist, "key")

    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKey();
  }, [apiUrl]);

  const getDataFromKeys = async () => {
    if (!playlistKey) return;
    try {
      const mresponse = await fetch(`${apiUrl}/playlist/playlist/${playlistKey}?siteId=822`);
      // const mresponse = await fetch(`${apiUrl}/playlist/${playlistKey}?siteId=785`);
      const mresponseContent = await mresponse.json();
      // console.log(mresponseContent.data.content, "json")
      setIssueData(mresponseContent?.data?.content || []);



      // console.log(mresponseContent.data.content, "sub")

      // console.log(mresponseContent.data,"setSubjectContent");
      setSubjectContent(mresponseContent?.data || []);

    } catch (err) {
      setError(err);
    }

  };

  useEffect(() => {
    if (playlistKey) {
      getDataFromKeys();
    }
  }, [playlistKey]);

  const getDataForId = async () => {
    if (!contentID) return;

    try {
      const mresponse = await fetch(`${apiUrl}/content/content-block/${contentID}?siteId=822`);
      const mresponseContent = await mresponse.json();
      console.log(mresponseContent, "ID")
      console.log(mresponseContent.data?.content?.[0]?.id, "ID")
      setContentBlockId(mresponseContent?.data?.content || []);

      // setIssueID(mresponseContent.data?.content?.[0] || null)
      // subjectContent.content.


    } catch (err) {
      console.log('errrr1', err)
      setError(err);
    }

  };

  useEffect(() => {
    if (contentID) {
      getDataForId();
    }
  }, [contentID]);



  const issueData1 = issueData?.[0]?.id || null

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  const handleArticlePage = (issueData1) => {

    console.log(issueData1);
    localStorage.setItem('articlePageApi', issueData1.id)
    navigate(`${issueData1.category}/articles/${issueData1.slug}`)
  }





  return (
    <>
      <div className=" bg-gray-100_01 px-[5.13rem] lg:px-[2.00rem] ">
        {/* <Img
          src="images/img_group_1000001631.svg"
          alt="Image Six"
          className="absolute bottom-[6%] left-0 right-0 m-auto w-[46%] object-contain z-10 md:w-[80%] md:top-[20%]"
        /> */}
        <div className=" m-auto flex flex-1 flex-col items-center gap-[1.88rem]">
          <div className="flex w-[90%] flex-col items-center py-[1.13rem] lg:w-full lg:p-[1.25rem] md:w-full md:p-[1.25rem] sm:p-[0rem]">

            <div className="container-xs mt-[2.63rem] flex flex-col items-center gap-[0.50rem] px-[3.50rem] md:w-[100%] text-center sm:text-center sm:w-[100%] md:px-[1.25rem] sm:px-[00rem]" >
              <Text as="h2" className="mb-[1.2rem] lg:text-[3.00rem] lg:mb-[1rem] ">{subjectContent?.name}</Text>
              <Text size="text3xl" as="p" className="self-stretch text-justify leading-[140%] !text-gray-800_01 md:px-[1rem] sm:px-[0rem]">{subjectContent?.headline} </Text>
            </div>

            {/* <div className="container-xs mt-[2.00rem] flex flex-col items-center gap-[0.50rem] px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
                  <Text as="h2" className="lg:text-[3.00rem]">
                    How we get results that last
                  </Text>
                  <Text size="text3xl" as="p" className="self-stretch text-center leading-[140%] !text-gray-800_01">
                    We bring together people from all sides and draw from deep expertise to tackle environmental issues
                    from every angle.
                  </Text>
                </div> */}
          </div>


          {/* <MobileCarousel issueData={issueData} /> */}

          {/* <div className="md:hidden container-xs flex flex-col items-center px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]"> */}
            {/* <div className="grid w-[90%] grid-cols-2 justify-center z-10 gap-[2.00rem] gap-y-[1.88rem] lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1"> */}
              {/* <Suspense fallback={<div>Loading feed...</div>}>
                    {solutionFeaturesGrid.map((d, index) => (
                      <InfoCard {...d} key={"featuresGrid" + index} />
                    ))}
                  </Suspense> */}

              {/* {issueData.map((item, index) => (
                <div key={index} className="flex items-center w-full px-[1.00rem] py-[2.75rem] lg:px-[1.00rem] lg:py-[1.75rem] md:py-[1.25rem] sm:p-[1.00rem] bg-white-a700 shadow-xs rounded-[20px]" >
                  <div className="flex w-full flex-col items-center gap-[1.75rem]">
                    <div className="flex flex-col items-center  rounded-[20px] p-[1.25rem]">
                      <Img src={item.images[0].imageUrl} alt="Clock Image" className=" cursor-pointer h-[4.75rem] w-[4.75rem]" onClick={handleArticlePage} />
                    </div>
                    <div className="flex flex-col items-center gap-[0.38rem] self-stretch">
                      <Heading size="headingmd" as="h5" className="uppercase cursor-pointer tracking-[0.00rem] lg:text-[1.00rem]" onClick={handleArticlePage}>
                        {item.name}
                      </Heading>
                      <Text size="text_2" as="p" className="self-stretch cursor-pointer text-center leading-[150%] lg:text-[0.84rem]" onClick={handleArticlePage}>
                        {item.excerpt}
                      </Text>
                    </div>

                  </div>
                </div>
              )
              )} */}




            {/* </div> */}
          {/* </div> */}
        </div>

      </div>


    </>

  )
}     