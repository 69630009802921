import React, { Suspense, useEffect, useState } from 'react';
import { Button, Img, Text, Heading, TextArea, Input } from "../../components/index";
import NaturalSolutionsProfile from "../../components/NaturalSolutionsProfile";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import ApiTest from './apitest';
import { useNavigate } from "react-router-dom";
import { DoubleArrow } from '@mui/icons-material';




export default function IssueWeAddress() {
    const apiUrl = process.env.REACT_APP_LOCAL_SERVER;
    const [playlistKey, setPlaylistKey] = useState(null);
    const [subjectContent, setSubjectContent] = useState([])
    const [issueData, setIssueData] = useState([])
    const [contentID, setContentID] = useState(null)
    const [contentBlockId, setContentBlockId] = useState("")
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    console.log(contentID, "contentBlockId");





    const fetchKey = async () => {
        try {
            const response = await fetch(`${apiUrl}/page/page/1?siteId=822`);
            // const response = await fetch(`${apiUrl}/page/1?siteId=785`);
            const jsonResponse = await response.json();
            setPlaylistKey(jsonResponse?.data?.playlist?.[2]?.key || null);
            console.log(jsonResponse.data, "json");
        } catch (err) {
            console.log('errrr1')
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchKey();
    }, [apiUrl]);

    const getDataFromKeys = async () => {
        if (!playlistKey) return;
        try {
            const mresponse = await fetch(`${apiUrl}/playlist/playlist/${playlistKey}?siteId=822`);
            // const mresponse = await fetch(`${apiUrl}/playlist/${playlistKey}?siteId=785`);
            const mresponseContent = await mresponse.json();
            setIssueData(mresponseContent?.data?.content || []);
            setSubjectContent(mresponseContent?.data || []);
            console.log(mresponseContent?.data?.content, "m-3");
            setContentID(mresponseContent?.data?.content[1]?.id || null);
            console.log(mresponseContent.data, "m-3");




        } catch (err) {
            console.log('errrr1')
            setError(err);
        }

    };

    useEffect(() => {
        if (playlistKey) {
            getDataFromKeys();
        }
    }, [playlistKey]);

    const getDataForId = async () => {
       
        if (!contentID) return;

        try {
            // const mresponse = await fetch(`${apiUrl}/content/content-block/66b343aaff504887e6993985?siteId=785`);
            const mresponse = await fetch(`${apiUrl}/content/content-block/${contentID}?siteId=822`); // content-block ID -66b1d8990bf8835dfe074c07, 66b1eae50bf8835dfe074c0f
            // const mresponse = await fetch(`${apiUrl}/content/content-block/${contentID}?siteId=785`); // content-block ID -66b1d8990bf8835dfe074c07, 66b1eae50bf8835dfe074c0f
            
            const mresponseContent = await mresponse.json();
            console.log(mresponseContent, "ID")
            console.log(mresponseContent.data?.content?.[0]?.id, "ID")
            setContentBlockId(mresponseContent?.data?.content || []);

            // setIssueID(mresponseContent.data?.content?.[0] || null)
            // subjectContent.content.


        } catch (err) {
            console.log('errrr1', err)
            setError(err);
        }

    };

    useEffect(() => {
        if (contentID) {
            getDataForId();
        }
    }, [contentID]);

    


    // const handleArticlePage = (contentBlockId) => {
       
    //         console.log(contentBlockId);
    //         localStorage.setItem('articlePageApi', contentBlockId.id)

    //         navigate(`category/articles/${contentBlockId.slug}`)
        


    // }




    return (
        <>
            <ApiTest />
            <div className="flex flex-col items-center justify-center gap-[3.75rem] self-stretch bg-gradient1 py-[3.88rem] lg:py-[2.00rem] md:py-[1.25rem] md:gap-[1rem] sm:gap-[0rem] sm:py-[0rem]">
                <div className="mt-[1.00rem] flex w-[90%] flex-col items-center lg:w-full lg:p-[1.25rem] md:w-full ">
                    <div className="container-xs flex flex-col items-center gap-[0.50rem] px-[3.50rem] sm:w-full sm:px-[1.00rem]">
                        <Text as="h2" className="!text-white-a700 text-center lg:text-[3.00rem] sm:lg:text-[2.0rem]">
                            {/* Issues we address */}{subjectContent?.name}
                        </Text>
                        <Text size="text2xl" as="p" className="self-stretch text-center leading-[140%] !text-white-a700 lg:px-[3.3rem] md:text-[1.1rem] md:px-[1rem] sm:lg:text-[0.80rem] ">
                            {/* We’re tackling the biggest issues and striving for maximum impact to ensure a clean energy transition,
                            healthy communities and the ability of people and nature to thrive. */}
                            {subjectContent?.headline}
                        </Text>
                    </div>
                </div>


                <div className="container-xs flex flex-col items-center gap-[3.63rem] px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:gap-[1.81rem] sm:px-[1.00rem]">
                    <div className="grid w-[90%] grid-cols-4 justify-center gap-[1.50rem] gap-y-[2.50rem] lg:grid-cols- md:grid-cols-2 sm:grid-cols-1">

                        {issueData?.map((item, index) => (
                            <div key={index} className="flex flex-col w-full lg:gap-[1.0rem] gap-[1.13rem] bg-white-a700_e5 shadow-md rounded-[16px] overflow-hidden relative" >
                                <div className="h-[10.00rem] w-full rounded-tl-[16px] rounded-tr-[16px] object-cover"> <Img className="h-[10.00rem] w-full rounded-tl-[16px] rounded-tr-[16px] object-cover" src={item?.images[0]?.imageUrl} /> </div>
                                <div className=' hidden lg:hidden md:hidden sm:block absolute bottom-[0] left-[0] text-white-a700 p-[0.7rem] smiwa_bg' >
                                    {item?.name}  <ArrowRightAltIcon />
                                </div>
                                <div className=" sm:hidden mb-[1.50rem] flex flex-col items-start gap-[0.25rem] self-stretch px-[1.50rem] sm:px-[1.25rem]" >
                                    <Heading size="headingmd" as="h5" className="  tracking-[0.00rem] lg:text-[0.95rem]"> {item?.name}</Heading>
                                    <Text size="text_2" as="p" className="  w-full leading-[150%] lg:text-[0.85rem]">{item?.excerpt}</Text>
                                </div>
                            </div>
                        ))}


                    </div>



                </div>

                {/* <div className="flex w-[25%] flex-col items-center gap-[0.88rem] lg:w-full md:w-full">
                <Text size="text3xl" as="p" className="!text-white-a700">
                  Help Maximize The Impact!
                </Text>
                <Button
                  color="amber_300"
                  size="lg"
                  rightIcon={
                    <Img
                      src="images/img_arrow_blue_gray_900_02.svg"
                      alt="Arrow"
                      className="h-[0.13rem] h-[2.38rem] w-[0.13rem] w-[2.50rem]"
                    />
                  }
                  className="gap-[0.50rem] self-stretch rounded-[30px] font-semibold"
                >
                  Donate
                </Button>
              </div> */}
            </div >

        </>

    )
}