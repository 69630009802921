import { Helmet } from "react-helmet";
import { Button, Img, Text, Heading, TextArea, Input } from "../../components/index";
import AdvisoryButtons from "../../components/AdvisoryButtons";
import AnnualReportButtons from "../../components/AnnualReportButtons";
import BlogSection from "../../components/BlogSection";
import FeatureCardList from "../../components/FeatureCardList";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import InfoCard from "../../components/InfoCard";
import LocalBusinessProfile from "../../components/LocalBusinessProfile";
import MercuryStandardsInfo from "../../components/MercuryStandardsInfo";
import NaturalSolutionsProfile from "../../components/NaturalSolutionsProfile";
import UserProfile from "../../components/UserProfile";
import UserProfile10 from "../../components/UserProfile10";
import { Suspense } from "react";
import ClimateSmartAgricultureModule from "./module-1CSA";


export default function ClimateSmartAgriculture() {

  const blogHighlightsGrid = [
    {
      blogTitle: "BLOG",
      blogDescription: "Growing Returns: EDF blog featuring discussions of resilient land and water systems",
    },
    {
      blogTitle: "OVERVIEW",
      blogDescription: "Soil carbon: Can cropland soils be a part of the climate solution?",
    },
    {
      blogTitle: "VIDEO",
      blogDescription: "Fireside chat: Dairy for nutrition and livelihoods, a perspective from India",
    },
    {
      blogTitle: "REPORT",
      blogDescription: "Heat stress threatens agricultural workers’ health",
    },
    {
      blogTitle: "INTEACTIVE TOOL",
      blogDescription: "How climate change threatens U.S. crop production",
    },
    {
      blogTitle: "BLOG POST",
      blogDescription: "Managing climate change risks at agricultural finance institutions",
    },
  ];

  const teamMembersGrid = [
    {
      userImage: "../../images/img_ellipse_29_1.png",
      userName: "Britt Groosman",
      userTitle: "Vice President, Climate-Smart Agriculture",
    },
    {
      userImage: "../../images/img_ellipse_29_150x150.png",
      userName: "Eileen McLellan",
      userTitle: "Lead Senior Scientist"
    },
    {
      userImage: "../../images/img_ellipse_29_1.png",
      userName: "Maggie Monast",
      userTitle: "Senior Director, Climate-Smart Agriculture",
    },
    {
      userImage: "../../images/img_ellipse_29_2.png",
      userName: "Peri Rosenstein",
      userTitle: "Senior Scientist, Livestock Systems",
    },
    {
      userImage: "../../images/img_ellipse_30.png",
      userName: "John Tauzel",
      userTitle: "Senior Director, Global Agriculture Methane",
    },
    {
      userImage: "../../images/img_ellipse_31.png",
      userName: "Ben Thomas",
      userTitle: "Senior Policy Director, Agriculture"
    },
  ];

  const allyJoinList = [
    {
      userImage: "../../images/img_image_placeholder.png",
      userMessage: "Join a powerful force of over 3 million allies"
    },
    {
      userImage: "../../images/img_image_placeholder_224x298.png",
      userMessage: "Get tips on how you can protect the environment",
    },
    {
      userImage: "../../images/img_image_placeholder_224x298.png",
      userMessage: "Get alerts when there are crucial petitions to sign",
    },
  ];

  const environmentalProjectsGrid = [
    {
      userImage: "../../images/Image Placeholder 1.png",
      headingText: "OVERVIEW",
      descriptionText: "Helping livestock farmers cut methane emissions",
    },
    {
      userImage: "../../images/Image Placeholder 1 (1).png",
      headingText: "OVERVIEW",
      descriptionText: "The race to climate-proof agriculture",
    },
    {
      userImage: "../../images/Image Placeholder 1 (2).png",
      headingText: "INITIATIVE",
      descriptionText: "A farm bill that helps farmers, ranchers and the climate",
    },
    {
      userImage: "../../images/Image Placeholder 1 (3).png",
      headingText: "WEBSITE",
      descriptionText: "Harnessing the power of farm loans and crop insurance",
    },
    {
      userImage: "../../images/Image Placeholder 1 (4).png",
      headingText: "INITIATIVE",
      descriptionText: "Make the invisible loss of nitrogen visible",
    },
    {
      userImage: "../../images/Image Placeholder 1 (5).png",
      headingText: "ARTICLE",
      descriptionText: "Farmers and scientists dig into the climate power of soil",
    },
  ];

  return (
    <>

      <ClimateSmartAgricultureModule />


      {/* <div className="self-stretch bg-text_and_main-white">
            <div className="relative h-[55.50rem]">
              <div className="absolute left-0 right-0 top-[0.00rem] m-auto h-[50.00rem] flex-1 bg-[url(/public/images/img_heroClimateChange.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto">
                <div className="mb-[2.50rem] flex flex-col items-center gap-[9.13rem] lg:gap-[6.81rem] md:gap-[6.81rem] sm:gap-[4.56rem]">
                  <Header />
                  <div className="container-xs flex flex-col items-center gap-[6.88rem] lg:gap-[6.88rem] lg:p-[1.25rem] md:gap-[5.13rem] md:p-[1.25rem] sm:gap-[3.44rem]">
                    <div className="flex flex-col items-start self-stretch">
                      <Text
                        size="text9xl"
                        as="h1"
                        className="w-[50%] leading-[120%] !text-#F9D95B lg:w-full lg:text-[3.00rem] md:w-full"
                      >
                        AGRICULTURE
                      </Text>
                      <Text
                        size="text5xl"
                        as="h1"
                        className="mt-[0.50rem] w-[30%] leading-[130%] !text-white-a700 lg:w-full md:w-full"
                      >
                        Making farming more sustainable and productive
                      </Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}


      {/* <div className="flex flex-col items-center justify-center gap-[3.75rem] self-stretch bg-gradient1  py-[3.88rem]  lg:py-[2.00rem] md:py-[1.25rem] sm:gap-[1.88rem] sm:py-[1.00rem]">
        <div className="mt-[1.00rem] flex w-[90%] flex-col items-center lg:w-full lg:p-[1.25rem] md:w-full md:p-[1.25rem]">
          <div className="container-xs flex flex-col items-center gap-[0.50rem] px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
            <Text as="h2" className="!text-white-a700 lg:text-[3.00rem]">
              Our work expanding the use of climate-smart practices
            </Text>
          </div>
        </div>
        <div className="container-xs flex flex-col items-center gap-[3.63rem] px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:gap-[1.81rem] sm:px-[1.00rem]">
          <div className="grid w-[90%] grid-cols-3 justify-center gap-[1.50rem] gap-y-[2.50rem] lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
            <Suspense fallback={<div>Loading feed...</div>}>
              {environmentalProjectsGrid.map((d, index) => (
                <NaturalSolutionsProfile {...d} key={"naturalGrid" + index} />
              ))}
            </Suspense>
          </div>
          <div className="flex w-[25%] flex-col items-center gap-[0.88rem] lg:w-full md:w-full">
            <Text size="text3xl" as="p" className="!text-white-a700">
              Help Maximize The Impact!
            </Text>
            <Button
              color="amber_300"
              size="lg"
              rightIcon={
                <Img
                  src="../../images/img_arrow_blue_gray_900_02.svg"
                  alt="Arrow"
                  className="h-[0.13rem] h-[2.38rem] w-[0.13rem] w-[2.50rem]"
                />
              }
              className="gap-[0.50rem] self-stretch rounded-[30px] font-semibold"
            >
              Donate
            </Button>
          </div>
        </div>
      </div> */}


      {/* <div className="mt-[6.13rem] self-stretch px-[0.63rem] py-[2.63rem] md:py-[1.25rem] sm:py-[1.00rem]">
        <div className="flex flex-col items-center bg-white-a700 py-[1.38rem] sm:py-[1.00rem]">
          <div className="container-xs flex flex-col gap-[4.63rem] lg:gap-[4.63rem] lg:p-[1.25rem] md:gap-[3.44rem] md:p-[1.25rem] sm:gap-[2.31rem]">
            <div className="flex flex-col items-center gap-[0.38rem] px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
              <Text as="h1" className="!text-color_black-900 lg:text-[3.00rem]">
                updates
              </Text>
              <Text size="text3xl" as="p" className="!text-gray-800_01">
                Read the latest articles, blogs and press releases on climate smart agriculture.
              </Text>
            </div>
            <div className="mx-[8.50rem] flex gap-[2.50rem] md:mx-0 md:flex-col">
              <FeatureCardList />
              <FeatureCardList
                cultivatingText="Revisiting the first OpenET Applications Conference: how satellite-based data is transforming water, farm, and forest management "
                cultivatingDuration="Blog post, 1 month ago"
                timeZoneText="farmers and scientists dig into the climate power of soil"
                timeZoneDuration="Article, 1 month ago"
                priceText="FDA paves the way for reducing methane emissions from livestock"
                priceDuration="Press release, 1 month ago"
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="self-stretch bg-gray-100_01 px-[0.63rem] py-[2.63rem] md:py-[1.25rem] sm:py-[1.00rem]">
        <div className="flex flex-col items-center py-[1.38rem] sm:py-[1.00rem]">
          <div className="container-xs flex flex-col gap-[5.00rem] lg:gap-[5.00rem] lg:p-[1.25rem] md:gap-[3.75rem] md:p-[1.25rem] sm:gap-[2.50rem]">
            <div className="flex flex-col items-center gap-[0.50rem] px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
              <Text as="h1" className="!text-color_black-900 lg:text-[3.00rem]">
                Agriculture resources
              </Text>
              <Text
                size="textxl"
                as="p"
                className="w-[52%] text-center leading-[140%] !text-gray-800_01 lg:w-full md:w-full"
              >
                Dig deeper into our work with these resources for researchers, policymakers, journalists and
                communities.
              </Text>
            </div>
            <div className="grid grid-cols-3 justify-center gap-[2.50rem] gap-y-[2.50rem] px-[3.50rem] lg:grid-cols-3 md:grid-cols-2 md:px-[1.25rem] sm:grid-cols-1 sm:px-[1.00rem]">
              <Suspense fallback={<div>Loading feed...</div>}>
                {blogHighlightsGrid.map((d, index) => (
                  <BlogSection {...d} key={"blogGrid" + index} />
                ))}
              </Suspense>
            </div>
          </div>
        </div>
      </div> */}

{/* {Our agriculture experts} */}
      {/* <div className="self-stretch bg-white-a700 px-[3.50rem] py-[5.00rem] lg:py-[2.00rem] md:p-[1.25rem] sm:p-[1.00rem]">
        <div className="flex flex-col items-center gap-[3.75rem] sm:gap-[1.88rem]">
          <div className="flex flex-col items-center gap-[2.50rem] self-stretch">
            <div className="container-xs flex flex-col items-center px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
              <div className="flex w-[76%] flex-col items-center gap-[1.25rem] px-[3.50rem] lg:w-full md:w-full md:px-[1.25rem] sm:px-[1.00rem]">
                <Text as="h1" className="lg:text-[3.00rem]">
                  Our agriculture experts
                </Text>
                <Text size="text3xl" as="p" className="self-stretch text-center leading-[140%] !text-gray-800_01">
                  We bring wide-ranging perspectives and skills to our agriculture work. Meet some of the people who
                  make it happen.
                </Text>
              </div>
            </div>
            <div className="flex flex-col items-center gap-[3.75rem] self-stretch sm:gap-[1.88rem]">
              <div className="grid grid-cols-3 justify-center gap-[1.88rem] gap-y-[3.75rem] self-stretch px-[19.38rem] lg:grid-cols-3 lg:px-[2.00rem] md:grid-cols-2 md:px-[1.25rem] sm:grid-cols-1 sm:px-[1.00rem]">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {teamMembersGrid.map((d, index) => (
                    <UserProfile10 {...d} key={"projectsGrid1" + index} />
                  ))}
                </Suspense>
              </div>
              <div className="container-xs flex flex-col items-center px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
                <div className="flex w-[28%] flex-col items-center rounded-[20px] bg-white-a700 px-[2.00rem] py-[2.50rem] shadow-lg lg:w-full md:w-full sm:p-[1.00rem]">
                  <div className="flex flex-col items-center gap-[1.75rem] self-stretch">
                    <div className="flex flex-col items-center rounded-[20px] bg-amber-300_66 p-[1.25rem]">
                      <Img src="../../images/img_arrow_back_2.svg" alt="Back Arrow" className="h-[2.50rem] w-[2.50rem]" />
                    </div>
                    <div className="mb-[1.88rem] flex flex-col items-center gap-[0.38rem] self-stretch">
                      <Heading size="headingmd" as="h2" className="uppercase tracking-[0.00rem]">
                        MEDIA CONTACT
                      </Heading>
                      <Text size="text_2" as="p" className="text-center leading-[150%]">
                        <>
                          Hilary Kirwan
                          <br />
                          (202) 572-3277 (office)
                        </>
                      </Text>
                    </div>
                  </div>
                  <Button
                    color="gray_100_01"
                    shape="round"
                    rightIcon={
                      <Img
                        src="../../images/img_arrowleft_gray_600.svg"
                        alt="Arrow Left"
                        className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
                      />
                    }
                    className="min-w-[7.00rem] gap-[0.63rem] !rounded-[16px]"
                  >
                    Email
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="container-xs flex flex-col items-center px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
            <div className="flex px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
              <Button
                color="blue_900"
                size="lg"
                rightIcon={
                  <Img
                    src="images/img_arrow.svg"
                    alt="Arrow"
                    className="h-[0.13rem] h-[2.38rem] w-[0.13rem] w-[2.50rem]"
                  />
                }
                className="min-w-[20.13rem] gap-[0.50rem] rounded-[30px] font-semibold"
              >
                See All Experts
              </Button>
            </div>
          </div>
        </div>
      </div> */}

{/* {Maximize your impact} */}
      {/* <div className="flex h-[48.13rem] flex-col items-center self-stretch bg-gray-50 bg-[url(/public/images/img_frame_48096608.png)] bg-cover bg-no-repeat py-[4.38rem] lg:h-auto lg:py-[2.00rem] md:h-auto md:py-[1.25rem] sm:py-[1.00rem]">
        <div className="container-xs mb-[1.88rem] flex flex-col items-center gap-[1.00rem] px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
          <Text as="h2" className="!text-white-a700 lg:text-[3.00rem]">
            Maximize your impact
          </Text>
          <div className="flex w-[58%] flex-col items-center rounded-[30px] bg-white-a700_e5 p-[2.75rem] lg:w-full md:w-full md:p-[1.25rem] sm:p-[1.00rem]">
            <Text size="text6xl" as="h3" className="md:text-[2.00rem] sm:text-[1.63rem]">
              Your gift will have triple the impact
            </Text>
            <Text
              size="text3xl"
              as="p"
              className="mt-[1.13rem] self-stretch text-center leading-[140%] !text-gray-800_01"
            >
              This Earth Day triple your impact to bring bold, innovative solutions to life in the fight against
              climate change.
            </Text>
            <Text size="texts" as="p" className="ml-[12.13rem] mt-[2.00rem] self-start !text-gray-800_01 md:ml-0">
              You give
            </Text>
            <div className="ml-[4.00rem] mr-[3.88rem] flex w-[94%] justify-center gap-[1.38rem] lg:w-full md:mx-0 md:w-full md:flex-col">
              <Button color="white_A700_e5" size="md" className="flex-1 rounded-[30px] font-medium md:self-stretch  hover:bg-blue-900">
                $10
              </Button>
              <Button color="white_A700_e5" size="md" className="flex-1 rounded-[30px] font-medium md:self-stretch hover:bg-blue-900">
                $15
              </Button>
              <Button color="white_A700_e5" size="md" className="flex-1 rounded-[30px] font-medium md:self-stretch hover:bg-blue-900">
                $25
              </Button>
              <Button color="white_A700_e5" size="md" className="flex-1 rounded-[30px] font-medium md:self-stretch hover:bg-blue-900">
                $50
              </Button>
              <Button color="white_A700_e5" size="md" className="min-w-[7.38rem] rounded-[30px] font-medium hover:bg-blue-900">
                Other
              </Button>
            </div>
            <Text size="texts" as="p" className="ml-[11.13rem] mt-[0.25rem] self-start !text-gray-800_01 md:ml-0">
              EDF Gets $45
            </Text>
            <div className="mb-[0.38rem] ml-[5.50rem] mr-[4.88rem] mt-[2.13rem] flex w-[94%] justify-center gap-[2.13rem] lg:w-full md:mx-0 md:w-full sm:flex-col">
              <div className="flex items-center gap-[0.50rem] p-[0.63rem]">
                <Heading as="h2" className="!text-gray-800">
                  Donate Once

                </Heading>
                <Img src="../../images/img_arrow_gray_800.svg" alt="Arrow Image" className="h-[2.38rem]" />
              </div>
              <div className="flex flex-1 items-center justify-center gap-[0.50rem] rounded-[30px] bg-blue-900 p-[0.63rem] sm:self-stretch">
                <Heading size="headings" as="h3" className="!text-text_and_main-white">
                  Donate Monthly
                </Heading>
                <Img src="../../images/img_arrow.svg" alt="Arrow Image" className="h-[2.38rem]" />
              </div>
            </div>
          </div>
        </div>
      </div> */}

{/* {Another easy way to help} */}
      {/* <div className="flex flex-col items-center gap-[3.75rem] self-stretch bg-gray-100_01 py-[3.75rem] md:py-[1.25rem] sm:gap-[1.88rem] sm:py-[1.00rem]">
        <div className="flex w-[90%] justify-center lg:w-full lg:p-[1.25rem] md:w-full md:p-[1.25rem]">
          <div className="container-xs flex justify-center px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
            <Text as="h2" className="lg:text-[3.00rem]">
              Another easy way to help
            </Text>
          </div>
        </div>
        <div className="container-xs mb-[6.00rem] flex flex-col items-center px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
          <div className="flex w-[90%] gap-[1.50rem] px-[0.25rem] md:w-full md:flex-col">
            <Suspense fallback={<div>Loading feed...</div>}>
              {allyJoinList.map((d, index) => (
                <UserProfile {...d} key={"cardsRow1" + index} />
              ))}
            </Suspense>
          </div>
        </div>
      </div> */}


      <Footer />
    </>
  )
}

