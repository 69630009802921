import React from "react";

const sizes = {
  text_2: "text-[1.13rem] font-normal not-italic lg:text-[0.94rem]",
  textxs: "text-[0.88rem] font-normal not-italic",
  texts: "text-[1.00rem] font-normal not-italic lg:text-[0.81rem]",
  textmd: "text-[1.13rem] font-normal not-italic lg:text-[0.94rem]",
  textxl: "text-[1.31rem] font-normal not-italic lg:text-[1.06rem]",
  text2xl: "text-[1.38rem] font-normal not-italic lg:text-[1.13rem]",
  text3xl: "text-[1.50rem] font-normal not-italic lg:text-[1.25rem] md:text-[1.38rem]",
  text4xl: "text-[2.50rem] font-normal not-italic lg:text-[2.13rem] md:text-[2.38rem] sm:text-[2.25rem]",
  text5xl: "text-[2.75rem] font-normal lg:text-[2.31rem] md:text-[2.50rem] sm:text-[2.13rem]",
  text6xl: "text-[3.25rem] font-normal not-italic lg:text-[2.75rem] md:text-[2.75rem] sm:text-[2.38rem]",
  text7xl: "text-[4.00rem] font-normal lg:text-[4.00rem] md:text-[3.00rem]",
  text8xl: "text-[4.31rem] font-normal lg:text-[4.31rem] md:text-[3.00rem]",
  text9xl: "text-[5.00rem] font-normal lg:text-[5.00rem] md:text-[3.00rem]",
};

const Text = ({ children, className = "", as, size = "text7xl", ...restProps }) => {
  const Component = as || "p";

  return (
    <Component className={`text-blue_gray-900_02 font-bebasneue ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
