import React, { Suspense, useEffect, useState } from 'react';
import { Button, Img, Text, Heading, TextArea, Input } from "../../components/index";
import Header from 'components/Header';
import { Helmet } from "react-helmet";
import agricultureLogo from '../../imageData/unsplash_91hGAhPmKrY.png';
import { useNavigate } from "react-router-dom";


export default function ClimateSmartAgricultureModule() {
    const apiUrl = process.env.REACT_APP_LOCAL_SERVER;
    const navigate = useNavigate();
    const [playlistKey, setPlaylistKey] = useState(null);
    const [issueData, setIssueData] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    console.log(playlistKey, "img");


    const fetchKey = async () => {
        try {
            const response = await fetch(`${apiUrl}/page/page/8?siteId=822`);
            // const response = await fetch(`${apiUrl}/page/8?siteId=785`);
            const jsonResponse = await response.json();
            console.log(jsonResponse, "first");
            setPlaylistKey(jsonResponse?.data?.playlist?.[0]?.key || null);
            console.log(jsonResponse.data.playlist, "key")

        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchKey();
    }, [apiUrl]);

    const getDataFromKeys = async () => {
        if (!playlistKey) return;
        try {
            const mresponse = await fetch(`${apiUrl}/playlist/playlist/${playlistKey}?siteId=822`);
            // const mresponse = await fetch(`${apiUrl}/playlist/${playlistKey}?siteId=785`);
            const mresponseContent = await mresponse.json();
            console.log(mresponseContent?.data, "data")
            setIssueData(mresponseContent?.data?.content || []);
            

        } catch (err) {
            setError(err);
        }            


    };

    useEffect(() => {
        if (playlistKey) {
            getDataFromKeys();
        }
    }, [playlistKey]);



    // const getDataForIdPresident = async () => {

    //     try {
    //         const mresponse = await fetch(`${apiUrl}/content/content-block/66b37d18ff504887e69939a7?siteId=785`);
    //         const mresponseContent = await mresponse.json();
    //         console.log(mresponseContent.data, "ID")
    //         setIdProfession(mresponseContent.data || []);

    //     } catch (err) {
    //         setError(err);
    //     }

    // };

    // useEffect(() => {
    //     getDataForIdPresident();
    // }, []);


    // const getDataForIdExecutive = async () => {

    //     try {
    //         const mresponse = await fetch(`${apiUrl}/content/content-block/66b37d6bff504887e69939a9?siteId=785`);
    //         const mresponseContent = await mresponse.json();
    //         console.log(mresponseContent.data, "ID")
    //         setIdExecutive(mresponseContent.data || []);

    //     } catch (err) {
    //         setError(err);
    //     }

    // };

    // useEffect(() => {
    //     getDataForIdExecutive();
    // }, []);




    // if (loading) return <div>Loading...</div>;
    // if (error) return <div>Error: {error.message}</div>;


    const environmentalProjectsGrid = [
        {
            userImage: "../../images/Image Placeholder 1.png",
            headingText: "OVERVIEW",
            descriptionText: "Helping livestock farmers cut methane emissions",
        },
        {
            userImage: "../../images/Image Placeholder 1 (1).png",
            headingText: "OVERVIEW",
            descriptionText: "The race to climate-proof agriculture",
        },
        {
            userImage: "../../images/Image Placeholder 1 (2).png",
            headingText: "INITIATIVE",
            descriptionText: "A farm bill that helps farmers, ranchers and the climate",
        },
        {
            userImage: "../../images/Image Placeholder 1 (3).png",
            headingText: "WEBSITE",
            descriptionText: "Harnessing the power of farm loans and crop insurance",
        },
        {
            userImage: "../../images/Image Placeholder 1 (4).png",
            headingText: "INITIATIVE",
            descriptionText: "Make the invisible loss of nitrogen visible",
        },
        {
            userImage: "../../images/Image Placeholder 1 (5).png",
            headingText: "ARTICLE",
            descriptionText: "Farmers and scientists dig into the climate power of soil",
        },
    ];

    const updatesCSA = [
        {
            content: "Cultivating Climate-Smart Dairy: A supplier engagement guide to manure management technologies",
            contentDay: "Blog post, 19 days ago",
        },
        {
            content: "Revisiting the first OpenET Applications Conference: how satellite-based data is transforming water, farm, and forest management ",
            contentDay: "Blog post, 1 month ago",
        },
        {
            content: "Why food companies must act now to protect public funding for climate-smart agriculture",
            contentDay: "Blog post, 1 month ago",
        },
        {
            content: "farmers and scientists dig into the climate power of soil",
            contentDay: "Article, 1 month ago",
        }, {
            content: "EMIT LESS Act will support U.S. livestock producers in lowering methane",
            contentDay: "Press release, 1 month ago",
        },
        {
            content: "FDA paves the way for reducing methane emissions from livestock",
            contentDay: "Blog post, 19 days agoPress release, 1 month ago",
        }
    ]

    const AgricultureResourcesCards = [
        {
            content: "BLOG",
            contentDay: "Growing Returns: EDF blog featuring discussions of resilient land and water systems",
        },
        {
            content: "OVERVIEW",
            contentDay: "Soil carbon: Can cropland soils be a part of the climate solution?",
        },
        {
            content: "VIDEO",
            contentDay: "Fireside chat: Dairy for nutrition and livelihoods, a perspective from India",
        },
        {
            content: "REPORT",
            contentDay: "Heat stress threatens agricultural workers’ health",
        }, {
            content: "INTERACTIVE TOOL",
            contentDay: "How climate change threatens U.S. crop production",
        },
        {
            content: "BLOG POST",
            contentDay: "Managing climate change risks at agricultural finance institutions",
        }
    ]

    const handleArticlePage = () => {
        // navigate("/our-work-drop-down")

    }


    return (
        <>
            <Helmet>
                <title>Home Environment - Join the Fight Against Climate Change</title>
                <meta
                    name="description"
                    content="Discover how fishing communities are thriving despite climate challenges. Learn about our impactful environmental strategies for a sustainable future. Get involved with our cause for a healthier planet."
                />
            </Helmet>
            <div className="w-full bg-white-a700">
                <div className="flex flex-col items-center">
                    <section className="self-stretch bg-text_and_main-white ">
                        <div className="relative h-[100vh] ">
                            <div className="absolute w-[100%]  m-auto flex-1  bg-cover bg-no-repeat lg:h-auto md:h-auto">
                                <div className="mb-[2.50rem] flex flex-col   sm:gap-[4.56rem]">
                                    <Header />
                                    <div className="relative mt-[-8%] lg:mt-[-10%] flex justify-center bg-gray-100a h-[106vh] lg:h-[106vh] md:mt-[-15%] md:h-[100vh] sm:h-[100vh] sm:mt-[-35%]">
                                        <img src={agricultureLogo} alt="Article Logo" className="w-full" />
                                        {/* <img src={imageUrl} alt="Article Logo" className="w-full" /> */}
                                        <div className='overlay_blue'></div>

                                        <div className="container-xs absolute w-[45%] flex flex-col items-center left-[7rem] top-[15rem] gap-[6.88rem] lg:w-[45%] lg:top-[12rem] lg:left-[6.5rem] lg:p-[1.25rem] md:w-[60%] md:top-[12rem] md:left-[5.0rem] md:gap-[5.13rem] md:p-[1.25rem] sm:gap-[3.44rem]  sm:top-[5rem] sm:left-[1.0rem] article_head">
                                            <div className="flex flex-col w-[80%] lg:w-[80%] md:w-[60%] sm:w-[95%]  items-start self-stretch">
                                                {/* <Text
                                                    size="text3xl"
                                                    as="p"
                                                    className="w-[55%] leading-[130%] !text-gold-g700 lg:w-full md:w-full lg:pb-[1rem]"
                                                >
                                                    Vanessa Glavinskas / 3 minute read
                                                    {articleData?.author?.[0]?.name}
                                                </Text> */}
                                                <Text
                                                    size="text9xl"
                                                    as="h1"
                                                    className="w-[55%] leading-[120%] !text-white-a700 lg:w-full lg:text-[3.00rem] md:w-full article_h1" >
                                                    {/* Fighting climate change costs far less than doing nothing */}
                                                    {/* {articleData?.headline} */}
                                                    <span className='cursor-pointer'>AGRICULTURE </span>
                                                </Text>
                                                <Text
                                                    size="textxl"
                                                    as="p"
                                                    className="mt-[0.50rem] w-[55%] leading-[130%] !text-white-a700 lg:w-full md:w-full lg:pt-[1rem] article_p1"
                                                >
                                                    {/* Published: June 12.2024 */}
                                                    {/* {articleData.publishedAt} */}
                                                    {/* {formatDate(articleData?.publishedAt)} */}
                                                    Making farming more sustainable and productive
                                                </Text>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className=' bg-text_and_main-white'>
                        <div className='container-xs  bg-text_and_main-white w-[80%] m-auto  my-14 flex flex-col gap-8'>
                            <div className=' w-[80%] m-auto sm:w-[90%] sm:text-center flex flex-col gap-8'>
                                <div>
                                    <Text
                                        size='text3xl'
                                        as="p"
                                        className='sm:text-[1rem]'

                                    >
                                        <span style={{ color: "#1232C4" }}>The problem:</span> Globally, agriculture contributes one-quarter of the pollution warming the climate. At the same time, climate impacts like droughts and heat waves are already threatening the livelihoods of more than 1 billion people who depend on agriculture to support their families. Business as usual isn’t working for farmers, ranchers or the planet.
                                    </Text>
                                </div>
                                <div>
                                    <Text
                                        size="text3xl"
                                        as="p"
                                        className='sm:text-[1rem]'
                                    >
                                        <span style={{ color: "#1232C4" }}>What we’re doing about it:</span> We’re expanding the use of climate-smart practices in the U.S., EU, China and India to increase food production for a growing population, rapidly reduce powerful pollutants like methane and nitrous oxide, and make rural communities more resilient.
                                    </Text>
                                </div>
                                {/* <div className='my-0 mx-auto'>
                                <Button
                                    size="textmd"
                                    color="gray_100_01"
                                    shape="round"

                                    className="py-[0.375rem] px-[1.25rem] gap-[0.63rem] !rounded-[25px] md:mb-[1rem] sm:mb-[1rem] md:w-[88%] md:px-[3rem]  md:!rounded-[30px] sm:w-full sm:px-[3rem]  sm:!rounded-[30px] "
                                >
                                    <Text size="textmd" as="p" className="!text-color_black-900 !text-[1.1rem]  ">
                                        {item.button2}
                                        People and Nature
                                    </Text>
                                    <ArrowOutwardIcon />

                                </Button>

                            </div> */}
                            </div>
                        </div>
                    </section>

                    {/* {Our work expanding the use of climate-smart practices} */}
                    {/* <section>
                        <div className="flex flex-col items-center justify-center  self-stretch bg-gradient1 py-[3.88rem] lg:py-[2.00rem] md:py-[1.25rem] md:gap-[1rem] sm:gap-[0rem] sm:py-[0rem]">
                            <div className="mt-[1.00rem] flex w-[90%] flex-col items-center lg:w-full lg:p-[1.25rem] md:w-full ">
                                <div className="container-xs flex flex-col items-center gap-[0.50rem] px-[3.50rem] sm:w-full sm:px-[1.00rem]">
                                    <Text as="h2" className="!text-white-a700 text-center lg:text-[3.00rem] sm:lg:text-[2.0rem]">
                                        {subjectContent.name}
                                        Our work expanding the use of climate-smart practices
                                    </Text>

                                </div>
                            </div>



                            <div className="container-xs flex flex-col items-center gap-[3.63rem] px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:gap-[1.81rem] sm:px-[1.00rem]">
                                <div className="grid w-[70%] grid-cols-3 justify-center gap-[1.50rem] gap-y-[2.50rem] lg:grid-cols- md:grid-cols-2 sm:grid-cols-1">

                                    {environmentalProjectsGrid.map((item, index) => (
                                        <div key={index} className="flex flex-col w-full lg:gap-[1.0rem] gap-[1.13rem] bg-white-a700_e5 shadow-md rounded-[16px] overflow-hidden relative" >
                                            <div className="h-[10.00rem] w-full rounded-tl-[16px] rounded-tr-[16px] object-cover"> <Img className="h-[10.00rem] w-full rounded-tl-[16px] rounded-tr-[16px] object-cover"
                                                src={item.userImage}
                                             src={item.images[0]?.imageUrl}

                                            /> </div>
                                            <div className='hidden lg:hidden md:hidden sm:block absolute bottom-[0] left-[0] text-white-a700 p-[0.7rem] smiwa_bg' onClick={() => handleArticlePage(item)}>
                                                {item.headingText}
                                                {item.name}

                                            </div>
                                            <div className=" sm:hidden mb-[1.50rem] flex flex-col items-start gap-[0.25rem] self-stretch px-[1.50rem] sm:px-[1.25rem]" onClick={() => handleArticlePage(item)} >
                                                <Heading size="headingmd" as="h5" className="tracking-[0.00rem] lg:text-[0.95rem]"> {item.name}</Heading>
                                                <Text size="text_2" as="p" className="w-full leading-[150%] lg:text-[0.85rem]">
                                                    {item.descriptionText}
                                                    {item.excerpt}
                                                </Text>
                                            </div>
                                        </div>
                                    ))}


                                </div>
                                <div className="flex w-[25%] flex-col items-center gap-[0.88rem] lg:w-full md:w-full">
                                    <Text size="text3xl" as="p" className="!text-white-a700">
                                        Help Maximize The Impact!
                                    </Text>
                                    <Button
                                        color="amber_300"
                                        size="lg"
                                        rightIcon={
                                            <Img
                                                src="../../images/img_arrow_blue_gray_900_02.svg"
                                                alt="Arrow"
                                                className="h-[0.13rem] h-[2.38rem] w-[0.13rem] w-[2.50rem]"
                                            />
                                        }
                                        className="gap-[0.50rem] self-stretch rounded-[30px] font-semibold"
                                    >
                                        Donate
                                    </Button>
                                </div>



                            </div>


                        </div >

                    </section> */}


                    <section>
                        <div className="   self-stretch bg-text_and_main-white px-[5.13rem] py-[1.25rem] lg:px-[2.00rem] ">
                            <div className="flex flex-col items-center self-stretch py-[1.75rem] sm:py-[1.00rem]">
                                <div className="container-xs mt-[2.00rem] flex flex-col items-center gap-[0.50rem] px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]" >
                                    <Text as="h2" className="lg:text-[3.00rem] text-center">
                                        {/* {subjectContent.name} */}
                                        UPDATES
                                    </Text>
                                    <Text size="text3xl" as="p" className="self-stretch text-center leading-[140%] !text-gray-800_01 lg:px-[3.30rem] md:px-[0rem] sm:px-[0rem]">
                                        {/* {subjectContent.headline} */}
                                        Read the latest articles, blogs and press releases on climate smart agriculture.
                                    </Text>
                                </div>
                            </div>

                            <div className="container-xs z-10 flex flex-col items-center mb-[2rem] px-[3.50rem] lg:p-[1.25rem]  md:px-[1.25rem] sm:px-[1.00rem]">
                                <div className="grid w-[90%] grid-cols-2 justify-center gap-[2.00rem] gap-y-[1.88rem] lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
                                    {updatesCSA?.map((item, index) => (
                                        <div key={index} className="flex items-center w-full px-[3.00rem] py-[2.75rem] md:py-[1.25rem] sm:p-[1.00rem] bg-white-a700 shadow-lg rounded-[20px]" >
                                            <div className="flex w-full flex-col items-center gap-[1.75rem]">
                                                <div className="flex flex-col items-center gap-[0.88rem] self-stretch">
                                                    <Text size="textmd" as="p" className="uppercase tracking-[0.00rem] " style={{ color: " #1232C4" }}>
                                                        {item?.content}
                                                    </Text>
                                                    <Text size="text_2" as="p" className="self-stretch leading-[150%] lg:text-[0.84rem]">
                                                        {item?.contentDay}
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* { AGRICULTURE RESOURCES} */}
                    {/* <section>
                        <div className="   self-stretch bg-gray-100_01 px-[5.13rem] py-[1.25rem] lg:px-[2.00rem] ">
                            <div className="flex flex-col items-center self-stretch py-[1.75rem] sm:py-[1.00rem]">
                                <div className="container-xs mt-[2.00rem] flex flex-col items-center gap-[0.50rem] px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]" >
                                    <Text as="h2" className="lg:text-[3.00rem] text-center">
                                        {subjectContent.name}
                                        AGRICULTURE RESOURCES
                                    </Text>
                                    <Text size="text3xl" as="p" className="self-stretch text-center leading-[140%] !text-gray-800_01 lg:px-[3.30rem] md:px-[0rem] sm:px-[0rem]">
                                        {subjectContent.headline}
                                        Dig deeper into our work with these resources for researchers, policymakers, journalists and communities.
                                    </Text>
                                </div>
                           </div>
                            <div className="container-xs z-10 flex flex-col items-center mb-[2rem] px-[3.50rem] lg:p-[1.25rem]  md:px-[1.25rem] sm:px-[1.00rem]">
                                <div className="grid w-[90%] grid-cols-3 justify-center gap-[2.00rem] gap-y-[1.88rem] lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
                                    {AgricultureResourcesCards.map((item, index) => (
                                        <div key={index} className="flex items-center w-full px-[3.00rem] py-[2.75rem] md:py-[1.25rem] sm:p-[1.00rem] bg-white-a700 shadow-lg rounded-[20px]" >
                                            <div className="flex w-full flex-col items-center gap-[1.75rem]">
                                                <div className="flex flex-col items-center gap-[0.88rem] self-stretch">
                                                    <Text size="textmd" as="p" className="uppercase tracking-[0.00rem] " style={{ color: " #1232C4" }}>
                                                        {item.content}
                                                    </Text>
                                                    <Text size="text_2" as="p" className="self-stretch leading-[150%] lg:text-[0.84rem]">
                                                        {item.contentDay}
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    )}
                                </div>
                            </div>
                        </div>
                    </section> */}
                </div>
            </div>

        </>

    )
}     