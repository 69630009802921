import { Button, Img, Text, Heading } from "./..";
import React from "react";

export default function UserProfileCard({
  userIcon = "images/img_grid.svg",
  userTitle = "Economics",
  userContactInfo = (
    <>
      Sommer Yesenofski
      <br />
      (949) 257-8768 (cell)
    </>
  ),
  email = "Email",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center w-full px-[2.00rem] py-[2.50rem] sm:p-[1.00rem] bg-white-a700 shadow-lg rounded-[20px]`}
    >
      <div className="flex flex-col items-center gap-[1.75rem] self-stretch">
        <div className="flex flex-col items-center rounded-[20px] bg-amber-300_66 p-[1.25rem]">
          <Img src={userIcon} alt="Economics Image" className="h-[2.50rem] w-[2.50rem]" />
        </div>
        <div className="mb-[1.88rem] flex flex-col items-center gap-[0.38rem] self-stretch">
          <Heading size="headingmd" as="h5" className="uppercase tracking-[0.00rem]">
            {userTitle}
          </Heading>
          <Text size="text_2" as="p" className="text-center leading-[150%]">
            {userContactInfo}
          </Text>
        </div>
      </div>
      <Button
        color="gray_100_01"
        shape="round"
        rightIcon={
          <Img
            src="images/img_arrowleft_gray_600.svg"
            alt="Arrow Left"
            className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
          />
        }
        className="min-w-[7.00rem] gap-[0.63rem] !rounded-[16px]"
      >
        {email}
      </Button>
    </div>
  );
}
