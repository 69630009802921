
import React, { useEffect, useState } from "react";
import '../../styles/article.css';
import ArticlePageSliderOne from "./module-1ArticleP";




export default function ArticlePage() {
    // const apiUrl = process.env.REACT_APP_LOCAL_SERVER;    
    // const [playlistKey, setPlaylistKey] = useState(null);
    // const [articleData, setArticleData] = useState([])
    // const [sliderData, setSliderData] = useState([])
    // const [dynamicContent, setDynamicContent] = useState([])
    // const [idNumber, setIdNumber] = useState("")
    // const [loading, setLoading] = useState(true);
    // const [error, setError] = useState(null);
    // console.log(playlistKey, "key");
    // console.log(idNumber, "idArticle");
    // console.log(articleData, "articledata");
    // console.log(dynamicContent, "dynamicContent");



    // const fetchKey_6 = async () => {
    //     try {
    //         const response = await fetch(`${apiUrl}/page/page/6?siteId=822`);
    //         const jsonResponse = await response.json();
    //         console.log(jsonResponse.data, "6-one");
    //         setPlaylistKey(jsonResponse.data.playlist[0].key || null);
    //         console.log(jsonResponse.data.playlist, "key")

    //     } catch (err) {
    //         setError(err);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    // useEffect(() => {
    //     fetchKey_6();


    // }, [apiUrl]);

    // const getDataFromKeys_6 = async () => {
    //     if (!playlistKey) return;

    //     try {
    //         const response = await fetch(`${apiUrl}/playlist/playlist/${playlistKey}?siteId=822`);
    //         const resContent = await response.json();
    //         setSliderData(resContent.data?.content || []);
    //         console.log(resContent, "6_two");
    //         console.log(resContent.data, "6_twoData");
    //         console.log(resContent.data, "img");
    //         console.log(resContent.data, "img");
    //         setDynamicContent(resContent.data?.dynamic.content?.[0], "img");

    //         setIdNumber(resContent.data?.content[0]?.id || null);

    //     } catch (err) {
    //         setError(err);
    //     }
    // };

    // useEffect(() => {
    //     if (playlistKey) {
    //         getDataFromKeys_6();
    //     }
    // }, [playlistKey]);




    // if (loading) return <div>Loading...</div>;
    // if (error) return <div>Error: {error.message}</div>;




    return (
       <>
      


       <ArticlePageSliderOne />
       

       </>
    );
}
