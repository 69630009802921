import { Text, Heading, Img } from "./..";
import React from "react";

export default function ContactCard({
  callIcon = "images/img_call_white_a700.svg",
  callTitle = "Call",
  callDetails = (
    <>
      202-572-3298
      <br />
      <br />
      Monday-Friday,
      <br />
      9am-5pm ET
    </>
  ),
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex justify-center items-center w-[32%] md:w-full px-[2.00rem] py-[3.50rem] md:py-[1.25rem] sm:p-[1.00rem]`}
    >
      <div className="flex w-full flex-col items-center gap-[1.75rem]">
        <div className="flex flex-col items-center rounded-[40px] bg-blue-900 p-[1.25rem]">
          <Img src={callIcon} alt="Call Icon" className="h-[2.50rem] w-[2.50rem]" />
        </div>
        <div className="flex flex-col items-center gap-[0.38rem] self-stretch">
          <Heading size="headingmd" as="h5" className="uppercase tracking-[0.00rem]">
            {callTitle}
          </Heading>
          <Text size="text_2" as="p" className="text-center leading-[150%]">
            {callDetails}
          </Text>
        </div>
      </div>
    </div>
  );
}
