import React, { Suspense, useEffect, useState } from 'react';
import { Button, Img, Text, Heading, TextArea, Input } from "../../components/index";
import MobileCarousel from './MobileCarousel';
import { useNavigate } from "react-router-dom";



export default function HowWeGetResults() {
  const apiUrl = process.env.REACT_APP_LOCAL_SERVER;
  const navigate = useNavigate();
  const [playlistKey, setPlaylistKey] = useState(null);
  const [subjectContent, setSubjectContent] = useState([])
  const [issueData, setIssueData] = useState([])
  const [contentID, setContentID] = useState(null)
  const [contentBlockId, setContentBlockId] = useState("")
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // console.log(playlistKey, 'module4');


  // const width = "100%";
  // const height = "100%";

  const fetchKey = async () => {
    try {
      const response = await fetch(`${apiUrl}/page/page/1?siteId=822`);
      // const response = await fetch(`${apiUrl}/page/1?siteId=785`);
      const jsonResponse = await response.json();
      setPlaylistKey(jsonResponse?.data?.playlist?.[3]?.key || null);
      // console.log(jsonResponse.data?.playlist, "key");


    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKey();
  }, [apiUrl]);

  const getDataFromKeys = async () => {
    if (!playlistKey) return;
    try {
      const mresponse = await fetch(`${apiUrl}/playlist/playlist/${playlistKey}?siteId=822`);
      const mresponseContent = await mresponse.json();
      setIssueData(mresponseContent?.data?.content || []);
      setSubjectContent(mresponseContent?.data || []);
      setContentID(mresponseContent?.data?.content[0]?.id || null);
      console.log(mresponseContent.data, "hwg");




    } catch (err) {
      setError(err);
    }

  };

  useEffect(() => {
    if (playlistKey) {
      getDataFromKeys();
    }
  }, [playlistKey]);

  const getDataForId = async () => {
    if (!contentID) return;

    try {
        const mresponse = await fetch(`${apiUrl}/content/content-block/${contentID}?siteId=822`);         
        const mresponseContent = await mresponse.json();
        console.log(mresponseContent, "ID")
        console.log(mresponseContent.data?.content?.[0]?.id, "ID")
        setContentBlockId(mresponseContent?.data?.content || []);
        // setIssueID(mresponseContent.data?.content?.[0] || null)
        // subjectContent.content.


    } catch (err) {
        console.log('errrr1', err)
        setError(err);
    }
};
useEffect(() => {
    if (contentID) {
        getDataForId();
    }
}, [contentID]);



  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;
//   const handleArticlePage = (contentBlockId) => {
//         console.log(contentBlockId);
//         localStorage.setItem('articlePageApi', contentBlockId.id)
//         navigate(`${contentBlockId.category}/article/${contentBlockId.slug}`)
// }

  return (
    <>
      <div className=" relative  self-stretch bg-gray-100_01 px-[5.13rem] py-[1.25rem] lg:px-[2.00rem] ">
        <Img
          src="images/img_group_1000001631.svg"
          alt="Image Six"
          className="absolute z-10 bottom-[6%] left-0 right-0 m-auto h-[51.25rem] w-[46%] object-contain"
        />
        <div className=" left-0 right-0 top-[1.25rem] m-auto flex flex-1 flex-col items-center ">
          <div className="flex flex-col items-center self-stretch py-[1.75rem] lg:py-[0rem] sm:py-[1.00rem]">

            <div className="container-xs mt-[2.00rem] flex flex-col items-center gap-[0.50rem] px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]" >
              <Text as="h2" className="lg:text-[3.00rem] text-center">{subjectContent?.name}</Text>
              <Text size="text3xl" as="p" className="self-stretch text-center leading-[140%] !text-gray-800_01 lg:px-[3.30rem] md:px-[0rem] sm:px-[0rem]">{subjectContent?.headline} </Text>
            </div>


          </div>
          {/* <div className='hidden lg:hidden md:block sm:block'> */}
          <MobileCarousel issueData={issueData} />
          {/* </div> */}


          {/* <div className="lg:hidden md:block flex items-center justify-center md:overflow-x-auto md:snap-x md:snap-mandatory">
            <div className="flex items-center w-[90%] md:w-[100%] flex-nowrap space-x-[1rem]">
              {issueData.length > 0 &&
                issueData.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-center w-[80%] min-w-[300px] px-[1.00rem] py-[2.75rem] md:py-[1.25rem] sm:p-[1.00rem] bg-white-a700 shadow-lg rounded-[20px] snap-center"
                  >
                    <div className="flex w-full flex-col items-center gap-[1.75rem]">
                      <div className="flex flex-col items-center rounded-[20px] bg-amber-300_4c p-[1.25rem]">
                        <img
                          src={item.images[0].imageUrl}
                          alt="Clock Image"
                          className="h-[2.75rem] w-[2.75rem]"
                        />
                      </div>
                      <div className="flex flex-col items-center gap-[0.38rem] self-stretch">
                        <h5 className="uppercase tracking-[0.00rem] lg:text-[1rem]">
                          {item.name}
                        </h5>
                        <p className="self-stretch text-center leading-[150%] lg:text-[0.84rem]">
                          {item.excerpt}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div> */}

          {/* {mobile reponsive} */}
          {/* <div className="lg:hidden md:block flex items-center justify-center  ">
            <div className="flex items-center w-[90%] md:w-[100%] flex-nowrap space-x-[1rem]">
              {issueData.length > 0 && issueData.map((item, index) => (
                <div key={index} className="flex flex-col items-center w-[80%] min-w-[300px] px-[1.00rem] py-[2.75rem] md:py-[1.25rem] sm:p-[1.00rem] bg-white-a700 shadow-lg rounded-[20px]">
                  <div className="flex w-full flex-col items-center gap-[1.75rem]">
                    <div className="flex flex-col items-center rounded-[20px] bg-amber-300_4c p-[1.25rem]">
                      <Img src={item.images[0].imageUrl} alt="Clock Image" className="h-[2.75rem] w-[2.75rem]" />
                    </div>
                    <div className="flex flex-col items-center gap-[0.38rem] self-stretch">
                      <Heading size="headingmd" as="h5" className="uppercase tracking-[0.00rem] lg:text-[1rem]">
                        {item.name}
                      </Heading>
                      <Text size="text_2" as="p" className="self-stretch text-center leading-[150%] lg:text-[0.84rem]">
                        {item.excerpt}
                      </Text>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div> */}


          {/* {Desktop view} */}
          <div className=" md:hidden container-xs z-10 flex flex-col items-center mb-[2rem] px-[3.50rem] lg:p-[1.25rem]  md:px-[1.25rem] sm:px-[1.00rem]">
            <div className="grid w-[90%] grid-cols-3 justify-center gap-[2.00rem] gap-y-[1.88rem] lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1">
              {/* <Suspense fallback={<div>Loading feed...</div>}>
                    {solutionFeaturesGrid.map((d, index) => (
                      <InfoCard {...d} key={"featuresGrid" + index} />
                    ))}
                  </Suspense> */}

              {/* <svg > width: 24.59px;height: 34.72px;top: 25.76px;left: 31.78px;gap: 0px;opacity: 0px; background: #1C1B1F; background: #F9D95B4D;
                  </svg> */}
              {issueData?.map((item, index) => (
                <div key={index} className="flex items-center w-full px-[1.00rem] py-[2.75rem] md:py-[1.25rem] sm:p-[1.00rem] bg-white-a700 shadow-lg rounded-[20px]" >
                  <div className="flex w-full flex-col items-center gap-[1.75rem]" >
                    <div className="flex flex-col items-center rounded-[20px] bg-amber-300_4c p-[1.25rem] ">
                      <Img src={item?.images?.[0].imageUrl} alt="Clock Image" className=" h-[2.75rem] w-[2.75rem] "  />
                    </div>
                    <div className="flex flex-col items-center gap-[0.38rem] self-stretch">
                      <Heading size="headingmd" as="h5" className=" text-center uppercase tracking-[0.00rem]  sm:text-[1.3rem]">
                        {item?.name}
                      </Heading>
                      <Text size="text_2" as="p" className=" self-stretch text-center leading-[150%] sm:text-[1rem]">
                        {item?.excerpt}
                      </Text>
                    </div>

                  </div>
                </div>
              )
              )}

            </div>
          </div>
        </div>

      </div>


    </>

  )
}