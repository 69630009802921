import Footer from "components/Footer";
import Header from "components/Header";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { jobData } from "./carrerData";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const CareerDetails = () => {
  const location = useLocation();
  const [jobDetails, setJobDetails] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    let jobID = location.pathname.split("/")[2];
    let data = jobData.find((job) => job.positionId == jobID);
    setJobDetails(data);
    console.log(data, "LAlal", jobID);
  }, []);
  return (
    <div className="relative">
      <Helmet>
        <title>{jobDetails?.position?.title}</title>
        <meta name="description" content="Join Us EDIF" />
        <meta
          meta="keywords"
          content={location.pathname.split("/")[2].split("_").join(",")}
        />
      </Helmet>
      <Header />
      <div
        onClick={() => navigate("/careers")}
        className="cursor-pointer hover:underline absolute sm:top-[100px] top-30 left-5 flex items-center gap-x-2 text-[#424241] sm:text-[8px] text-[16px] font-Poppins"
      >
        <KeyboardBackspaceIcon style={{ fontSize: "100%" }} />{" "}
        <span>Back to Search</span>
      </div>
      <div className="sm:w-full sm:px-4 w-[1200px] flex flex-col items-center mx-auto min-h-[80vh] my-20 ">
        <div className="flex flex-col items-center">
          <h1 className="text-[#2D2D2D] sm:text-xl text-6xl">
            {jobDetails?.position?.title}
          </h1>
          {/* <p className="text-xl font-extralight text-[#424241] font-Poppins">
            This is a hybrid role out of EDF's New York office or remote in the
            U.S.
          </p> */}
        </div>
        <div className="sm:w-full w-[900px] shadow shadow-2xl border-[white]/20 rounded-2xl py-10 px-10 flex flex-col mt-10 text-[#2D2D2D]">
          <p className="sm:ml-0 ml-14 mb-4">
            REQ 0000
            {jobDetails?.id}
          </p>
          <div className="flex flex-wrap gap-x-24 gap-y-8 justify-center">
            <div className="w-[300px]">
              <h3 className="text-xl font-[500]">pay range</h3>
              <p>{jobDetails?.consultancyFee || "Not Disclosed"}</p>
            </div>
            <div className="w-[300px]">
              <h3 className="text-xl font-[500]">Locations</h3>
              <p>India</p>
            </div>
            <div className="w-[300px]">
              <h3 className="text-xl font-[500]">Type</h3>
              <p>{"Fixed Term Contract (Fixed Term)"}</p>
            </div>
            <div className="w-[300px]">
              <h3 className="text-xl font-[500]">Posted</h3>
              <p>Nov 01,2024</p>
            </div>
          </div>
          <button className="bg-[#1232C4] w-fit mx-auto px-2 py-1 font-semibold text-sm rounded-xl mt-4 text-[#FAFAFA] font-Poppina">
            Send your CV to info@edif.org.in
          </button>
        </div>
        <div className="mt-10">
          <h2 className="sm:text-lg text-xl text-[#1232C4]">
            Overall Function
          </h2>
          <p className="text-[#424241] ltext-md mt-1 leading-5">
            {jobDetails?.position?.overall_function}
            {jobDetails?.position?.overall_function_points && (
              <ul className="list-disc pl-4">
                {jobDetails?.position?.overall_function_points?.map((points) => (
                  <li className="mb-4 leading-5">{points}</li>
                ))}
              </ul>
            )}
          </p>
          <h2 className="text-xl text-[#1232C4] my-3">Key Responsibilities</h2>
          <p className="text-[#424241] text-md">
            <ul className="list-disc pl-0">
              {jobDetails?.keyResponsibilities?.map((responsibility) =>
                jobDetails?.position?.title?.startsWith("Senior") ? (
                  <>
                    <span className="inline-block mb-2 underline">
                      {responsibility?.category}
                    </span>
                    {responsibility?.responsibilities?.map((data) => (
                      <li className="mb-4 leading-5 ml-4">{data}</li>
                    ))}
                  </>
                ) : (
                  <>
                    <li className="mb-4 leading-5 ml-4">{responsibility}</li>
                  </>
                )
              )}
            </ul>
          </p>
          <h2 className="text-xl text-[#1232C4] my-4">Qualifications</h2>
          <p className="text-[#424241] text-md mt-1">
            <ul className="list-disc pl-4">
              {jobDetails?.qualifications?.map((qualification) => (
                <li className="mb-4 leading-5">{qualification}</li>
              ))}
            </ul>
          </p>
          {jobDetails?.travel && (
            <>
              <h2 className="text-xl text-[#1232C4] my-4 inline pb-0">
                Travel expectations:
              </h2>
              <p className="text-[#424241] text-md mt-1 inline ml-2">
                {jobDetails?.travel}
              </p>
            </>
          )}
          {jobDetails?.tenure && (
            <div className="mt-2">
              <h2 className="text-xl text-[#1232C4] my-4 inline">Tenure:</h2>
              <p className="text-[#424241] text-md mt-1 inline ml-2">
                {jobDetails?.tenure}
              </p>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CareerDetails;
