import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../../styles/apiCustom.css';
import { useNavigate } from "react-router-dom";

const MobileCarousel = ({ issueData }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    initialSlide: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 10,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  // Initialize isTruncated as an array of true values
  const [isTruncated, setIsTruncated] = useState(issueData.map(() => true));
  const navigate = useNavigate();

  const handleArticlePage = (contentBlockId) => {
    localStorage.setItem('articlePageApi', contentBlockId.id);
    navigate(`${contentBlockId.category}/article/${contentBlockId.slug}`);
  };

  const truncateText = (text, maxLength, index) => {
    if (isTruncated[index] && text.length > maxLength) {
      return (
        <>
          {text.substring(0, maxLength)}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              const updatedTruncation = [...isTruncated];
              updatedTruncation[index] = false; // Update the clicked item's state
              setIsTruncated(updatedTruncation);
            }}
            className="pl-1 text-[10px] font-semibold"
            style={{ color: "blue" }}
          >
            See more...
          </a>
        </>
      );
    }
    return text;
  };

  // Reset isTruncated when issueData changes
  useEffect(() => {
    setIsTruncated(issueData.map(() => true));
  }, [issueData]);

  return (
    <div className="hidden lg:hidden md:block z-10 w-full items-center justify-center p[">
      <Slider {...settings} className="w-[90%] md:w-[100%]">
        {issueData.length > 0 &&
          issueData.map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center w-full px-[2.00rem] py-[2.75rem] md:py-[1.25rem] md:w-[95%] bg-white-a700 shadow-lg rounded-[20px] gap-[1.25rem] slider_box"
            >
              <div className="flex w-full flex-col items-center gap-[1.75rem]">
                <div className="flex flex-col items-center gap-[0.38rem] self-stretch">
                  <h5
                    className="uppercase cursor-pointer tracking-[0.00rem] text-center lg:text-[1rem] sm:text-[0.8rem]"
                    onClick={() => handleArticlePage(item)}
                  >
                    {item.name}
                  </h5>
                  <p className="self-stretch cursor-pointer text-center leading-[150%] lg:text-center lg:text-[0.84rem] sm:text-[0.67rem]">
                    {truncateText(item.excerpt, 210, index)}
                  </p>
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default MobileCarousel;
