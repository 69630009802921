import React, { Suspense, useEffect, useState } from 'react';
import '../../styles/apiCustom.css';
import { Button, Img, Text, Heading, TextArea, Input } from "../../components/index";
import Header from 'components/Header';
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Footer from 'components/Footer';






export default function OurWorkDropDown() {
    const apiUrl = process.env.REACT_APP_LOCAL_SERVER;
    const navigate = useNavigate();
    const [dropDownKey, setDropDownKey] = useState(null);
    const [playlistKey, setPlaylistKey] = useState(null);
    const [issueData, setIssueData] = useState([])
    const [playlistData, setPlaylistData] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [pageData, setPageData] = useState({})
    console.log(playlistKey, "img");




    const getLastSegment = (url) => {
        try {
            // Create a new URL object
            const urlObj = new URL(url);
            
            // Get the pathname from the URL
            const pathname = urlObj.pathname;
            
            // Split the pathname into segments and get the last one
            const segments = pathname.split('/').filter(segment => segment);
            
            // Return the last segment
            return segments.length > 0 ? segments[segments.length - 1] : null;
        } catch (e) {
            console.error('Invalid URL:', e);
            return null;
        }
    }
    

    const fetchKey = async () => {
        try {
            const response = await fetch(`${apiUrl}/contents/ourWork/default?siteId=822`);
            // const response = await fetch(`${apiUrl}/page/8?siteId=785`);
            const jsonResponse = await response.json();
            console.log(jsonResponse, "first");
            const currentUrl = window.location.href;
            console.log(currentUrl);            
            const lastSegment = getLastSegment(currentUrl);
            console.log("first111", lastSegment);
            setDropDownKey(jsonResponse?.data?.[4].key || null);
            const selectedArticle = jsonResponse?.data?.find(d=>d.slug === lastSegment)
            console.log('getSelectedArtcle',selectedArticle);
            setPageData(selectedArticle)
            // console.log(jsonResponse.data, "key")

        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchKey();
    }, [apiUrl]);

    const getDataFromKeys = async () => {
        if (!dropDownKey) return;
        try {
            const mresponse = await fetch(`${apiUrl}/content/ourWork/${dropDownKey}?siteId=822`);
            // const mresponse = await fetch(`${apiUrl}/playlist/${playlistKey}?siteId=785`);
            const mresponseContent = await mresponse.json();
            console.log(mresponseContent, "data")
            setIssueData(mresponseContent?.data || []);
            
            setPlaylistKey(mresponseContent?.data?.playlist?.[0].key || null);


        } catch (err) {
            setError(err);
        }

    };

    useEffect(() => {
        if (dropDownKey) {
            getDataFromKeys();
        }
    }, [dropDownKey]);

    const getDataFromPlaylistKeys = async () => {
        if (!playlistKey) return;
    
        try {
          const response = await fetch(`${apiUrl}/playlist/playlist/${playlistKey}?siteId=822`);
          // const response = await fetch(`${apiUrl}/playlist/${playlistKey}?siteId=785`);
          const resContent = await response.json();
          console.log(resContent.data, "test");
    
          setPlaylistData(resContent?.data || null);
        console.log(resContent.data, "sliderImg");
          // setIdArticle(resContent.data?.content[0]?.id || null);
          console.log(resContent.data?.content[0], "id");
    
          // getDataForId(resContent.data?.content[0]?.id)
    
        } catch (err) {
          setError(err);
        }
      };
    
      useEffect(() => {
        if (playlistKey) {
            getDataFromPlaylistKeys();
        }
      }, [playlistKey]);

    // if (loading) return <div>Loading...</div>;
    // if (error) return <div>Error: {error.message}</div>;


   
    const updatesCSA = [
        {
            content: "Cultivating Climate-Smart Dairy: A supplier engagement guide to manure management technologies",
            contentDay: "Blog post, 19 days ago",
        },
        {
            content: "Revisiting the first OpenET Applications Conference: how satellite-based data is transforming water, farm, and forest management ",
            contentDay: "Blog post, 1 month ago",
        },
        {
            content: "Why food companies must act now to protect public funding for climate-smart agriculture",
            contentDay: "Blog post, 1 month ago",
        },
        {
            content: "farmers and scientists dig into the climate power of soil",
            contentDay: "Article, 1 month ago",
        }, {
            content: "EMIT LESS Act will support U.S. livestock producers in lowering methane",
            contentDay: "Press release, 1 month ago",
        },
        {
            content: "FDA paves the way for reducing methane emissions from livestock",
            contentDay: "Blog post, 19 days agoPress release, 1 month ago",
        }
    ]

   

    const handleArticlePage = () => {
        navigate("/our-work-drop-down")

    }

    const htmlContent = `${pageData.pageContent}`
    console.log(htmlContent);

// ....
    const decodedString = `${pageData?.pageContent}`
  .replace(/&lt;/g, '<')  // Decode opening tag
  .replace(/&gt;/g, '>'); // Decode closing tag
  const stringWithoutIframe = decodedString.replace(/<iframe[^>]*>.*?<\/iframe>/g, '');
  

console.log(stringWithoutIframe);
    

// ....


function decodeHtmlEntities(str) {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = str;
    return textarea.value;
  }


//   function decodeHtmlEntities(str) {
//     const textarea = document.createElement('textarea');
//     textarea.innerHTML = str;
//     return textarea.value;
//   }
  
//   let decodedString1 = decodeHtmlEntities(`${pageData.pageContent}`);
  
  // Step 2: Remove the iframe tag or any iframe-related string content
  // Removing encoded iframe tag pattern
//   decodedString1 = decodedString1.replace(/<iframe[^>]*>.*?<\/iframe>/g, '');
  
  // Removing iframe string-like representation (if any remaining)
//   decodedString1 = decodedString1.replace(/&lt;iframe[^&]*&gt;.*?&lt;\/iframe&gt;/g, '');
  
  // Log or use the cleaned string
  console.log(decodedString);
    return (
        <>
            <Helmet>
                <title>Home Environment - Join the Fight Against Climate Change</title>
                <meta
                    name="description"
                    content="Discover how fishing communities are thriving despite climate challenges. Learn about our impactful environmental strategies for a sustainable future. Get involved with our cause for a healthier planet."
                />
            </Helmet>
            {Object.keys(pageData)?.length ?
            <div className="w-full bg-white-a700">
                <div className="flex flex-col items-center">
                    <section className="self-stretch bg-text_and_main-white ">
                        <div className="relative h-[100vh] ">
                            <div className="absolute w-[100%]  m-auto flex-1  bg-cover bg-no-repeat lg:h-auto md:h-auto">
                                <div className="mb-[2.50rem] flex flex-col   sm:gap-[4.56rem]">
                                    <Header />
                                    <div className="relative mt-[-8%] lg:mt-[-10%] flex justify-center bg-gray-100a h-[106vh] lg:h-[106vh] md:mt-[-15%] md:h-[100vh] sm:h-[100vh] sm:mt-[-35%]">
                                        <img src={issueData?.images?.[0]?.imageUrl[0]} alt="Article Logo" className="w-full" />
                                        {/* <img src={imageUrl} alt="Article Logo" className="w-full" /> */}
                                        <div className='overlay_blue'></div>

                                        <div className="container-xs absolute w-[45%] flex flex-col items-center left-[7rem] top-[15rem] gap-[6.88rem] lg:w-[45%] lg:top-[12rem] lg:left-[6.5rem] lg:p-[1.25rem] md:w-[60%] md:top-[12rem] md:left-[5.0rem] md:gap-[5.13rem] md:p-[1.25rem] sm:gap-[3.44rem]  sm:top-[5rem] sm:left-[1.0rem] article_head">
                                            <div className="flex flex-col w-[80%] lg:w-[80%] md:w-[60%] sm:w-[95%]  items-start self-stretch">
                                                {/* <Text
                                                    size="text3xl"
                                                    as="p"
                                                    className="w-[55%] leading-[130%] !text-gold-g700 lg:w-full md:w-full lg:pb-[1rem]"
                                                >
                                                    Vanessa Glavinskas / 3 minute read
                                                    {articleData?.author?.[0]?.name}
                                                </Text> */}
                                                <Text

                                                    size="text9xl"
                                                    as="h1"
                                                    className="w-[55%]  leading-[120%] !text-white-a700 lg:w-full lg:text-[3.00rem] md:w-full article_h1" >
                                                    {/* Fighting climate change costs far less than doing nothing */}
                                                    {/* {articleData?.headline} */}
                                                    {/* Drop */}
                                                    {pageData?.name}
                                                </Text>
                                                <Text
                                                    size="textxl"
                                                    as="p"
                                                    className="mt-[0.50rem] w-[55%] leading-[130%] !text-white-a700 lg:w-full md:w-full lg:pt-[1rem] article_p1"
                                                >
                                                    {/* Published: June 12.2024 */}
                                                    {/* {articleData.publishedAt} */}
                                                    {/* {formatDate(articleData?.publishedAt)} */}
                                                    {/* Making farming more sustainable and productive */}
                                                    {pageData?.alternateHeadline}
                                                </Text>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className=' bg-text_and_main-white'>
                        <div className='container-xs  bg-text_and_main-white w-[80%] m-auto  my-14 flex flex-col gap-8'>
                            <div className=' w-[80%] m-auto flex flex-col gap-8'>
                                <div>
                                    <Text
                                        size='text3xl'
                                        as="p"
                                    >
                                        <span className='flex flex-col gap-[1rem] middle_content' dangerouslySetInnerHTML={{ __html: decodedString }} ></span>
                                        {/* <span style={{ color: "#1232C4" }}>DROP DOWN The problem:</span> Globally, agriculture contributes one-quarter of the pollution warming the climate. At the same time, climate impacts like droughts and heat waves are already threatening the livelihoods of more than 1 billion people who depend on agriculture to support their families. Business as usual isn’t working for farmers, ranchers or the planet. */}
                                    </Text>
                                </div>
                                {/* <div>
                                    <Text
                                        size="text3xl"
                                        as="p"

                                    >
                                        <span style={{ color: "#1232C4" }}>What we’re doing about it:</span> We’re expanding the use of climate-smart practices in the U.S., EU, China and India to increase food production for a growing population, rapidly reduce powerful pollutants like methane and nitrous oxide, and make rural communities more resilient.
                                    </Text>
                                </div> */}
                            </div>
                        </div>
                    </section>

                    <section>

                        <div className="   self-stretch bg-text_and_main-white px-[5.13rem] py-[1.25rem] lg:px-[2.00rem] ">
                            <div className="flex flex-col items-center self-stretch py-[1.75rem] sm:py-[1.00rem]">
                                <div className="container-xs mt-[2.00rem] flex flex-col items-center gap-[0.50rem] px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]" >
                                    <Text as="h2" className="lg:text-[3.00rem] text-center">
                                        {/* {subjectContent.name} */}
                                        {/* DROPDOWN UPDATES */}
                                        {playlistData?.name}
                                    </Text>
                                    <Text size="text3xl" as="p" className="self-stretch text-center leading-[140%] !text-gray-800_01 lg:px-[3.30rem] md:px-[0rem] sm:px-[0rem]">
                                        {/* {subjectContent.headline} */}
                                        {/* Read the latest articles, blogs and press releases on climate smart agriculture. */}
                                        {playlistData?.alternateHeadline}
                                    </Text>
                                </div>
                            </div>

                            <div className="container-xs z-10 flex flex-col items-center mb-[2rem] px-[3.50rem] lg:p-[1.25rem]  md:px-[1.25rem] sm:px-[1.00rem]">
                                <div className="grid w-[90%] grid-cols-2 justify-center gap-[2.00rem] gap-y-[1.88rem] lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-1">
                                    {playlistData?.content?.map((item, index) => (
                                        <div key={index} className="flex items-center w-full px-[3.00rem] py-[2.75rem] md:py-[1.25rem] sm:p-[1.00rem] bg-white-a700 shadow-lg rounded-[20px]" >
                                            <div className="flex w-full flex-col items-center gap-[1.75rem]">
                                                <div className="flex flex-col items-center gap-[0.88rem] self-stretch">
                                                    <Text size="textmd" as="p" className="uppercase tracking-[0.00rem] " style={{ color: " #1232C4" }}>
                                                        {/* {item.content} */}
                                                        {item?.excerpt}
                                                    </Text>
                                                    <Text size="text_2" as="p" className="self-stretch leading-[150%] lg:text-[0.84rem]">
                                                        {/* {item.contentDay} */}
                                                        {item?.publishedAt}
                                                    </Text>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>

                    <div className="mt-[1rem]">
            < Footer />
          </div>

                </div>
            </div> :
            " "
}
        </>

    )
}     