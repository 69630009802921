import { Text, Heading, Img } from "./..";
import React from "react";

export default function InfoCard({
  clockImage = "images/img_clock.svg",
  headingText = "Scientific evidence",
  descriptionText = "Applying evidence to identify the most effective solutions",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex items-center w-full px-[1.00rem] py-[2.75rem] md:py-[1.25rem] sm:p-[1.00rem] bg-white-a700 shadow-lg rounded-[20px]`}
    >
      <div className="flex w-full flex-col items-center gap-[1.75rem]">
        <div className="flex flex-col items-center rounded-[20px] bg-amber-300_4c p-[1.25rem]">
          <Img src={clockImage} alt="Clock Image" className="h-[2.75rem] w-[2.75rem]" />
        </div>
        <div className="flex flex-col items-center gap-[0.38rem] self-stretch">
          <Heading size="headingmd" as="h5" className="uppercase tracking-[0.00rem]">
            {headingText}
          </Heading>
          <Text size="text_2" as="p" className="self-stretch text-center leading-[150%]">
            {descriptionText}
          </Text>
        </div>
      </div>
    </div>
  );
}
