import React, { Suspense, useEffect, useState } from 'react';
import { Button, Img, Text, Heading, TextArea, Input } from "../../components/index";




export default function BoardMembers() {
    const apiUrl = process.env.REACT_APP_LOCAL_SERVER;
    const [playlistKey, setPlaylistKey] = useState(null);
    const [subjectContent, setSubjectContent] = useState([])
    const[anchorKeyData,setAnchorKeyData] =useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    console.log(playlistKey, "img");






    const fetchKey = async () => {
        try {
            const response = await fetch(`${apiUrl}/page/page/3?siteId=822`);
            // const response = await fetch(`${apiUrl}/page/3?siteId=785`);
            const jsonResponse = await response.json();
            setPlaylistKey(jsonResponse?.data?.playlist?.[0]?.key || null);
            // console.log(jsonResponse.data.playlist, "key")

        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchKey();
    }, [apiUrl]);

    const getDataFromKeys = async () => {
        if (!playlistKey) return;
        try {
            const mresponse = await fetch(`${apiUrl}/playlist/playlist/${playlistKey}?siteId=822`);
            // const mresponse = await fetch(`${apiUrl}/playlist/${playlistKey}?siteId=785`);
            const mresponseContent = await mresponse.json();
            console.log(mresponseContent.data, "data")
            setSubjectContent(mresponseContent?.data || []);
            const contentKey = mresponseContent?.data?.content || []
            if (contentKey?.length > 0){
                const promises = contentKey?.map(async (anchorName) => {
                    const resp = await fetch(`${apiUrl}/content/anchor/${anchorName.key}?siteId=822`);
                    const responseData = await resp.json();
                    // return { data: responseData, name: anchorName.name }; // Return combined data
                    return responseData;
                });
                const results = await Promise.all(promises);
                console.log("results.keys",results);
                setAnchorKeyData(results)     
            }

        } catch (err) {
            setError(err);
        }
    };
    useEffect(() => {
        if (playlistKey) {
            getDataFromKeys();
        }
    }, [playlistKey]);

   
    return (
        <>
            <div>
                <div className="flex justify-center self-stretch bg-white-a700 pt-[1.50rem] pb-[6.50rem] lg:py-[2.00rem] md:py-[1.25rem] sm:py-[1.00rem]">
                    <div className="container-xs flex justify-center lg:p-[1.25rem] md:p-[1.25rem] md:mt-[1.75rem] sm:p-[0.4rem]">
                        <div className="flex w-full flex-col gap-[4.13rem] sm:gap-[2.06rem]">
                            <div className="mx-[8.63rem] flex flex-col items-center gap-[1.13rem] px-[1.50rem] md:mx-[8.63rem] md:mx-0 md:text-center md:px-[1.25rem] sm:mx-[0rem] sm:px-[0rem] ">
                                <Text as="h1" className="lg:text-[3.00rem]">
                                    {subjectContent?.name}
                                </Text>
                                <Text size="text3xl" as="p" className="!text-gray-800_01 sm:text-[1.12rem]">
                                    {subjectContent?.headline}
                                </Text>
                            </div>
                            <div className=" flex flex-col  gap-[1.50rem] md:flex-col">
                            {anchorKeyData?.map((item, index) => (
                                    <div key={index} className="sm:flex-col flex w-full items-center gap-[1.50rem] py-[3rem] rounded-[30px] bg-white-a700 px-[1.50rem] shadow-xs md:px-[1.25rem] sm:px-[0rem] sm:py-[1.5rem] sm:items-center">
                                        <div className="w-[18%] sm:w-[50%] "><Img src={item?.data.images?.[0].imageUrl} alt="Member Image" className=" w-full rounded-[150px]" /></div>
                                        <div className=" w-[75%] flex flex-col gap-[0.63rem] self-stretch md:pb-[2rem] sm:w-full sm:self-center">
                                            <div className="flex flex-col gap-[1rem]  px-[0.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                                                <Heading size="headinglg" as="h3" className="tracking-[0.00rem] text-center !text-blue-900 sm:text-[0.9rem]">
                                                    {item?.data?.name}
                                                </Heading>
                                                <Text size="textmd" as="p" className='text-justify sm:text-center sm:text-[0.9rem]'>

                                                    {item?.data?.aboutTheAnchor}
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {/* {issueData.slice(0, 2).map((item, index) => (
                                    <div key={index} className="sm:flex-col flex w-full items-center gap-[1.50rem] py-[3rem] rounded-[30px] bg-white-a700 px-[1.50rem] shadow-xs md:px-[1.25rem] sm:px-[0rem] sm:items-center">
                                        <div className="w-[18%] sm:w-[50%] "><Img src={item.images[0].imageUrl} alt="Member Image" className=" w-full rounded-[150px]" /></div>
                                        <div className=" w-[75%] flex flex-col gap-[0.63rem] self-stretch md:pb-[2rem] sm:w-full sm:self-center">
                                            <div className="flex flex-col gap-[1rem]  px-[0.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                                                <Heading size="headinglg" as="h3" className="tracking-[0.00rem] text-center !text-blue-900 sm:text-[0.9rem]">
                                                    {item.name}
                                                </Heading>
                                                <Text size="textmd" as="p" className='text-left sm:text-center sm:text-[0.9rem]'>

                                                    {item.aboutTheAnchor}
                                                </Text>
                                            </div>
                                        </div>
                                    </div>
                                ))} */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="self-stretch bg-text_and_main-white px-[3.50rem] pt-[3.00rem] pb-[6rem] lg:pt-[3.00rem] lg:pb-[4.00rem] md:p-[1.25rem] sm:p-[1.00rem]">
                    <div className="flex flex-col items-center gap-[3.75rem] sm:gap-[1.88rem]">
                        <div className="flex flex-col items-center gap-[2.50rem] self-stretch">
                            
                            <div className="flex flex-col items-center gap-[3.75rem] self-stretch sm:gap-[1.88rem]">
                                <div className="flex justify-center gap-[1.88rem] gap-y-[3.75rem] self-stretch px-[5.38rem] lg:grid-cols-4 lg:px-[2.00rem] md:grid-cols-2 md:px-[1.25rem] md:py-[3.25rem] sm:pt-[0rem] sm:block sm:px-[1.00rem]">
                                    {issueData.slice(2).map((item, index) => (
                                        <div key={index} className=" flex flex-col items-center w-[30%] gap-[1.00rem] py-[1.75rem] md:w-[60%] sm:conatiner-xs sm:my-[1.5rem] sm:w-full sm:py-[1.00rem] bg-white-a700 shadow-xs rounded-[16px]">
                                            <Img src={item.images[0].imageUrl} alt="Profile Image" className="h-[9.38rem] w-[9.38rem] rounded-[74px] object-cover" />

                                            <div className="mb-[0.63rem] flex flex-col items-center gap-[0.38rem] self-stretch px-[1.25rem] md:px-[1.25rem]">
                                                <Heading size="headingmd" as="h5" className="tracking-[0.00rem] sm:text-[0.9rem]">
                                                    {item.name}
                                                </Heading>
                                                <Text size="textmd" as="p" className="self-stretch text-center leading-[140%]">
                                                    {item.aboutTheAnchor}
                                                </Text>
                                                <Text size="textmd" as="p" className="self-stretch text-center leading-[140%] sm:text-[0.9rem]">
                                                    {item.excerpt}
                                                </Text>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>

        </>

    )
}     