import { Text, Heading, Img } from "./..";
import React from "react";

export default function UserProfile2({
  userImage = "images/img_carolyn_square_0.png",
  userTitle = "ECONOMICS",
  userDescription,
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center w-[24%] md:w-full gap-[1.00rem] py-[1.75rem] sm:py-[1.00rem] bg-white-a700 shadow-xs rounded-[16px]`}
    >
      <Img src={userImage} alt="Profile Image" className="h-[8.88rem] w-[40%] rounded-[70px] object-contain" />
      <div className="mb-[2.38rem] flex flex-col items-start gap-[0.25rem] self-stretch px-[2.50rem] sm:px-[1.25rem]">
        <Heading size="headingmd" as="h5" className="tracking-[0.00rem]">
          {userTitle}
        </Heading>
        <Text size="textmd" as="p" className="w-full !font-mulish leading-[140%]">
          <span className="font-poppins text-blue-900">Carolyn Kousky</span>
          <span className="font-poppins text-blue_gray-900_02">
            &nbsp;studies climate risks, like floods, and ways to make disaster recovery more equitable.
          </span>
        </Text>
      </div>
    </div>
  );
}
