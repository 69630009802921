import { Heading, Text } from "./..";
import React from "react";

export default function UserProfile3({
  distanceText = "3M",
  dedicatedText = "Dedicated members and activists who inspire us and make what we do possible",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-start w-[32%] md:w-full px-[1.88rem] py-[1.13rem] sm:px-[1.00rem] bg-white-a700_cc shadow-2xl rounded-[28px]`}
    >
      <Text size="text8xl" as="p" className="mt-[0.50rem] !text-[4.31rem] !text-gray-800_01">
        {distanceText}
      </Text>
      <Heading as="p" className="relative mt-[-0.13rem] w-[94%] leading-[1.88rem] !text-gray-800_01">
        {dedicatedText}
      </Heading>
    </div>
  );
}
