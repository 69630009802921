import React from "react";
import { Text, Img, Heading, Input } from "./..";



const InputSubscribeTab = () => {
    return(
        <>
        <div className="container-xs absolute  bottom-[125px] left-0 right-0 top-0 m-auto flex h-max !w-[45%] justify-center gap-[0.88rem] rounded-[60px] bg-blue-900 px-[1.88rem] py-[2.13rem] shadow-2xl lg:w-[45%] lg:px-[1.30rem] lg:py-[1.13rem] md:relative md:!w-[90%] md:top-[-2rem] md:rounded-[20px] md:flex-col sm-w:[95%]sm:p-[1.00rem] ">
              <Input
                name="Email Input"
                placeholder={`Enter your Email here...`}
                className="flex-grow rounded-[30px] !text-gray-800_01 lg:text-[1rem] lg:p-[1.50rem] md:p-[1.25rem]"
              />
              <div className="flex w-[42%] items-center justify-center gap-[0.56rem] rounded-[30px] bg-amber-300 p-[0.63rem] md:w-full md:p-[1.25rem]">
                <Heading size="headings" as="h5">
                  Subscribe Now
                </Heading>
                <Img src="../../images/img_arrow_blue_gray_900_02.svg" alt="Arrow Image" className="h-[2.38rem]" />
              </div>
            </div>
        </>
    )
}

export default InputSubscribeTab;