import Footer from "components/Footer";
import Header from "components/Header";
import linkIcon from "assets/images/linkIcon.svg";
import React, { useEffect } from "react";
import { ArrowBackOutlined, ArrowForwardOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Careers = () => {
  const navigate = useNavigate();
  useEffect(() => {
    document
      .getElementById("careers")
      .scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);
  const jobList = [
    {
      jobId: "Assistant_Manager_Climate_Resilient_Fisheries",
      jobName: "Assistant Manager, Climate Resilient Fisheries",
      salary: "Not Disclosed",
    },
    {
      jobId: "Senior_Manager_Climate_Resilient_Fisheries",
      jobName: "Senior Manager, Climate Resilient Fisheries",
      salary: "Not Disclosed",
    },
    {
      jobId: "Portfolio_Analyst",
      jobName: "Portfolio Analyst",
      salary: "Up to INR 12 Lacs per annum",
      location: "Delhi",
    },
  ];
  return (
    <div>
      <Helmet>
        <title>Job openings at EDIF</title>
        <meta
          name="description"
          content="We work hard to make EDIF a great place to work, through our culture, our generous benefits and our commitment to diversity."
        />
        <meta meta="keywords" content="" />
      </Helmet>
      <Header />
      <div
        id="careers"
        className="min-h-[80vh] max-w-[80vw] my-4 mx-auto mt-20"
      >
        <div className="flex flex-col items-center">
          <h1 className="text-[#2D2D2D] sm:text-4xl text-6xl mb-3">
            Job openings at EDIF
          </h1>
          <p className="text-[#424241] sm:text-sm text-2xl sm:w-full w-[1142px] font-light !not-italic font-Poppins">
            We work hard to make EDIF a great place to work, through our
            culture, our generous benefits and our commitment to diversity.
          </p>
        </div>
        <div className="flex sm:flex-col flex-md justify-center gap-10 mt-10">
          {jobList?.map((job) => (
            <div
              className="px-8 py-6  border rounded-lg flex flex-col gap-y-2 h-[150px] max-h-[220px] shadow-custom hover:scale-105 sm:w-full sm:gap-y-2 w-[450px] max-w-[700px] relative cursor-pointer"
              onClick={() => {
                navigate(`/careers/${job.jobId}`);
              }}
            >
              <img src={linkIcon} className="absolute top-6 right-4 w-3" />
              <h1 className=" text-[#1232C4] text-[22px] ">{job?.jobName}</h1>
              <div className="text-[#2D2D2D] !text-[15px]">
                <p>{job?.location || "India"}</p>
                <p className="sm:pb-4">$ {job?.salary}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-10 sm:mb-10">
          <button className="bg-[#E2E2E2] p-2 rounded-l-xl cursor-not-allowed">
            <ArrowBackOutlined style={{ fontSize: "14px", margin: "auto 0" }} />
          </button>
          <div className="border w-5 text-center my-auto p-2 pr-4">1</div>
          <button className="bg-[#E2E2E2] p-2 rounded-r-xl cursor-not-allowed">
            <ArrowForwardOutlined style={{ fontSize: "14px" }} />
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Careers;
