import { Helmet } from "react-helmet";
import { Button, Img, Text, Heading, TextArea, Input } from "../../components/index";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import React, { Suspense, useEffect, useState } from "react";
import '../../styles/article.css';


export default function ArticlePageSliderOne() {
    const apiUrl = process.env.REACT_APP_LOCAL_SERVER;
    const [playlistKey, setPlaylistKey] = useState(null);
    const [articleData, setArticleData] = useState([])
    const [dynamicContent, setDynamicContent] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    console.log(playlistKey, "playlistKey");



    const [showHeader, setShowHeader] = useState(1);
    const [showStatic, setShowStatic] = useState(2);
    const [showYouMA, setShowYouMA] = useState(3);
    const [showFooter, setShowFooter] = useState(4);


    // http://35.244.47.173:3003/api/frontend/v2/content/articles/66b1cff00bf8835dfe074c01?siteId=785


    // const fetchKey = async () => {
    //     try {
    //         const response = await fetch(`${apiUrl}/page/1?siteId=785`);
    //         const jsonResponse = await response.json();
    //         console.log(jsonResponse.data, "res");
    //         setPlaylistKey(jsonResponse.data.playlist[0].key || null);
    //     } catch (err) {
    //         setError(err);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    useEffect(() => {
        // fetchKey();
        getPageId()

    }, []);

    const getPageId = () => {

        const id = localStorage.getItem('articlePageApi')
        getDataFromId(id)
    }



    const getDataFromId = async (articId) => {
        if (!articId) return;
        try {
            const mresponse = await fetch(`${apiUrl}/content/articles/${articId}?siteId=822`);
            // const mresponse = await fetch(`${apiUrl}/content/articles/${articId}?siteId=785`);
            const mresponseContent = await mresponse.json();
            console.log(mresponseContent.data, "seconddata")
            setArticleData(mresponseContent?.data, "seconddata")

        } catch (err) {
            setError(err);
        }
    };


    const fetchKey_6 = async () => {
        try {
            const response = await fetch(`${apiUrl}/page/page/6?siteId=822`);
            // const response = await fetch(`${apiUrl}/page/page/6?siteId=785`);
            const jsonResponse = await response.json();
            console.log(jsonResponse.data, "6-one");
            const ArticleHeader = jsonResponse?.data?.playlist?.find(d => d.name === "Article Header");
            const StaticBlock = jsonResponse?.data?.playlist?.find(d => d.name === "Static Block");
            const YouMayAlsoLike = jsonResponse?.data?.playlist?.find(d => d.name === "You May Also Like");
            const Footer = jsonResponse?.data?.playlist?.find(d => d.name === "Footer");
            console.log('ArticleHeader', ArticleHeader)
            setShowHeader(ArticleHeader ? ArticleHeader?.key : 1)
            setShowStatic(StaticBlock ? StaticBlock?.key : 2)
            setShowYouMA(YouMayAlsoLike ? YouMayAlsoLike?.key : 3)
            setShowFooter(Footer ? Footer?.key : 4)
            // setPlaylistKey(jsonResponse.data.playlist[2].key || null);
            // console.log(jsonResponse.data.playlist, "key")

        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchKey_6();


    }, [apiUrl]);

    // const getDataFromKeys_6 = async () => {
    //     if (!playlistKey) return;

    //     try {
    //         const response = await fetch(`${apiUrl}/playlist/${playlistKey}?siteId=785`);
    //         const resContent = await response.json();
    //         // setSliderData(resContent.data?.content || []);
    //         // console.log(resContent, "6_two");
    //         // console.log(resContent.data, "6_twoData");
    //         // setDynamicContent(resContent.data?.dynamic.content?.[0], "img");

    //         // setIdNumber(resContent.data?.content[0]?.id || null);

    //     } catch (err) {
    //         setError(err);
    //     }
    // };

    // useEffect(() => {
    //     if (playlistKey) {
    //         getDataFromKeys_6();
    //     }
    // }, [playlistKey]);




    // if (loading) return <div>Loading...</div>;
    // if (error) return <div>Error: {error.message}</div>;

    // const formatDate = (dateString) => {
    //     if (dateString) {
    //         const date = new Date(dateString);
    //         const options = { year: 'numeric', month: 'long', day: 'numeric' };
    //         return new Intl.DateTimeFormat('en-US', options).format(date);
    //     }
    // }



    const imageUrl = articleData?.images?.[0]?.imageUrl?.[0] || 'default-image-url';

    return (
        <>
            <Helmet>
                <title>Home Environment - Join the Fight Against Climate Change</title>
                <meta
                    name="description"
                    content="Discover how fishing communities are thriving despite climate challenges. Learn about our impactful environmental strategies for a sustainable future. Get involved with our cause for a healthier planet."
                />
            </Helmet>

            {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ order: showHeader }}>Artci</div>
                <div style={{ order: showStatic }}>stat</div>
                <div style={{ order: showYouMA }}>you may </div>
                <div style={{ order: showFooter }}>footer</div>
            </div> */}

            <div className="w-full bg-white-a700">
                <div className="flex flex-col items-center">

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ order: showHeader }}>
                        <section>
                                <div className="container-xs my-[3rem] text-center mb-[2rem] sm:my-[1rem]">
                                    <Text as="h2" className=" text-[4rem] lg:text-[3.00rem] font-normal ">
                                        YOU MAY ALSO LIKE
                                        {/* {youMayAlsoLikeHeadline.name} */}
                                    </Text>
                                    <div className=" w-[90%] m-auto flex  gap-[1.50rem] px-[0.25rem] md:w-full md:flex-col mt-[2rem] mb-[5rem]">
                                        {dynamicContent.map((item, index) => (
                                            <div key={index} className="w-full rounded-[20px] mb-[1.5rem] bg-white-a700 shadow-xs sm:py-[1.00rem]">
                                                <div className="w-full"> <img src={item?.images?.[0]?.imageUrl} className="w-full" /></div>
                                                <div className="flex flex-col text-left gap-[0.38rem] self-stretch px-[2rem] py-[1.5rem]">
                                                    <Text size="text_2" as="p" className="self-stretch !font-semibold text-left leading-[150%] text-[1.5rem]   lg:text-[0.84rem]">{item.author?.[0]?.name}</Text>
                                                    <Text size="text_2" as="p" className="self-stretch text-left leading-[150%] text-[0.9rem] lg:text-[0.84rem]"> {item.date}</Text>
                                                    <Text size="text_2" as="p" className="self-stretch !font-semibold text-left leading-[150%] text-[1.1rem] lg:text-[0.84rem]"> {item.content1}</Text>
                                                    <Text size="text_2" as="p" className="self-stretch text-left leading-[150%] text-[0.9rem] lg:text-[0.84rem]"> {item.content2}</Text>

                                                </div>


                                            </div>
                                        ))}
                                        {/* {youMayAlsoLike.map((item, index) => (
                                    <div key={index} className="w-full rounded-[20px] mb-[1.5rem] bg-white-a700 shadow-xs sm:py-[1.00rem]">
                                        <div className="w-full"> <img src={item.image} className="w-full" /></div>
                                        <div className="flex flex-col text-left gap-[0.38rem] self-stretch px-[2rem] py-[1.5rem]">
                                            <Text size="text_2" as="p" className="self-stretch !font-semibold text-left leading-[150%] text-[1.5rem]   lg:text-[0.84rem]">{item.name}</Text>
                                            <Text size="text_2" as="p" className="self-stretch text-left leading-[150%] text-[0.9rem] lg:text-[0.84rem]"> {item.date}</Text>
                                            <Text size="text_2" as="p" className="self-stretch !font-semibold text-left leading-[150%] text-[1.1rem] lg:text-[0.84rem]"> {item.content1}</Text>
                                            <Text size="text_2" as="p" className="self-stretch text-left leading-[150%] text-[0.9rem] lg:text-[0.84rem]"> {item.content2}</Text>

                                        </div>


                                    </div>
                                ))} */}
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div style={{ order: showStatic }}>  
                            
                            <section className="self-stretch bg-text_and_main-white ">
                                <div className="relative h-[100vh] lg:h-[100vh] md:h-[92vh] sm:h-[100vh] bg-cover bg-center ">
                                    <div className="absolute w-[100%]  m-auto h-[50.00rem] flex-1 lg:h-auto md:h-auto">
                                        <div className="mb-[2.50rem] flex flex-col   sm:gap-[4.56rem]">
                                            <Header />
                                            <div className="relative mt-[-8%] lg:mt-[-10%] flex justify-center bg-gray-100a h-[106vh] lg:h-[106vh] md:mt-[-15%] md:h-[100vh] sm:h-[100vh] sm:mt-[-35%]">
                                                <img src={imageUrl} alt="Article Logo" className="w-full" />
                                                <div className='overlay_blue'></div>

                                                <div className="container-xs absolute top-[30%] lg:left-[11%] left-2/1 lg:pr-[6rem] lg:w-[50%] md:pr-[0rem] md:w-[84%] md:top-[30%] md:left-[11%] sm:[10rem] sm:left-12 sm:pr-[0rem] sm:w-[90%] sliderCust">
                                                    <div className="flex flex-col w-[80%] lg:w-[80%] md:w-[60%] sm:w-[95%]  items-start self-stretch">
                                                        <Text
                                                            size="text3xl"
                                                            as="p"
                                                            className="w-[55%] leading-[130%] !text-gold-g700 lg:w-full md:w-full lg:pb-[1rem]"
                                                        >
                                                            {/* Vanessa Glavinskas / 3 minute read */}
                                                            {/* {articleData?.author?.[0]?.name} */}
                                                            {articleData?.author?.name}
                                                        </Text>
                                                        <Text
                                                            size="text7xl"
                                                            as="h1"
                                                            className="w-[55%] leading-[120%] !text-white-a700 lg:w-full lg:text-[3.00rem] md:w-full article_h1" >
                                                            {/* Fighting climate change costs far less than doing nothing */}
                                                            {articleData?.headline}
                                                        </Text>
                                                        <Text
                                                            size="textxl"
                                                            as="p"
                                                            className="mt-[0.50rem] w-[55%] leading-[130%] !text-white-a700 lg:w-full md:w-full lg:pt-[1rem] article_p1"
                                                        >
                                                            {/* Published: June 12.2024 */}
                                                            {/* {articleData.publishedAt} */}
                                                            {/* {formatDate(articleData?.publishedAt)} */}
                                                            {articleData?.publishedAt}

                                                        </Text>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div style={{ order: showYouMA }}>
                        <section>
                                <div className=" mt-[2rem] pt-[2rem] ">
                                    {/* {articleData.pageContent} */}
                                    <div className="article_page" dangerouslySetInnerHTML={{ __html: articleData?.pageContent }} />

                                    {/* {articleModule1.map((item, index) => (
                                <div key={index} className="container-xs flex-col items-center self-stretch  bg-white-a700  py-[4.25rem] md:py[1.25rem] sm:py-[1rem] ">
                                    <div className="flex w-[90%] m-auto gap-[1.88rem] md:block sm:block">
                                        <div className="text-left">
                                            <Text as="p" size="textsm" className="flex flex-col gap-[1rem] text-[1.5rem]">
                                                <div>{item.content1}</div>
                                                <div>{item.content2}</div>
                                                <div>{item.content3}</div>
                                                <div> {item.content4}</div>
                                                <div>{item.content5}</div>


                                            </Text>
                                        </div>
                                        <div className=" w-full rounded-[20px] mb-[1.5rem] bg-white-a700 px-[2rem] py-[2rem] shadow-xs md:my-[3.00rem] sm:my-[3.00rem]">
                                            <div className="w-[100%]"><img src={item.img} alt="Shilong village" className="w-[100%]" /></div>
                                            <div className="mt-[1.3rem] lg:mt-[0.8rem]">
                                                <text className="text-[1.5rem]">
                                                    {item.Img_content}
                                                </text>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-[90%] gap-3 mt-[1rem] mb-auto mx-auto lg:mt-[0.8rem]">
                                        <text className="text-[1.5rem]">
                                            {item.content6}

                                        </text>
                                        <text className="text-[1.5rem]">
                                            {item.content7}

                                        </text>
                                        ....<div className=" flex justify-center gap-[1.6rem] md:block">
                                            <Button
                                                size="text-[18px]"
                                                color="gray_100_01"
                                                shape="round"
                                                rightIcon={
                                                    <Img
                                                        src="images/img_arrowleft.svg"
                                                        alt="Arrow Left"
                                                        className=" h-[0.88rem]  w-[0.88rem]"
                                                    />
                                                }
                                                className="w-[23%] text-[18px] p-[0.8rem] gap-[0.63rem] !rounded-[25px] md:mb-[1rem] sm:mb-[1rem] md:w-[90%] md:px-[3rem]  md:!rounded-[30px] sm:w-full sm:px-[3rem]  sm:!rounded-[30px] "
                                            >
                                                <Text size="textxl" as="p" className="!text-color_black-900  ">
                                                    {item.button1}
                                                </Text>
                                            </Button>
                                            <Button
                                                size="text-[18px]"
                                                color="gray_100_01"
                                                shape="round"
                                                rightIcon={
                                                    <Img
                                                        src="images/img_arrowleft.svg"
                                                        alt="Arrow Left"
                                                        className=" h-[0.88rem]  w-[0.88rem]"
                                                    />
                                                }
                                                className="w-[21%] text-[18px] p-[0.8rem] gap-[0.63rem] !rounded-[25px] md:mb-[1rem] sm:mb-[1rem] md:w-[88%] md:px-[3rem]  md:!rounded-[30px] sm:w-full sm:px-[3rem]  sm:!rounded-[30px] "
                                            >
                                                <Text size="textxl" as="p" className="!text-color_black-900  ">
                                                    {item.button2}
                                                </Text>
                                            </Button>
                                            <button className="text-[18px]"> <ArrowOutwardIcon /></button>
                                            <button>{item.button2} <ArrowOutwardIcon /></button>

                                        </div>.....
                                    </div>

                                </div>
                            ))}; */}
                                </div>
                            </section>
                        </div>
                        <div style={{ order: showFooter }}><Footer /></div>
                    </div>










                    {/* <section>
                        <div className=" bg-gray-100_01">
                            <div className="container-xs py-[3rem] ">
                                {whereWillTheMoney.map((item, index) => (
                                    <div key={index}>
                                        <div className=" mt-[2.00rem]  flex flex-col items-center gap-[0.50rem] px-[3.50rem] lg:p-[1.25rem] md:mt-[0rem] sm:mt-[0rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
                                            <Text size="text9xl"
                                                as="h1"
                                                className="w-[80%] text-[4rem] leading-[120%] text-center lg:w-full lg:text-[3.00rem] md:w-full">
                                                {item.name}
                                            </Text>
                                        </div>
                                        <div className=" mt-[2.00rem] flex flex-col items-center gap-[0.40rem] px-[3.50rem] lg:p-[1.25rem] md:text-center  md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
                                            <text size="text_2" as="p" className="self-stretch leading-[150%] text-[1.5rem] lg:text-[1.2rem] md:text-[1.5rem]">{item.Content1}</text><br></br>
                                            <text size="text_2" as="p" className="self-stretch leading-[150%] text-[1.5rem] lg:text-[1.2rem] md:text-[1.5rem]" >{item.Content2}</text><br></br>
                                            <text size="text_2" as="p" className="self-stretch leading-[150%] text-[1.5rem] lg:text-[1.2rem] md:text-[1.5rem]">{item.Content3}</text><br></br>
                                            <text size="text_2" as="p" className="self-stretch leading-[150%] text-[1.5rem] lg:text-[1.2rem] md:text-[1.5rem]">{item.Content4}</text>
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    </section> */}






                </div>



            </div >

        </>

    )
}     