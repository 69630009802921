import { Img, Text, Heading } from "./..";
import React from "react";

export default function PresidentBidenProfile({
  headlineText = "President Biden’s Vital Climate Leadership “Delivered Real, Positive Benefits for Everyone”",
  dateText = "July 21, 2024",
  arrowImage = "images/img_arrowleft.svg",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center p-[2.38rem] sm:p-[1.00rem] bg-white-a700 shadow-xs flex-1 rounded-[20px]`}
    >
      <div className="mx-[4.50rem] mt-[0.75rem] flex w-[94%] flex-col items-start gap-[0.63rem] sm:mx-0 sm:gap-[0.63rem]">
        <Heading
          size="headingmd"
          as="h5"
          className="w-full uppercase leading-[130%] tracking-[0.00rem] !text-blue-900 sm:w-full sm:text-[1.13rem]"
        >
          {headlineText}
        </Heading>
        <Text size="text_2" as="p" className="sm:text-[0.94rem]">
          {dateText}
        </Text>
      </div>
      <Img src={arrowImage} alt="Left Arrow Image" className="h-[0.88rem] w-[0.88rem] self-end" />
    </div>
  );
}
