import { Helmet } from "react-helmet";
import { Button, Img, Text, Heading, TextArea, Input } from "../../components";
import AdvisoryButtons from "../../components/AdvisoryButtons";
import AnnualReportButtons from "../../components/AnnualReportButtons";
import BlogSection from "../../components/BlogSection";
import BusinessCard from "../../components/BusinessCard";
import ButtonList from "../../components/ButtonList";
import ContactCard from "../../components/ContactCard";
import DateNavigation from "../../components/DateNavigation";
import EventDetails from "../../components/EventDetails";
import ExpertReviewSection from "../../components/ExpertReviewSection";
import FeatureCard from "../../components/FeatureCard";
import FeatureCard1 from "../../components/FeatureCard1";
import FeatureCardList from "../../components/FeatureCardList";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import InfoCard from "../../components/InfoCard";
import LocalBusinessProfile from "../../components/LocalBusinessProfile";
import MercuryStandardsInfo from "../../components/MercuryStandardsInfo";
import NaturalSolutionsProfile from "../../components/NaturalSolutionsProfile";
import OpEdArticle from "../../components/OpEdArticle";
import PodcastProfile from "../../components/PodcastProfile";
import PresidentBidenProfile from "../../components/PresidentBidenProfile";
import StatementRelease from "../../components/StatementRelease";
import StatisticsDisplay from "../../components/StatisticsDisplay";
import UserProfile from "../../components/UserProfile";
import UserProfile1 from "../../components/UserProfile1";
import UserProfile10 from "../../components/UserProfile10";
import UserProfile2 from "../../components/UserProfile2";
import UserProfile3 from "../../components/UserProfile3";
import UserProfile4 from "../../components/UserProfile4";
import UserProfile5 from "../../components/UserProfile5";
import UserProfile6 from "../../components/UserProfile6";
import UserProfile7 from "../../components/UserProfile7";
import UserProfile8 from "../../components/UserProfile8";
import UserProfile9 from "../../components/UserProfile9";
import UserProfileCard from "../../components/UserProfileCard";
import UserProfileList from "../../components/UserProfileList";
import VitalSignsProfile from "../../components/VitalSignsProfile";
import React, { Suspense } from "react";

const OurBoard = () => {

  const partnerProfiles = [
    {
      userName1: "G. Leonard Baker, Jr.",
      userTitle1: "Limited Partner, Sutter Hill Ventures",
      userName2: "Joshua Bekenstein",
      userTitle2: "Managing Director, Bain Capital",
      userName3: "Georges C. Benjamin, MD",
      userTitle3: "Executive Director, American Public Health Association",
    },
    {
      userName1: "G. Leonard Baker, Jr.",
      userTitle1: "Limited Partner, Sutter Hill Ventures",
      userName2: "Joshua Bekenstein",
      userTitle2: "Managing Director, Bain Capital",
      userName3: "Georges C. Benjamin, MD",
      userTitle3: "Executive Director, American Public Health Association",
      username2: "Shelby W. Bonnie",
      usertitle2: "Co-founder, CNET Networks",
    },
    {
      userName1: "G. Leonard Baker, Jr.",
      userTitle1: "Limited Partner, Sutter Hill Ventures",
      userName2: "Joshua Bekenstein",
      userTitle2: "Managing Director, Bain Capital",
      userName3: "Georges C. Benjamin, MD",
      userTitle3: "Executive Director, American Public Health Association",
    },
    {
      userName1: "G. Leonard Baker, Jr.",
      userTitle1: "Limited Partner, Sutter Hill Ventures",
      userName2: "Joshua Bekenstein",
      userTitle2: "Managing Director, Bain Capital",
      userName3: "Georges C. Benjamin, MD",
      userTitle3: "Executive Director, American Public Health Association",
      username2: "Susan Ford Dorsey",
      usertitle2: "President, Sand Hill Foundation",
    },
    {
      userName1: "G. Leonard Baker, Jr.",
      userTitle1: "Limited Partner, Sutter Hill Ventures",
      userName2: "Joshua Bekenstein",
      userTitle2: "Managing Director, Bain Capital",
      userName3: "Georges C. Benjamin, MD",
      userTitle3: "Executive Director, American Public Health Association",
    },
    {
      userName1: "G. Leonard Baker, Jr.",
      userTitle1: "Limited Partner, Sutter Hill Ventures",
      userName2: "Joshua Bekenstein",
      userTitle2: "Managing Director, Bain Capital",
      userName3: "Georges C. Benjamin, MD",
      userTitle3: "Executive Director, American Public Health Association",
      username1: "Coddy Johnson",
      usertitle1: "Partner, Goodwater Capital",
    },
    {
      userName1: "G. Leonard Baker, Jr.",
      userTitle1: "Limited Partner, Sutter Hill Ventures",
      userName2: "Joshua Bekenstein",
      userTitle2: "Managing Director, Bain Capital",
      userName3: "Georges C. Benjamin, MD",
      userTitle3: "Executive Director, American Public Health Association",
      username2: "Abby Leigh",
      usertitle2: "Artist",
      username3: "Frank Loy",
      usertitle3: "Former Under Secretary of State for Global Affairs",
    },
    {
      userName1: "G. Leonard Baker, Jr.",
      userTitle1: "Limited Partner, Sutter Hill Ventures",
      userName2: "Joshua Bekenstein",
      userTitle2: "Managing Director, Bain Capital",
      userName3: "Georges C. Benjamin, MD",
      userTitle3: "Executive Director, American Public Health Association",
      username1: "Secretary Ray Mabus",
      usertitle1: "Former Secretary of the Navy",
      username2: "Susan Mandel",
      usertitle2: "ZOOM Foundation",
    },
    {
      userName1: "G. Leonard Baker, Jr.",
      userTitle1: "Limited Partner, Sutter Hill Ventures",
      userName2: "Joshua Bekenstein",
      userTitle2: "Managing Director, Bain Capital",
      userName3: "Georges C. Benjamin, MD",
      userTitle3: "Executive Director, American Public Health Association",
      username2: "Kathryn Murdoch",
      usertitle2: "President, Quadrivium Foundation",
      username3: "Susan Oberndorf",
      usertitle3: "President, Susan and William Oberndorf Foundation",
    },
    {
      userName1: "G. Leonard Baker, Jr.",
      userTitle1: "Limited Partner, Sutter Hill Ventures",
      userName2: "Joshua Bekenstein",
      userTitle2: "Managing Director, Bain Capital",
      userName3: "Georges C. Benjamin, MD",
      userTitle3: "Executive Director, American Public Health Association",
      username1: "Virginia Sall",
      usertitle1: "Co-founder, Sall Family Foundation",
      username2: "Lise Strickler",
      usertitle2: "Three Cairns Group",
    },
    {
      userName1: "G. Leonard Baker, Jr.",
      userTitle1: "Limited Partner, Sutter Hill Ventures",
      userName2: "Joshua Bekenstein",
      userTitle2: "Managing Director, Bain Capital",
      userName3: "Georges C. Benjamin, MD",
      userTitle3: "Executive Director, American Public Health Association",
    },
    {
      userName1: "G. Leonard Baker, Jr.",
      userTitle1: "Limited Partner, Sutter Hill Ventures",
      userName2: "Joshua Bekenstein",
      userTitle2: "Managing Director, Bain Capital",
      userName3: "Georges C. Benjamin, MD",
      userTitle3: "Executive Director, American Public Health Association",
      username3: "Alice Hill",
      usertitle3: "David M. Rubenstein Senior Fellow for Energy and the Environment, Council on Foreign Relations",
    },
    {
      userName1: "G. Leonard Baker, Jr.",
      userTitle1: "Limited Partner, Sutter Hill Ventures",
      userName2: "Joshua Bekenstein",
      userTitle2: "Managing Director, Bain Capital",
      userName3: "Georges C. Benjamin, MD",
      userTitle3: "Executive Director, American Public Health Association",
      username3: "Ruth DeFries, Ph.D.",
      usertitle3:
        "Denning Family Professor of Sustainable Development, Department of Ecology, Evolution, and Environmental Biology, Columbia University",
    },
  ];

  return (
    <>
      <div className="self-stretch bg-text_and_main-white">
        <div className="relative h-[50.0rem]">
          <div className="absolute left-0 right-0 top-[0.00rem] m-auto h-[50.00rem] flex-1 bg-[url(/public/images/img_ourBoardHero.png)] bg-cover bg-no-repeat lg:h-auto md:h-auto">
            <div className="mb-[2.50rem] flex flex-col items-center gap-[9.13rem] lg:gap-[6.81rem] md:gap-[6.81rem] sm:gap-[4.56rem]">
              <Header />
              <div className="container-xs flex flex-col items-center gap-[6.88rem] lg:gap-[6.88rem] lg:p-[1.25rem] md:gap-[5.13rem] md:p-[1.25rem] sm:gap-[3.44rem]">
                <div className="flex flex-col items-start self-stretch">
                  <Text
                    size="text9xl"
                    as="h1"
                    className="w-[50%] leading-[120%] !text-white-a700 lg:w-full lg:text-[3.00rem] md:w-full"
                  >

                    Board Of Trustees
                  </Text>
                  <Text
                    size="text2xl"
                    as="p"
                    className="mt-[0.50rem] w-[54%] leading-[130%] !text-white-a700 lg:w-full md:w-full"
                  >
                    Fortune magazine called our board one of the most influential nonprofit boards in the country.
                  </Text>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center self-stretch bg-white-a700 py-[1.50rem] sm:py-[1.00rem]">
        <div className="container-xs mt-[2.50rem] flex justify-center px-[1.25rem] lg:p-[1.25rem] md:w-full  md:p-[1.25rem] md:text-[40px] sm:w-full sm:[40px]">
          <div className="flex w-full flex-col gap-[5.00rem] py-[3.25rem] lg:gap-[5.00rem] md:gap-[3.75rem] md:py-[1.25rem] sm:gap-[2.50rem] sm:py-[1.00rem]">
            <div className="flex flex-col items-center gap-[1.88rem] px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
              <Text as="h1" className="lg:text-[3.00rem] md:text-[40px] sm:text-[33px]">
                International boards
              </Text>
              <Button
                size="lg"
                shape="round"
                rightIcon={
                  <Img
                    src="../../images/img_arrowleft_gray_600.svg"
                    alt="Arrow Left"
                    className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
                  />
                }
                className="min-w-[23.75rem] gap-[1.00rem] !rounded-[20px] md:text-[14px] sm:min-w-[12.75rem] sm:[14px]"
              >
                EDF Europe Board
              </Button>
            </div>
            <div className="flex flex-col items-center gap-[1.88rem]">
              <Text as="h1" className="lg:text-[3.00rem]">
                National and state boards
              </Text>
              <div className="flex flex-col gap-[1.25rem] self-stretch px-[7.75rem] py-[2.50rem] lg:px-[2.00rem] md:px-[1.25rem] sm:p-[1.00rem]">
                <AdvisoryButtons />
                <AdvisoryButtons
                  advisoryButtonText="Texas Advisory Board"
                  nationalButtonText="N. Carolina Advisory Board"
                  economicsButtonText="Digital Advisory Council"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="self-stretch">
        
          <div className="flex justify-center bg-gray-100_01 py-[6.13rem] lg:py-[2.00rem] md:py-[1.25rem] sm:py-[1.00rem]">
            <div className="container-xs flex justify-center px-[0.63rem] lg:p-[1.25rem] md:p-[1.25rem]">
              <div className="flex w-full gap-[1.38rem] py-[1.38rem] md:flex-col sm:py-[1.00rem]">
                <div className="w-[44%] rounded-[20px] bg-white-a700 px-[1.38rem] py-[2.75rem] shadow-xs md:w-full md:py-[1.25rem] sm:p-[1.00rem]">
                  <div className="ml-[1.38rem] flex flex-col items-start gap-[2.50rem] md:ml-0">
                    <Text as="h1" className="!text-color_black-900 lg:text-[3.00rem]">
                      Officers
                    </Text>
                    <Text
                      size="text3xl"
                      as="p"
                      className="mb-[1.00rem] w-[96%] leading-[140%] !text-gray-800_01 lg:w-full md:w-full"
                    >
                      <span className="font-semibold text-blue-900">Mark W. Heising</span>
                      <span className="text-gray-800_01">
                        <>
                          , Chair
                          <br />
                          Managing Director, Medley Partners
                          <br />
                          Vice Chair, Heising-Simons Foundation
                          <br />
                          <br />
                        </>
                      </span>
                      <span className="font-semibold text-blue-900">Matt Cohler</span>
                      <span className="text-gray-800_01">
                        <>
                          , Vice Chair
                          <br />
                          General Partner, Benchmark
                          <br />
                          <br />
                        </>
                      </span>
                      <span className="font-semibold text-blue-900">Katherine Lorenz</span>
                      <span className="text-gray-800_01">
                        <>
                          , Vice Chair
                          <br />
                          President, Cynthia and George Mitchell Foundation
                          <br />
                          <br />
                        </>
                      </span>
                      <span className="font-semibold text-blue-900">
                        <>
                          Peggy M. Shepard, Vice Chair
                          <br />
                          Co-founder and Executive Director, West Harlem Environmental Action, Inc. (WE ACT for
                          Environmental Justice)
                        </>
                      </span>
                    </Text>
                  </div>
                </div>
                <div className="flex-1 rounded-[20px] bg-white-a700 px-[1.63rem] py-[3.63rem] shadow-xs md:self-stretch md:py-[1.25rem] sm:p-[1.00rem]">
                  <div className="flex flex-col items-center px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                    <Text as="h1" className="!text-color_black-900 lg:text-[3.00rem]">
                      Robert Wilson's legacy
                    </Text>
                    <Heading
                      size="headinglg"
                      as="h2"
                      className="mt-[0.63rem] w-[68%] text-center leading-[140%] !text-blue-900 lg:w-full md:w-full"
                    >
                      <span className="font-normal text-gray-800_01">The</span>
                      <span className="text-blue-900">&nbsp;longtime EDF trustee&nbsp;</span>
                      <span className="font-normal text-gray-800_01">had a lasting impact on the world.</span>
                    </Heading>
                    <Img
                      src="../../images/img_bobwilson_600x400_0.png"
                      alt="Legacy Image"
                      className="mb-[1.13rem] mt-[2.13rem] h-[25.50rem] w-[94%] rounded-[20px] object-contain"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[3.125rem] content-center ">
            <div className=" mt-[3rem] flex-1 bg-white-a700" />
            <div className="container-xs  flex flex-col items-center gap-[3.75rem] px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:gap-[1.88rem] sm:px-[1.00rem]">
              <Text as="h1" className="!text-color_black-900 lg:text-[3.00rem]">
                Trustees
              </Text>
              <div className="flex flex-col gap-[2.38rem] self-stretch">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {partnerProfiles.map((d, index) => (
                    <UserProfileList {...d} key={"trusteesList" + index} />
                  ))}
                </Suspense>
              </div>
            </div>
          </div>
        
        <div className=" z-[17] mt-[-7.88rem]">
          <div className=" h-[28.63rem] content-center py-[2.38rem] lg:h-auto md:h-auto sm:py-[1.00rem]">
            <div className="mx-auto h-[23.88rem] flex-1 bg-gray-100_01" />
            <div className="container-xs my-auto flex h-max flex-col gap-[2.50rem] lg:p-[1.25rem] md:p-[1.25rem]">
              <div className="flex justify-center px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                <Text as="h1" className="!text-color_black-900 lg:text-[3.00rem]">
                  Honorary trustees
                </Text>
              </div>
              <div>
                <div className="flex justify-center gap-[2.50rem] px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                  <Button
                    size="2xl"
                    shape="round"
                    className="min-w-[27.50rem] !rounded-[20px] font-semibold !text-blue-900 !shadow-lg"
                  >
                    Gene E. Likens, Ph.D.
                  </Button>
                  <Button
                    size="2xl"
                    shape="round"
                    className="min-w-[27.50rem] !rounded-[20px] font-semibold !text-blue-900 !shadow-lg"
                  >
                    N. J. Nicholas, Jr.
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default OurBoard
