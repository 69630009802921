import { Helmet } from "react-helmet";
import { Button, Img, Text, Heading, TextArea, Input } from "../../components/index";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import React, { Suspense, useEffect, useState } from "react";
import articleLogo from '../../imageData/artcile_bg.png';
import bobwilson from '../../imageData/bobwilson.png';
import image1 from '../../imageData/Image Placeholder 1.png';
import image2 from '../../imageData/Image Placeholder 1 (1).png';
import image3 from '../../imageData/Image Placeholder 1 (2).png';
import '../../styles/article.css';







const articleModule1 = [
    {
        id: "1",
        content1: "Without action to slow global warming, the economic damage caused by climate change is projected to cost $38 trillion dollars a year.",
        content2: "“That’s like buying every property in New York City nine times over,” says Juan Pablo Hoffmaister, an expert in climate finance at Environmental Defense Fund.",
        content3: "But it doesn’t have to be this way",
        content4: "Scientists say we can still keep global average temperatures from climbing to catastrophic levels if we cut greenhouse gas emissions significantly by 2030. “Prevention is always cheaper than the cure,” Hoffmaister says.",
        content5: "That requires considerable investment in efforts to slash pollution while ramping up renewable energy and other climate change fighting measures. And, so far, we haven’t done enough. ",
        content6: "According to experts at the United Nations, world leaders need to mobilize at least $2.4 trillion just to help the most vulnerable nations adapt to a warming world. Much more is needed to fight all the effects and pump the brakes on climate change.",
        content7: "Here’s what to know.",
        Img_content: "The Shilong Village in China saw massive flooding in June of this year. Without action to slow global warming, the economic damage caused by climate change is projected to cost the world $38 trillion dollars a year. (Getty)",
        button1: "Business and innovation",
        button2: "Climate and energy",
        img: bobwilson,
    },
];

const whereWillTheMoney = [
    {
        id: "1",
        name: "WHERE WILL THE MONEY COM FROM?",
        Content1: "That’s the trillion-dollar question. Some will come from governments. Infrastructure, like building seawalls to protect coastal areas from flooding, for example, needs to be publicly funded. But it will also come from private finance, such as corporate investments in renewable energy, energy efficiency, institutional investors, venture capital and private equity funds focused on clean technology, as well as alternative sources, like carbon markets and emissions trading programs.",
        Content2: "The United Nations is also making resources accessible to developing countries, including a new “loss and damage” fund that provides direct, global financial support to countries and communities struggling with the effects of climate change.",
        Content3: "Funding for climate solutions can also come in the form of debt forgiveness. Belize, for example, is in talks to have its debt forgiven in exchange for funding natural climate solutions in the country.",
        Content4: "“Every country has competing priorities that put a strain on resources,” Hoffmaister says. “But we have to remind ourselves that the impacts of climate change are not limited to national borders, and increasing resilience in other countries helps all of us.”",
    },

];
const youMayAlsoLikeHeadline = [
    {
        id: "1",
        name: "You may also like",
    }
]
const youMayAlsoLike = [
    {
        id: "1",
        image: image1,
        name: "Climate change",
        date: "August 8, 2024",
        content1: "As one of America's largest utilities doubles down on dirty energy, residents...",
        content2: "Duke energy, one of the largest utilities in the U.S., is planning on expanding gas-powered plants in North Carolina, where residents will...",

    },
    {
        id: "2",
        image: image2,
        name: "Explainer",
        date: "August 7, 2024",
        content1: "Fighting wildfires with fire: Canprescribed burns save our forests?",
        content2: "As climate change increases the risk of deadly wildfires, a very old practice is gaining a resurgence to help fight fire with fire.",

    },
    {
        id: "3",
        image: image3,
        name: "People",
        date: "August 1, 2024",
        content1: "Families celebrate EPA ban on killer paint stripper chemical",
        content2: "Methylene chloride, a highly toxic chemical used to strip paint for decades, is being phased out for most uses, thanks to a new rule from...",

    },

];


export default function ApiTest() {
    const apiUrl = process.env.REACT_APP_LOCAL_SERVER;
    const [playlistKey, setPlaylistKey] = useState(null);
    const [articleData, setArticleData] = useState([])
    const [sliderData, setSliderData] = useState([])
    const [dynamicContent, setDynamicContent] = useState([])
    const [idNumber, setIdNumber] = useState("")
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    console.log(playlistKey, "id");
    console.log(idNumber, "idArticle");
    console.log(articleData, "articledata");
    console.log(sliderData,"sliderData");
    


 // http://35.244.47.173:3003/api/frontend/v2/content/articles/66b1cff00bf8835dfe074c01?siteId=785


    // http://35.244.47.173:3003/api/frontend/v2/page/6?siteId=785
    // http://35.244.47.173:3003/api/frontend/v2/playlist/15,13,14,16?siteId=785
    // http://35.244.47.173:3003/api/frontend/v2/playlist/15,13,14,16?siteId=785===onclick id will be generated will 
  // http://35.244.47.173:3003/api/frontend/v2/content/articles/{id}?siteId=785



    // http://35.244.47.173:3003/api/frontend/v2/content/articles/66b1cff00bf8835dfe074c01?siteId=785


    const fetchKey = async () => {
        try {
            const response = await fetch(`${apiUrl}/page/page/6?siteId=822`);
            // const response = await fetch(`${apiUrl}/page/6?siteId=785`);
            const jsonResponse = await response.json();
            console.log(jsonResponse.data, "res");
            setPlaylistKey(jsonResponse.data.playlist[1].key || null);
            console.log(jsonResponse.data.playlist, "key")

        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchKey();
        getPageId()
    }, [apiUrl]);

    const getPageId = () => {

        const urlObj = new URL(window.location);
        const params = new URLSearchParams(urlObj.search);
        const id = params.get('id');

        console.log(id, "param");
        getDataFromId(id)
    }

    const getDataFromKeys = async () => {
        if (!playlistKey) return;

        try {
            const response = await fetch(`${apiUrl}/playlist/playlist/${playlistKey}?siteId=822`);
            // const response = await fetch(`${apiUrl}/playlist/${playlistKey}?siteId=785`);
            const resContent = await response.json();
            setSliderData(resContent.data?.content || []);
            console.log(resContent, "img");
            // setDynamicContent(resContent.data?.dynamic.content, "img");

            // setIdNumber(resContent.data?.content[0]?.id || null);

        } catch (err) {
            setError(err);
        }
    };

    useEffect(() => {
        if (playlistKey) {
            getDataFromKeys();
        }
    }, [playlistKey]);


    const getDataFromId = async (idNumber) => {
        if (!idNumber) return;
        try {
            const mresponse = await fetch('http://35.244.47.173:3003/api/frontend/v2/content/articles/567890987654567890?siteId=822');
            // const mresponse = await fetch('http://35.244.47.173:3003/api/frontend/v2/content/articles/567890987654567890?siteId=785');
            const mresponseContent = await mresponse.json();
            console.log(mresponseContent, "second")
            // console.log(mresponseContent.data, "seconddata")
            // setArticleData(mresponseContent.data, "seconddata")


        } catch (err) {
            setError(err);
        }

    };

    // useEffect(() => {
    //     if (idNumber) {
    //         getDataFromId();
    //     }
    // }, [idNumber]);




    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Intl.DateTimeFormat('en-US', options).format(date);
        }
    }



    const imageUrl = articleData?.images?.[0]?.imageUrl?.[0] || 'default-image-url';

    return (
        <>
       
                 {/* <Helmet>
                <title>Home Environment - Join the Fight Against Climate Change</title>
                <meta
                    name="description"
                    content="Discover how fishing communities are thriving despite climate challenges. Learn about our impactful environmental strategies for a sustainable future. Get involved with our cause for a healthier planet."
                />
            </Helmet>
            <div className="w-full bg-white-a700">
                <div className="flex flex-col items-center">
                    <div className="self-stretch bg-text_and_main-white ">
                        <div className="relative h-[100vh] ">
                            <div className="absolute w-[100%]  m-auto flex-1  bg-cover bg-no-repeat lg:h-auto md:h-auto">
                                <div className="mb-[2.50rem] flex flex-col   sm:gap-[4.56rem]">
                                    <Header />
                                    <div className="relative mt-[-8%] lg:mt-[-10%] flex justify-center bg-gray-100a h-[106vh] lg:h-[106vh] md:mt-[-15%] md:h-[100vh] sm:h-[100vh] sm:mt-[-35%]">
                                        <img src={imageUrl} alt="Article Logo" className="w-full" />
                                        <div className="container-xs absolute w-[45%] left-[7rem] top-[15rem] lg:w-[45%] lg:top-[12rem] lg:left-[6.5rem] md:top-[10rem] md:left-[1.3rem] sm:top-[5rem] sm:left-[1.0rem] flex flex-col items-center gap-[6.88rem] lg:gap-[6.88rem] lg:p-[1.25rem] md:gap-[5.13rem] md:p-[1.25rem] sm:gap-[3.44rem]">
                                            <div className="flex flex-col w-[80%] lg:w-[80%] md:w-[60%] sm:w-[95%]  items-start self-stretch">
                                                <Text
                                                    size="text3xl"
                                                    as="p"
                                                    className="w-[55%] leading-[130%] !text-gold-g700 lg:w-full md:w-full lg:pb-[1rem]"
                                                >
                                                    Vanessa Glavinskas / 3 minute read
                                                    {articleData.author?.[0].name}
                                                </Text>
                                                <Text
                                                    size="text9xl"
                                                    as="h1"
                                                    className="w-[55%] leading-[120%] !text-white-a700 lg:w-full lg:text-[3.00rem] md:w-full"
                                                >
                                                    Fighting climate change costs far less than doing nothing
                                                    {articleData.headline}
                                                </Text>
                                                <Text
                                                    size="textxl"
                                                    as="p"
                                                    className="mt-[0.50rem] w-[55%] leading-[130%] !text-white-a700 lg:w-full md:w-full lg:pt-[1rem]"
                                                >
                                                    Published: June 12.2024
                                                    {articleData.publishedAt}
                                                    {formatDate(articleData.publishedAt)}

                                                </Text>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <section>
                        <div className=" mt-[2rem] pt-[2rem] ">
                            {articleData.pageContent}
                            <div className="article_page" dangerouslySetInnerHTML={{ __html: articleData.pageContent }} />
                            
                            {articleModule1.map((item, index) => (
                                <div key={index} className="container-xs flex-col items-center self-stretch  bg-white-a700  py-[4.25rem] md:py[1.25rem] sm:py-[1rem] ">
                                    <div className="flex w-[90%] m-auto gap-[1.88rem] md:block sm:block">
                                        <div className="text-left">
                                            <Text as="p" size="textsm" className="flex flex-col gap-[1rem] text-[1.5rem]">
                                                <div>{item.content1}</div>
                                                <div>{item.content2}</div>
                                                <div>{item.content3}</div>
                                                <div> {item.content4}</div>
                                                <div>{item.content5}</div>


                                            </Text>
                                        </div>
                                        <div className=" w-full rounded-[20px] mb-[1.5rem] bg-white-a700 px-[2rem] py-[2rem] shadow-xs md:my-[3.00rem] sm:my-[3.00rem]">
                                            <div className="w-[100%]"><img src={item.img} alt="Shilong village" className="w-[100%]" /></div>
                                            <div className="mt-[1.3rem] lg:mt-[0.8rem]">
                                                <text className="text-[1.5rem]">
                                                    {item.Img_content}
                                                </text>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-[90%] gap-3 mt-[1rem] mb-auto mx-auto lg:mt-[0.8rem]">
                                        <text className="text-[1.5rem]">
                                            {item.content6}

                                        </text>
                                        <text className="text-[1.5rem]">
                                            {item.content7}

                                        </text>
                                        ....<div className=" flex justify-center gap-[1.6rem] md:block">
                                            <Button
                                                size="text-[18px]"
                                                color="gray_100_01"
                                                shape="round"
                                                rightIcon={
                                                    <Img
                                                        src="images/img_arrowleft.svg"
                                                        alt="Arrow Left"
                                                        className=" h-[0.88rem]  w-[0.88rem]"
                                                    />
                                                }
                                                className="w-[23%] text-[18px] p-[0.8rem] gap-[0.63rem] !rounded-[25px] md:mb-[1rem] sm:mb-[1rem] md:w-[90%] md:px-[3rem]  md:!rounded-[30px] sm:w-full sm:px-[3rem]  sm:!rounded-[30px] "
                                            >
                                                <Text size="textxl" as="p" className="!text-color_black-900  ">
                                                    {item.button1}
                                                </Text>
                                            </Button>
                                            <Button
                                                size="text-[18px]"
                                                color="gray_100_01"
                                                shape="round"
                                                rightIcon={
                                                    <Img
                                                        src="images/img_arrowleft.svg"
                                                        alt="Arrow Left"
                                                        className=" h-[0.88rem]  w-[0.88rem]"
                                                    />
                                                }
                                                className="w-[21%] text-[18px] p-[0.8rem] gap-[0.63rem] !rounded-[25px] md:mb-[1rem] sm:mb-[1rem] md:w-[88%] md:px-[3rem]  md:!rounded-[30px] sm:w-full sm:px-[3rem]  sm:!rounded-[30px] "
                                            >
                                                <Text size="textxl" as="p" className="!text-color_black-900  ">
                                                    {item.button2}
                                                </Text>
                                            </Button>
                                            <button className="text-[18px]"> <ArrowOutwardIcon /></button>
                                            <button>{item.button2} <ArrowOutwardIcon /></button>

                                        </div>.....
                                    </div>

                                </div>
                            ))};
                        </div>
                    </section>

                    <section>
                        <div className=" bg-gray-100_01">
                            <div className="container-xs py-[3rem] ">
                                {whereWillTheMoney.map((item, index) => (
                                    <div key={index}>
                                        <div className=" mt-[2.00rem]  flex flex-col items-center gap-[0.50rem] px-[3.50rem] lg:p-[1.25rem] md:mt-[0rem] sm:mt-[0rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
                                            <Text size="text9xl"
                                                as="h1"
                                                className="w-[80%] text-[4rem] leading-[120%] text-center lg:w-full lg:text-[3.00rem] md:w-full">
                                                {item.name}
                                            </Text>
                                        </div>
                                        <div className=" mt-[2.00rem] flex flex-col items-center gap-[0.40rem] px-[3.50rem] lg:p-[1.25rem] md:text-center  md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
                                            <text size="text_2" as="p" className="self-stretch leading-[150%] text-[1.5rem] lg:text-[1.2rem] md:text-[1.5rem]">{item.Content1}</text><br></br>
                                            <text size="text_2" as="p" className="self-stretch leading-[150%] text-[1.5rem] lg:text-[1.2rem] md:text-[1.5rem]" >{item.Content2}</text><br></br>
                                            <text size="text_2" as="p" className="self-stretch leading-[150%] text-[1.5rem] lg:text-[1.2rem] md:text-[1.5rem]">{item.Content3}</text><br></br>
                                            <text size="text_2" as="p" className="self-stretch leading-[150%] text-[1.5rem] lg:text-[1.2rem] md:text-[1.5rem]">{item.Content4}</text>
                                        </div>

                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    <section>
                        <div className="container-xs my-[3rem] text-center mb-[2rem]">
                            <Text as="h2" className=" text-[4rem] lg:text-[3.00rem] font-normal ">
                                YOU MAY ALSO LIKE
                                {youMayAlsoLikeHeadline.name}
                            </Text>
                            <div className=" w-[90%] m-auto flex  gap-[1.50rem] px-[0.25rem] md:w-full md:flex-col mt-[2rem] mb-[5rem]">
                            {dynamicContent.map((item, index) => (
                                    <div key={index} className="w-full rounded-[20px] mb-[1.5rem] bg-white-a700 shadow-xs sm:py-[1.00rem]">
                                        <div className="w-full"> <img src={item.images?.[0]?.imageUrl} className="w-full" /></div>
                                        <div className="flex flex-col text-left gap-[0.38rem] self-stretch px-[2rem] py-[1.5rem]">
                                            <Text size="text_2" as="p" className="self-stretch !font-semibold text-left leading-[150%] text-[1.5rem]   lg:text-[0.84rem]">{item.name}</Text>
                                            <Text size="text_2" as="p" className="self-stretch text-left leading-[150%] text-[0.9rem] lg:text-[0.84rem]"> {item.date}</Text>
                                            <Text size="text_2" as="p" className="self-stretch !font-semibold text-left leading-[150%] text-[1.1rem] lg:text-[0.84rem]"> {item.content1}</Text>
                                            <Text size="text_2" as="p" className="self-stretch text-left leading-[150%] text-[0.9rem] lg:text-[0.84rem]"> {item.content2}</Text>

                                        </div>


                                    </div>
                                ))}
                                 {youMayAlsoLike.map((item, index) => (
                                    <div key={index} className="w-full rounded-[20px] mb-[1.5rem] bg-white-a700 shadow-xs sm:py-[1.00rem]">
                                        <div className="w-full"> <img src={item.image} className="w-full" /></div>
                                        <div className="flex flex-col text-left gap-[0.38rem] self-stretch px-[2rem] py-[1.5rem]">
                                            <Text size="text_2" as="p" className="self-stretch !font-semibold text-left leading-[150%] text-[1.5rem]   lg:text-[0.84rem]">{item.name}</Text>
                                            <Text size="text_2" as="p" className="self-stretch text-left leading-[150%] text-[0.9rem] lg:text-[0.84rem]"> {item.date}</Text>
                                            <Text size="text_2" as="p" className="self-stretch !font-semibold text-left leading-[150%] text-[1.1rem] lg:text-[0.84rem]"> {item.content1}</Text>
                                            <Text size="text_2" as="p" className="self-stretch text-left leading-[150%] text-[0.9rem] lg:text-[0.84rem]"> {item.content2}</Text>

                                        </div>


                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    <Footer />
                </div>



            </div > */}





        </>

    )
}     