import React, { useEffect, useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button } from "../../components/index";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useNavigate } from "react-router-dom";
import MobileCarousel from './MobileCarousel';


export default function SliderOne() {
    const apiUrl = process.env.REACT_APP_LOCAL_SERVER;
    const [playlistKey, setPlaylistKey] = useState(null);
    const [sliderData, setSliderData] = useState([]);
    const [idArticle, setIdArticle] = useState([]);
    const [activeImageIndex, setActiveImageIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // console.log(idArticle,"id");
    

    


  const navigate = useNavigate();

    
    

    const fetchKey = async () => {
        try {
            const response = await fetch(`${apiUrl}/page/page/1?siteId=822`);
            // const response = await fetch(`${apiUrl}/page/1?siteId=785`);
            const jsonResponse = await response.json();
            // console.log(jsonResponse,"First");
            // console.log(jsonResponse.data,"Second");
            // console.log(jsonResponse.data?.playlist,"Third");
            setPlaylistKey(jsonResponse.data?.playlist?.[1]?.key || null);
            console.log(jsonResponse.data?.playlist?.[0]?.key,"key");
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchKey();
    }, [apiUrl]);

    const getDataFromKeys = async () => {
        if (!playlistKey) return;

        try {
            const response = await fetch(`${apiUrl}/playlist/playlist/${playlistKey}?siteId=822`);
            // const response = await fetch(`${apiUrl}/playlist/${playlistKey}?siteId=785`);
            const resContent = await response.json();
            setSliderData(resContent.data?.content || []);
            console.log(resContent,"sliderImg");
            // setIdArticle(resContent.data?.content[0]?.id || null);
            // console.log(resContent.data?.content[2]?.id,"id" );
            
        } catch (err) {
            setError(err);
        }
    };

    useEffect(() => {
        if (playlistKey) {
            getDataFromKeys();
        }
    }, [playlistKey]);


    // const getDataFromId = async () => {
    //     if (!idArticle) return;

    //     try {
    //         const response = await fetch(`${apiUrl}/content/${idArticle}?siteId=785`);
    //         const resID = await response.json();
    //         setIdArticle(resID.data?.content || []);
    //         console.log(resID.data?.content,"idarticle" );
            
    //     } catch (err) {
    //         setError(err);
    //     }
    // };

    // useEffect(() => {
    //     if (idArticle) {
    //         getDataFromId();
    //     }
    // }, [idArticle]);



    const handlePrevClick = () => {
        setActiveImageIndex((prevIndex) => (prevIndex === 0 ? sliderData.length - 1 : prevIndex - 1));
    };

    const handleNextClick = () => {
        setActiveImageIndex((prevIndex) => (prevIndex + 1) % sliderData.length);
    };

    // if (loading) return <div>Loading...</div>;
    // if (error) return <div>Error: {error.message}</div>;

    // Ensure activeImageIndex is within the bounds of sliderData
    const safeIndex = Math.max(0, Math.min(activeImageIndex, sliderData.length - 1));
    const currentData = sliderData[safeIndex] || {};
    const { name = '', excerpt = '', images = [] } = currentData;

    // const backgroundStyles = [
    //     'bg-gradient7', // Default or first image
    //     'bg-gradient8', // Example for second image
    //     'bg-gradient7', // Example for third image
    //     // Add more styles as needed
    // ];

    // const sliderBackground = backgroundStyles[activeImageIndex % backgroundStyles.length] || 'bg-gradient7';


const handleArticlePage = () => {
    console.log(currentData, "name");
   
    localStorage.setItem('articlePageApi',currentData.slug)
    
        // navigate(`Category/articles/${currentData.slug}`)
        navigate(`${currentData.category}/articles/${currentData.slug}`)
        // navigate(`/article?id=${currentData.id}&name=${convertedUrlName}`)
    
    // else if(safeIndex === 1){
    //     navigate('/article/contentSliderTwo')
    // }
    // else{
    //     navigate('/article/contentSliderThree')

    // }
    // console.log(currentData,safeIndex)
    // navigate('/article/contentSliderone')

    // <MobileCarousel idArticle={idArticle} />
}
const slugify = (text) => {
    return text
        .toString()                // Ensure input is a string
        .toLowerCase()             // Convert to lowercase
        .trim()                    // Remove leading and trailing spaces
        .replace(/[\s\W-]+/g, '-') // Replace spaces and non-word characters with dashes
        .replace(/^-+|-+$/g, '');  // Remove leading and trailing dashes
}



    return (
        <div className="relative mt-[-8%] lg:mt-[-10%] flex justify-center bg-gray-100a h-[106vh] lg:h-[106vh] md:mt-[-15%] md:h-[100vh] sm:h-[100vh] sm:mt-[-45%]">
            {images?.length > 0 ? 
                <img
                    src={images[0].imageUrl}
                    alt="Slider Image"
                    className="w-full h-full "
                />
            : " "}
            {/* object-cover */}
            <div className='overlay_blue'></div>
            <div className="container-xs absolute top-[30%] lg:left-[11%] left-2/1 lg:pr-[6rem] lg:w-[50%] md:pr-[0rem] md:w-[84%] md:top-[30%] md:left-[11%] sm:[10rem] sm:left-12 sm:pr-[0rem] sm:w-[90%] sliderCust">
                <h1 className='w-[50%] leading-[120%] !text-white-a700 lg:w-full lg:pr-[3rem] md:w-[90%] lg:text-[3.5rem] fontSize_h1 slider_h1 md:text-[3rem] sm:text-[2.0rem]'>{name}</h1>
                <p className='w-[50%] leading-[120%] !text-white-a700 lg:w-full text-[3.00rem] md:w-[70%] lg:text-[1.3rem] md:text-[1rem] sm:pr-[1rem] sm:text-[1.0rem] fontSize_p slider_p'>{excerpt}</p>
                <Button
                    onClick={handleArticlePage}
                // onClick={()=>navigate('/article')}
                //  <MobileCarousel idArticle={idArticle} />

                    color="amber_300"
                    size="lg"
                    className="mt-[3.00rem] min-w-[14.38rem] gap-[0.50rem] rounded-[30px] font-semibold sm:text-[1.0rem] sm:mt-[2.00rem] sm:min-w-[10.38rem] sm:px-[1.3rem] sm:h-[2.75rem]"
                >
                    See How <ArrowRightAltIcon sx={{ fontSize: '30px' }} />
                </Button>
            </div>
            <button
                onClick={handlePrevClick}
                className="absolute top-[50%] left-[1rem] transform -translate-y-1/2 bg-gray-800 p-2 rounded-full"
            >
                <ArrowBackIosNewIcon sx={{ fontSize: '40px', color: '#fff' }} />
            </button>
            <button
                onClick={handleNextClick}
                className="absolute top-[50%] right-[1rem] transform -translate-y-1/2 bg-gray-800 p-2 rounded-full"
            >
                <ArrowForwardIosIcon sx={{ fontSize: '40px', color: '#fff' }} />
            </button>
            
        </div>
    );
}
