import React, { useState, useEffect } from 'react';

const useFetchPlaylistKey = (apiUrl) => {
    const [playlistKey, setPlaylistKey] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchKey = async () => {
            try {
                const response = await fetch(`${apiUrl}/page/2?siteId=785`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const jsonResponse = await response.json();
                setPlaylistKey(jsonResponse?.data?.playlist?.[0].key);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false);
            }
        };

        fetchKey();
    }, [apiUrl]);

    return { playlistKey, loading, error };
};

export default useFetchPlaylistKey;
