import { Text, Heading } from "./..";
import React from "react";

export default function UserProfileList({
  username1 = "G. Leonard Baker, Jr.",
  usertitle1 = "Limited Partner, Sutter Hill Ventures",
  username2 = "Joshua Bekenstein",
  usertitle2 = "Managing Director, Bain Capital",
  username3 = "Georges C. Benjamin, MD",
  usertitle3 = "Executive Director, American Public Health Association",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} flex md:flex-col items-center gap-[2.50rem] flex-1`}>
      <div className="flex flex-1 gap-[2.50rem] md:self-stretch">
        <div className="w-full rounded-[20px] bg-white-a700 px-[1.38rem] py-[2.50rem] shadow-xs sm:p-[1.25rem]">
          <div>
            <div className="mb-[1.50rem] flex flex-col items-start gap-[0.25rem]">
              <Heading size="headings" as="h5" className="uppercase tracking-[0.00rem] !text-blue-900">
                {username1}
              </Heading>
              <Text size="text_2" as="p">
                {usertitle1}
              </Text>
            </div>
          </div>
        </div>
        <div className="w-full rounded-[20px] bg-white-a700 px-[1.38rem] py-[2.38rem] shadow-xs sm:p-[1.25rem]">
          <div className="mb-[0.38rem]">
            <div className="mb-[1.38rem] flex flex-col items-start gap-[0.38rem]">
              <Heading size="headings" as="h5" className="uppercase tracking-[0.00rem] !text-blue-900">
                {username2}
              </Heading>
              <Text size="text_2" as="p">
                {usertitle2}
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-[32%] justify-center rounded-[20px] bg-white-a700 px-[1.38rem] py-[2.50rem] shadow-xs">
        <div className="flex w-full flex-col items-start gap-[0.25rem]">
          <Heading size="headings" as="h5" className="uppercase tracking-[0.00rem] !text-blue-900">
            {username3}
          </Heading>
          <Text size="text_2" as="p" className="w-full leading-[150%]">
            {usertitle3}
          </Text>
        </div>
      </div>
    </div>
  );
}
