import { Text, Img } from "./..";
import React from "react";

export default function UserProfile({
  userImage = "images/img_image_placeholder.png",
  userMessage = "Join a powerful force of over 3 million allies",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col w-[32%] md:w-full gap-[1.50rem] bg-white-a700 shadow-sm rounded-[16px]`}
    >
      <Img
        src={userImage}
        alt="Card Image"
        className="h-[7.25rem] w-full rounded-tl-[16px] rounded-tr-[16px] object-cover"
      />
      <div className="mb-[1.50rem] self-stretch px-[1.50rem] sm:px-[1.25rem]">
        <Text size="text2xl" as="p" className="!font-medium uppercase leading-[130%] tracking-[0.00rem]">
          {userMessage}
        </Text>
      </div>
    </div>
  );
}
