import { Text, Img, Heading } from "./..";
import React from "react";

export default function LocalBusinessProfile({
  businessDescription = "Local business and conservation interests jointly intervene in Mid-Barataria Sediment Diversion lawsuit",
  date = "July 15, 2024",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} flex flex-col items-start gap-[0.50rem] flex-1`}>
      <div className="relative h-[3.75rem] content-center self-stretch sm:h-auto">
        <Heading as="p" className="mx-auto w-full leading-[150%] sm:w-full sm:text-[1.06rem]">
          {businessDescription}
        </Heading>
        <Img
          src="images/img_arrow_left_gray_600_1.svg"
          alt="Thumbnail"
          className="absolute bottom-[0.50rem] left-[34%] m-auto h-[0.88rem] w-[0.88rem]"
        />
      </div>
      <Text size="textmd" as="p" className="capitalize tracking-[0.00rem] !text-blue-900 sm:text-[0.94rem]">
        {date}
      </Text>
    </div>
  );
}
