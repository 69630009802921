import { Text, Img, Heading } from "./..";
import React from "react";

export default function FeatureCard1({
  titleEDFBusiness = "EDF+Business",
  descriptionEDFBusiness = "Making green business the new business as usual.",
  titleMarketForces = "Market Forces",
  descriptionMarketForces = "Showing how economic incentives equalenvironmental gains.",
  titleclimate411 = "CLIMATE 411",
  descriptionclimate411 = "Insights on the science, law and economics ofglobal climate change.",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col w-[32%] md:w-full gap-[2.50rem] px-[1.88rem] sm:px-[1.00rem]`}
    >
      <div className="self-stretch rounded-[20px] bg-white-a700 p-[1.25rem] shadow-lg">
        <div className="flex flex-col gap-[0.38rem]">
          <div className="flex items-start justify-between gap-[1.25rem]">
            <Heading size="headings" as="h5" className="self-center uppercase tracking-[0.00rem] !text-blue-900">
              {titleEDFBusiness}
            </Heading>
            <Img
              src="images/img_arrow_left_gray_600_4.png"
              alt="Edf Business Image"
              className="h-[0.88rem] object-cover"
            />
          </div>
          <Text size="text_2" as="p" className="leading-[150%]">
            {descriptionEDFBusiness}
          </Text>
        </div>
      </div>
      <div className="self-stretch rounded-[20px] bg-white-a700 p-[1.25rem] shadow-lg">
        <div className="flex flex-col gap-[0.38rem]">
          <div className="flex items-start justify-between gap-[1.25rem]">
            <Heading size="headings" as="h5" className="self-center uppercase tracking-[0.00rem] !text-blue-900">
              {titleMarketForces}
            </Heading>
            <Img
              src="images/img_arrow_left_gray_600_4.png"
              alt="Market Forces Image"
              className="h-[0.88rem] object-cover"
            />
          </div>
          <Text size="text_2" as="p" className="leading-[150%]">
            {descriptionMarketForces}
          </Text>
        </div>
      </div>
      <div className="self-stretch rounded-[20px] bg-white-a700 p-[1.25rem] shadow-lg">
        <div className="flex flex-col gap-[0.38rem]">
          <div className="flex items-start justify-between gap-[1.25rem]">
            <Heading size="headings" as="h5" className="self-center uppercase tracking-[0.00rem] !text-blue-900">
              {titleclimate411}
            </Heading>
            <Img src="images/img_arrow_left_gray_600_4.png" alt="Climate Image" className="h-[0.88rem] object-cover" />
          </div>
          <Text size="text_2" as="p" className="leading-[150%]">
            {descriptionclimate411}
          </Text>
        </div>
      </div>
    </div>
  );
}
