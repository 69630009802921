import React, { Suspense, useEffect, useState } from 'react';
import { Button, Img, Text, Heading, TextArea, Input } from "../../components/index";
import { Html } from '@mui/icons-material';
import '../../styles/apiCustom.css';
import { useNavigate } from "react-router-dom";
import { object } from 'prop-types';


export default function WhoWeAre() {
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_LOCAL_SERVER;
  const [playlistKey, setPlaylistKey] = useState(null);
  const [contentID, setContentID] = useState(null)
  const [subjectContent, setSubjectContent] = useState([])
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  console.log(subjectContent, "subjectContent");
  // console.log(issueID,"issueID");
  console.log(contentID, "contentID");


  const fetchKey = async () => {
    try {
      // const response = await fetch(`${apiUrl}/page/page/2?siteId=822`);
      const response = await fetch('https://api.edif.org.in/api/frontend/v2/page/page/2?siteId=822')
      // const response = await fetch(`${apiUrl}/page/2?siteId=785`);
      const jsonResponse = await response.json();
      setPlaylistKey(jsonResponse?.data?.playlist?.[0].key || null);
      console.log(jsonResponse.data.playlist, "key")

    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchKey();
  }, [apiUrl]);

  const getDataFromKeys = async () => {
    if (!playlistKey) return;

    try {
      const response = await fetch(`${apiUrl}/playlist/playlist/${playlistKey}?siteId=822`);
      // const response = await fetch(`${apiUrl}/playlist/${playlistKey}?siteId=785`);
      const resContent = await response.json();
      console.log(resContent.data, "test");

      setContentID(resContent?.data?.content?.[0].key || '');
      console.log(resContent.data?.content[0]?.id, "sliderImg");
      // setIdArticle(resContent.data?.content[0]?.id || null);
      console.log(resContent.data?.content[0], "id");

      // getDataForId(resContent.data?.content[0]?.id)

    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (playlistKey) {
      getDataFromKeys();
    }
  }, [playlistKey]);

  const getDataForId = async () => {
    if (!contentID) return;

    try {
      // const mresponse = await fetch(`${apiUrl}/content/content-block/66b343aaff504887e6993985?siteId=785`);
      const mresponse = await fetch(`${apiUrl}/content/content-block/${contentID}?siteId=822`); //called contect-block ID
      const mresponseContent = await mresponse.json();
      console.log(mresponseContent.data, "ID")
      setSubjectContent(mresponseContent?.data || []);

      // setIssueID(mresponseContent.data?.content?.[0] || null)
      // subjectContent.content.


    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }

  };

  useEffect(() => {
    if (contentID) {
      getDataForId();
    }
  }, [contentID]);

  const asdasd = (expect, gettingData) => {
    console.log(expect, gettingData)
  }
  const contentBlockId = subjectContent?.content?.[0] || 'articleId';
  console.log(contentBlockId, "contentBlockId");

  useEffect(() => {
    if (contentBlockId?.id) {
      const liElements = document.querySelectorAll('.whoWA ul li');

      liElements.forEach((li, index) => {
        let slugName = '';
        let dataid = '';
        let categoryName = '';
        // subjectContent?.content.forEach((item, indexx) => {
        // console.log('Text from <a>:1111111 ', item, li)
        li.addEventListener('click', (e) => {
          e.preventDefault();
          let category, slug;

          const aElement = li.querySelector('a');

          // Check if an <a> element was found and get its text content
          if (aElement) {
            const text = aElement.textContent || aElement.innerText;
            console.log('Text from <a>:', text);
            console.log('Text from <a>:', subjectContent?.content)
            const contentBlockWhoWA = subjectContent?.content.find(d => d.name.toLowerCase() === text.toLowerCase());
            console.log(contentBlockWhoWA, "contentBlockWhoWA");

            // const contentBlockWhoWA = subjectContent?.content.find(d => d.name.toLowerCase() === asdasd(d.name.toLowerCase(), text));
            console.log('Text from <a>:1111', contentBlockWhoWA);
            slugName = contentBlockWhoWA.slug;
            dataid = contentBlockWhoWA.id
            categoryName = contentBlockWhoWA.category
            console.log(slugName);
            console.log(dataid);


          }

          switch (index) {
            case 0:
              category = 'world';
              slug = 'our-story';
              break;
            case 1:
              category = 'mission';
              slug = 'values';
              break;
            case 2:
              category = 'diversity';
              slug = 'commitment';
              break;
            case 3:
              category = 'ethics';
              slug = 'code';
              break;
            case 4:
              category = 'sustainability';
              slug = 'edf';
              break;
            default:
              category = '';
              slug = '';
          }

          console.log(`Navigating to ${category}/articles/${slugName} with id ${dataid}`);
          localStorage.setItem('articlePageApi', dataid);
          navigate(`/${categoryName}/articles/${slugName}`);
        });
        // })

      });

      return () => {
        liElements.forEach((li) => {
          li.replaceWith(li.cloneNode(true));
        });
      };
    }
  }, [contentBlockId.id, navigate]);








  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;





  // const handleArticlePage = () => {
  //   console.log(contentBlockId?.id, "name");
  //   console.log(contentBlockId.category, "name");
  //   // const convertedUrlName = slugify(currentData.name)

  //   localStorage.setItem('articlePageApi',contentBlockId.id)

  //   navigate(`/${contentBlockId.category}/article/${contentBlockId.slug}`)
  // }










  return (
    <>
      {/* {setContentID === null ?  */}
      {/* {Object.keys(subjectContent)?.length && Object.keys(variable)?.length ? data : ""}
      {playlist.length ? data : " "} */}

      <div className="justify-center self-stretch bg-gray-100_01 py-[4.25rem] lg:py-[2.00rem] md:py-[1.25rem] sm:py-[1.00rem]">
        <div className="container-xs  flex justify-center lg:p-[1.25rem] md:p-[0rem]">
          <div className="w-full rounded-[20px] text-center bg-white-a700 px-[0.63rem] pt-[2.13rem] shadow-xs sm:py-[1.00rem]">
            {/* <div className=" "> */}
            <div className=" justify-center  md:pt-[1rem] ">
              <Text as="h1" className="!text-color_black-900 text-center lg:text-[3.00rem] ">
                {subjectContent?.name}
                {/* who we are */}
              </Text >
              {/* <button onClick={handleArticlePage}>asasasass</button> */}
            </div>
            <div className="px-[2rem] my-[2rem] lg:px-[1.38rem] lg:items-center  md:flex-col md:px-[1.25rem] sm:px-[1.00rem]">
              {/* <div className="w-[40%] lg:w-[50%] md:w-[90%] rounded-[20px]">
                  <Img
                    src={subjectContent?.images?.[0]?.imageUrl[0]}
                    // src="images/img_growing_life_co.png"
                    alt="Lifeco Image"
                    className="h-[20.rem] w-[100%] rounded-[20px] object-contain lg:h-[19.rem] md:w-full"
                  />
                </div> */}
              {/* <div className=""> */}
              {/* <div className="text-justify lg:self-stretch md:w-[90%] md:text-center md:self-stretch"> */}
              <Text size="textmd" as="p" className="leading-[140%] !text-gray-800_01 w-[100%] text-[1.44rem]">
                <>
                  <span className='text-justify' // onClick={handleArticlePage}
                    dangerouslySetInnerHTML={{ __html: subjectContent?.pageContent }}></span>
                  {/* We began in 1967, as a scrappy group of scientists and a lawyer on Long Island, New York,
                          fighting to save osprey from the toxic pesticide DDT. Using scientific evidence, our founders
                          got DDT banned in the U.S.
                          <br />
                          <br />
                          Today, we’re one of the world’s top environmental organizations. We deliver bold solutions
                          that cut climate pollution and strengthen the ability of people and nature to thrive — even
                          with the climate change we feel right now.
                          <br />
                          <br />
                          And science still guides everything we do. */}
                </>
              </Text>
              {/* <div className="flex flex-col gap-[0.88rem] w-[100%]">
                <div className="flex gap-[0.7rem] md:flex-col flex-wrap">
                  {subjectContent?.content?.map((item, index) => (
                    <div key={index}>
                      <Button
                        color="gray_100_01"
                        shape="round"
                        rightIcon={
                          <Img
                            src="images/img_arrowleft.svg"
                            alt="Arrow Left"
                            className="h-[0.88rem] lg:h-[0.58rem] w-[0.88rem] lg:w-[0.58rem] "
                          />
                        }
                        className="min-w-[7.13rem] gap-[0.63rem] !rounded-[16px] text-[1.13rem] lg:text-[0.85rem]"
                      >
                        {item.excerpt}
                      </Button>
                    </div>
                  ))}
                 
                </div>
              </div> */}
              {/* </div> */}
            </div>
            {/* </div> */}
          </div>
        </div>
      </div>
      {/* : "" }  */}


    </>

  )
}