import { Heading, Img, Text } from "./..";
import React from "react";

export default function OpEdArticle({
  opEdTitle = "OP-ED",
  opEdDescription = "Scientific American op-ed: Howtemperate forests could help limitclimate change",
  authorImage = "images/img_jason_mathers_square_0_62x62.png",
  authorDetails,
  ...props
}) {
  return (
    <div {...props} className={`${props.className} flex items-center w-[54%] md:w-full`}>
      <div className="relative h-[33.75rem] w-full content-center">
        <div className="flex w-[80%] flex-col gap-[1.25rem] rounded-[16px] bg-white-a700 py-[4.13rem] shadow-xs md:py-[1.25rem]">
          <div className="flex flex-col items-start gap-[0.38rem] px-[2.00rem] sm:px-[1.25rem]">
            <Text size="textmd" as="p" className="ml-[0.38rem] !text-blue-900">
              {opEdTitle}
            </Text>
            <Text size="text5xl" as="p" className="mb-[3.13rem] ml-[0.38rem] w-full leading-[120%] tracking-[0.00rem]">
              {opEdDescription}
            </Text>
          </div>
          <div className="mx-[1.75rem] flex flex-col items-start gap-[1.25rem]">
            <div className="h-[0.06rem] w-[30%] bg-blue_gray-900_2b" />
            <Img
              src={authorImage}
              alt="Profile Image"
              className="h-[3.88rem] w-[3.88rem] rounded-[30px] object-cover"
            />
          </div>
        </div>
        <Heading
          size="headings"
          as="h5"
          className="absolute bottom-[13%] left-[21%] m-auto w-[60%] leading-[140%] !text-color_black-900"
        >
          <span className="text-color_black-900">
            <>
              Amanda Leland
              <br />
            </>
          </span>
          <span className="text-[1.13rem] font-normal text-color_black-900">Executive Director</span>
        </Heading>
      </div>
    </div>
  );
}
