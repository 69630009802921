import Header from 'components/Header';
import { Button, Img, Text, Heading, TextArea, Input } from "../../components/index";
import React from 'react';
import UserProfile10 from "../../components/UserProfile10";
import { Suspense } from "react";
import { Helmet } from 'react-helmet';
import Footer from 'components/Footer';
import BoardMembers from './module-1BM';

export default function ourLeadershipTeam() {

  const teamMembersGrid1 = [
    {
      userImage: "../../images/img_ellipse_28_150x150.png",
      userName: "Sean Cook",
      userTitle: (
        <>
          Executive Vice President
          <br />
          Chief Human Resources &
          <br />
          Administration Officer
        </>
      ),
    },
    {
      userImage: "../../images/img_ellipse_28_1.png",
      userName: "Angela Churie Kallhauge",
      userTitle: (
        <>
          Executive Vice President
          <br />
          Impact
        </>
      ),
    },
    {
      userImage: "../../images/img_ellipse_28_2.png",
      userName: "Pete Harrison",
      userTitle: (
        <>
          Executive Vice President
          <br />
          Regions
        </>
      ),
    },
    {
      userImage: "../../images/img_ellipse_28_3.png",
      userName: "Tom Murray",
      userTitle: (
        <>
          Executive Vice President
          <br />
          Solutions
        </>
      ),
    },
  ];
  const teamMembersGrid = [
    {
      userImage: "../../images/img_ellipse_28_4.png",
      userName: "Joe Bonfiglio",
      userTitle: (
        <>
          Executive Director
          <br />
          U.S. Region
        </>
      ),
    },
    {
      userImage: "../../images/img_ellipse_28_5.png",
      userName: "Lea Borkenhagen",
      userTitle: (
        <>
          Senior Vice President
          <br />
          EDF+Business
        </>
      ),
    },
    {
      userImage: "../../images/img_ellipse_28_6.png",
      userName: "Margot Brown",
      userTitle: (
        <>
          Senior Vice President
          <br />
          Justice and Equity
        </>
      ),
    },
    {
      userImage: "../../images/img_ellipse_28_7.png",
      userName: "Mark Brownstein",
      userTitle: (
        <>
          Senior Vice President
          <br />
          Energy Transition
        </>
      ),
    },
    {
      userImage: "../../images/img_ellipse_28_8.png",
      userName: "Steven Hamburg",
      userTitle: (
        <>
          Senior Vice President
          <br />
          Chief Scientist
        </>
      ),
    },
    {
      userImage: "../../images/img_ellipse_28_9.png",
      userName: "Suzi Kerr",
      userTitle: (
        <>
          Senior Vice President
          <br />
          Chief Economist
        </>
      ),
    },
    {
      userImage: "../../images/img_ellipse_28_10.png",
      userName: "QIN Hu",
      userTitle: (
        <>
          Chief Representative
          <br />
          China
        </>
      ),
    },
    {
      userImage: "../../images/img_ellipse_28_11.png",
      userName: "Hisham Mundol",
      userTitle: (
        <>
          Chief Advisor
          <br />
          India
        </>
      ),
    },
    {
      userImage: "../../images/img_ellipse_28_12.png",
      userName: "Amy Middleton",
      userTitle: (
        <>
          Senior Vice President
          <br />
          Chief Marketing &
          <br />
          Communications Officer
        </>
      ),
    },
    {
      userImage: "../../images/img_ellipse_28_13.png",
      userName: "Lou Mkanganwi",
      userTitle: (
        <>
          Senior Vice President
          <br />
          Chief Finance & Technology Officer
        </>
      ),
    },
    {
      userImage: "../../images/img_ellipse_28_14.png",
      userName: "Jill Isenbarger",
      userTitle: "Chief of Staff",
    },
    {
      userImage: "../../images/img_ellipse_28_15.png",
      userName: "Elizabeth Mattila",
      userTitle: (
        <>
          Vice President
          <br />
          Global HR & Infrastructure
        </>
      ),
    },
    {
      userImage: "../../images/img_ellipse_28_16.png",
      userName: "Mandy Rambharos",
      userTitle: (<>
        Vice President
        <br />
        Global Climate Cooperation
      </>),
    },
    {
      userImage: "../../images/img_ellipse_28_17.png",
      userName: "Helen Spence-Jackson",
      userTitle: (
        <>
          Executive Director
          <br />
          Europe
        </>
      ),
    },
    {
      userImage: "../../images/img_ellipse_28_18.png",
      userName: "Sarah Vogel",
      userTitle: (
        <>
          Senior Vice President
          <br />
          Healthy Communities
        </>
      ),
    },
    {
      userImage: "../../images/img_ellipse_28_19.png",
      userName: "Rory Muhammad",
      userTitle: "Chief Diversity Officer",
    },

  ];
  return (
    <>
      <Helmet>
        <title>Our Leadership Team - Join the Fight Against Climate Change</title>
        <meta
          name="description"
          content="Discover how fishing communities are thriving despite climate challenges. Learn about our impactful environmental strategies for a sustainable future. Get involved with our cause for a healthier planet."
        />
      </Helmet>
      <div className="w-full bg-white-a700">
        <div className="flex flex-col items-center">
          <Header />
        </div>
        <BoardMembers />
        {/* <div className="flex justify-center self-stretch bg-white-a700 pt-[1.50rem] pb-[6.50rem] lg:py-[2.00rem] md:py-[1.25rem] sm:py-[1.00rem]">
          <div className="container-xs mt-[5.75rem] flex justify-center lg:p-[1.25rem] md:p-[1.25rem]">
            <div className="flex w-full flex-col gap-[4.13rem] sm:gap-[2.06rem]">
              <div className="mx-[13.63rem] flex flex-col items-center gap-[1.13rem] px-[3.50rem] md:mx-0 md:px-[1.25rem] sm:px-[1.00rem]">
                <Text as="h1" className="lg:text-[3.00rem]">
                  Our leadership team
                </Text>
                <Text size="text3xl" as="p" className="!text-gray-800_01">
                  The senior leaders who drive the strategic vision of our global organization.
                </Text>
              </div>
              <div className="flex gap-[1.50rem] md:flex-col">
                <div className="flex w-full flex-col items-center gap-[1.50rem] rounded-[30px] bg-white-a700 px-[3.50rem] shadow-xs md:px-[1.25rem] sm:px-[1.00rem]">
                  <Img
                    src="../../images/img_ellipse_28.png"
                    alt="Member Image"
                    className="mt-[1.88rem] h-[18.75rem] w-[18.75rem] rounded-[150px] object-cover"
                  />
                  <div className="flex flex-col gap-[0.63rem] self-stretch">
                    <div className="flex flex-col items-center px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                      <Heading size="headinglg" as="h3" className="tracking-[0.00rem] !text-blue-900">
                        Fred Krupp
                      </Heading>
                      <Text size="textmd" as="p">
                        President
                      </Text>
                    </div>
                    <Text size="textmd" as="p" className="text-center leading-[140%]">
                      Fred Krupp has guided EDF for three decades. Under his leadership, EDF has become one of the
                      world&#39;s most influential environmental organizations.
                    </Text>
                  </div>
                </div>
                <div className="flex w-full flex-col items-center gap-[1.38rem] rounded-[30px] bg-white-a700 px-[3.50rem] shadow-xs md:px-[1.25rem] sm:px-[1.00rem]">
                  <Img
                    src="../../images/img_ellipse_28_300x300.png"
                    alt="Member Image"
                    className="mt-[1.88rem] h-[18.75rem] w-[18.75rem] rounded-[150px] object-cover"
                  />
                  <div className="flex flex-col gap-[0.63rem] self-stretch">
                    <div className="flex flex-col items-center px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                      <Heading size="headinglg" as="h3" className="tracking-[0.00rem] !text-blue-900">
                        Amanda Leland
                      </Heading>
                      <Text size="textmd" as="p">
                        Executive Director
                      </Text>
                    </div>
                    <Text size="textmd" as="p" className="text-center leading-[140%]">
                      Amanda Leland oversees all aspects of our global organization, ensuring we are well-positioned
                      to achieve ambitious climate action that helps stabilize the climate.
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="self-stretch bg-text_and_main-white px-[3.50rem] py-[5.00rem] lg:py-[2.00rem] md:p-[1.25rem] sm:p-[1.00rem]">
          <div className="flex flex-col items-center gap-[3.75rem] sm:gap-[1.88rem]">
            <div className="flex flex-col items-center gap-[2.50rem] self-stretch">
              <div className="container-xs flex flex-col items-center px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
              </div>
              <div className="flex flex-col items-center gap-[3.75rem] self-stretch sm:gap-[1.88rem]">
                <div className="grid grid-cols-4 justify-center gap-[1.88rem] gap-y-[3.75rem] self-stretch px-[5.38rem] lg:grid-cols-4 lg:px-[2.00rem] md:grid-cols-2 md:px-[1.25rem] sm:grid-cols-1 sm:px-[1.00rem]">
                  <Suspense fallback={<div>Loading feed...</div>}>
                    {teamMembersGrid1.map((d, index) => (
                      <UserProfile10 {...d} key={"projectsGrid1" + index} />
                    ))}
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="self-stretch bg-white-a700 px-[3.50rem] py-[5.00rem] lg:py-[2.00rem] md:p-[1.25rem] sm:p-[1.00rem]">
          <div className="flex flex-col items-center gap-[3.75rem] sm:gap-[1.88rem]">
            <div className="flex flex-col items-center gap-[2.50rem] self-stretch">
              <div className="container-xs flex flex-col items-center px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
                </div>
              <div className="flex flex-col items-center gap-[3.75rem] self-stretch sm:gap-[1.88rem]">
                <div className="grid grid-cols-4 justify-center gap-[1.88rem] gap-y-[3.75rem] self-stretch px-[5.38rem] lg:grid-cols-4 lg:px-[2.00rem] md:grid-cols-2 md:px-[1.25rem] sm:grid-cols-1 sm:px-[1.00rem]">
                  <Suspense fallback={<div>Loading feed...</div>}>
                    {teamMembersGrid.map((d, index) => (
                      <UserProfile10 {...d} key={"projectsGrid1" + index} />
                    ))}
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div> */}
   
        <div className=' mt-[2.5rem] lg:mt-[2rem]'>
        <Footer />
          </div> 
      </div>
    </>
  )
}