import { Img, Heading, Text } from "./..";
import React from "react";

export default function EventDetails({
  eventTitle_1 = "YOUNGO Panel: Engaging With Elected Officials on a Global Level",
  eventTimeLocation_1 = "13:00 - 13:45 GST, Room Ajman D, Conference of Youth (COY18)",
  eventDescription_1 = "Panel discussion with three YOUNGO members on what it looks like to engage elected officials around climate issues and how to advocate for solutions regardless of location.",
  eventDetailsLinkText_1 = "See full event details",
  eventTitle = "Ecological Civilization and Beautiful China Practice",
  eventTimeLocation = "9:00 - 10:30 GST, China Pavilion",
  eventDescription = "This session will introduce China’s policies and actions to address climate change and tell China’s story in an international language.",
  eventDetailsLinkText = "See full event details",

  ...props
}) {
  return (
    <div {...props} className={`${props.className} flex flex-col items-center gap-[1.25rem] flex-1`}>
      <div className="flex flex-col gap-[1.63rem] self-stretch">
        <div className="flex flex-col items-center gap-[0.63rem] px-[3.50rem] md:px-[1.25rem]">
          <Text size="text5xl" as="p" className="!text-blue-900">
            {eventTitle}
          </Text>
          <Text size="textmd" as="p" className="!text-gray-600_01">
            {eventTimeLocation}
          </Text>
        </div>
        <Heading as="p" className="text-center !font-normal leading-[140%] !text-gray-800_01">
          {eventDescription}
        </Heading>
      </div>
      <div className="flex">
        <Heading size="headings" as="h5">
          {eventDetailsLinkText}
        </Heading>
        <Img
          src="images/img_arrow_down_blue_gray_900_02_30x30.svg"
          alt="Arrow Image"
          className="h-[1.88rem] w-[1.88rem]"
        />
      </div>
    </div>
  );
}
