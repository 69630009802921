import { Heading, Img, Text } from "./..";
import React from "react";

export default function FeatureCard({
  websitetext1 = "website",
  climateVulnerabilityText = "The U.S. Climate Vulnerability Index",
  websitetext2 = "website",
  fleetElectrificationText = "Fleet Electrification Solutions Center",
  collectionText = "COLLECTION",
  naturalClimateText = (
    <>
      Natural Climate Solutions Crediting
      <br />
      Handbook
    </>
  ),
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col w-[32%] md:w-full gap-[2.50rem] px-[1.88rem] sm:px-[1.00rem]`}
    >
      <div className="flex flex-col items-end self-stretch rounded-[20px] bg-white-a700 px-[1.25rem] py-[1.50rem] shadow-lg sm:py-[1.25rem]">
        <Img
          src="images/img_arrowleft_gray_600.svg"
          alt="Arrow Image Left"
          className="relative z-[2] h-[0.88rem] w-[0.88rem]"
        />
        <div className="relative mb-[0.75rem] mt-[-0.13rem] flex flex-col items-start gap-[0.38rem] self-stretch">
          <Text size="textmd" as="p" className="uppercase tracking-[0.00rem] !text-blue-900">
            {websitetext1}
          </Text>
          <Heading as="p">{climateVulnerabilityText}</Heading>
        </div>
      </div>
      <div className="flex flex-col items-end self-stretch rounded-[20px] bg-white-a700 px-[1.25rem] py-[1.50rem] shadow-lg sm:py-[1.25rem]">
        <Img
          src="images/img_arrowleft_gray_600.svg"
          alt="Arrow Image Right"
          className="relative z-[3] h-[0.88rem] w-[0.88rem]"
        />
        <div className="relative mb-[0.88rem] mt-[-0.13rem] flex flex-col items-start gap-[0.38rem] self-stretch">
          <Text size="textmd" as="p" className="uppercase tracking-[0.00rem] !text-blue-900">
            {websitetext2}
          </Text>
          <Heading as="p">{fleetElectrificationText}</Heading>
        </div>
      </div>
      <div className="self-stretch rounded-[20px] bg-white-a700 p-[1.25rem] shadow-lg">
        <div className="flex flex-col items-center gap-[0.38rem]">
          <div className="flex items-start justify-between gap-[1.25rem] self-stretch">
            <Text size="textmd" as="p" className="self-center uppercase tracking-[0.00rem] !text-blue-900">
              {collectionText}
            </Text>
            <Img
              src="images/img_arrow_left_gray_600_4.png"
              alt="Collection Arrow Image"
              className="h-[0.88rem] object-cover"
            />
          </div>
          <Heading as="p" className="leading-[150%]">
            {naturalClimateText}
          </Heading>
        </div>
      </div>
    </div>
  );
}
