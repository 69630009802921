import { Text, Img, Heading } from "./..";
import React from "react";

export default function StatementRelease({
  statementDescription = "EDF Statement regarding the release of a draft equivalency agreement between Environment and Climate Change Canada and Saskatchewan on oil and gas methane emissions",
  releaseDate = "July 12, 2024",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} flex flex-col items-start gap-[0.50rem] flex-1`}>
      <div className="relative h-[9.38rem] content-center self-stretch">
        <Heading as="p" className="mx-auto w-full leading-[150%]">
          {statementDescription}
        </Heading>
        <Img
          src="images/img_arrowleft_gray_600.svg"
          alt="Arrow Image"
          className="absolute bottom-[0.50rem] left-1/4 m-auto h-[0.88rem] w-[0.88rem]"
        />
      </div>
      <Text size="textmd" as="p" className="capitalize tracking-[0.00rem] !text-blue-900">
        {releaseDate}
      </Text>
    </div>
  );
}
