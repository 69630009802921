import React from "react";

const sizes = {
  textlg: "text-[1.25rem] font-medium lg:text-[1.06rem]",
  headingxs: "text-[1.13rem] font-semibold lg:text-[0.94rem]",
  headings: "text-[1.25rem] font-semibold lg:text-[1.06rem]",
  headingmd: "text-[1.38rem] font-semibold lg:text-[1.13rem]",
  headinglg: "text-[1.50rem] font-semibold lg:text-[1.25rem] md:text-[1.38rem]",
  headingxl: "text-[1.63rem] font-semibold lg:text-[1.38rem] md:text-[1.50rem] sm:text-[1.38rem]",
};

const Heading = ({ children, className = "", size = "textlg", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-blue_gray-900_02 font-poppins ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
