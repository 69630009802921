import React from "react";
import { useRoutes } from "react-router-dom";
import NotFound from "pages/NotFound";
import HomePage from "pages/HomePage";
import AboutPage from "pages/AboutPage";
import ClimateSmartAgriculture from "pages/AboutPage/ClimateSmartAgriculture";
import OurLeadershipTeam from "pages/AboutPage/OurLeadershipTeam";
import OurBoard from "pages/AboutPage/OurBoard";
import OurTeam from "pages/AboutPage/OurTeam";
import EventsPage from "pages/EventsPage";
import ArticlePage from "pages/ArticlePage/module-1ArticleP";
import ArticlePageSliderOne from "pages/ArticlePage/articlepage";
import OurWorkDropDown from "pages/AboutPage/ourWorkDropdown";
import StaffMembers from "pages/AboutPage/module-1StaffMember";
import Careers from "pages/CareersPage/Careers";
import CareerDetails from "pages/CareersPage/CareerDetails";


const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <HomePage /> },
    { path: ":category/articles/:name", element:<ArticlePageSliderOne /> },
    { path: "/about-us", element: <AboutPage /> },
    { path: "/our-work", element: <ClimateSmartAgriculture /> },
    // { path: "/our-work-drop-down", element: <OurWorkDropDown /> },
    { path: "/edif/ourwork/:name", element: <OurWorkDropDown /> },
    { path: "/board-members", element:<OurLeadershipTeam /> },
    // { path: "/our-team", element:<StaffMembers /> },
    { path: "/staff-members", element:<StaffMembers /> },
    { path: "/get-involved", element:<OurBoard /> },
    { path: "/about/ourTeam", element:<OurTeam /> },
    { path: "/new-and-stories", element:<EventsPage /> },
    {path:"/careers",element:<Careers/>},
    {path:"/careers/:jobID",element:<CareerDetails/>},

    // { path: "/about", element: <AboutPage /> },
    // { path: "/ourWork/climatesmartagriculture", element: <ClimateSmartAgriculture /> },
    // { path: "/ourLeadershipTeam", element:<OurLeadershipTeam /> },
    // { path: "/ourBoard", element:<OurBoard /> },
    // { path: "/about/ourTeam", element:<OurTeam /> },
    // { path: "/events", element:<EventsPage /> },
    // { path: "about/:category/article/:name", element:<ArticlePageAboutP /> },
    // { path: ":category/article/:name", element:<ArticlePageSliderOne /> },
    // { path: "/article/contentSliderOne", element:<ArticlePageSliderOne /> },
    // { path: "/article/contentSliderTwo", element:<ArticlePageSliderOne /> },
    // { path: "/article/contentSliderThree", element:<ArticlePageSliderOne /> },
    { path: "", element: <NotFound /> },
  ]);

  return element;
};

export default ProjectRoutes;
