import { Img, Text } from "./..";
import React from "react";

export default function UserProfile8({ userQuestion = "What is EDF&#39;s taxpayer ID number?", ...props }) {
  return (
    <div {...props} className={`${props.className} flex sm:flex-col items-center flex-1`}>
      <Text size="text3xl" as="p" className="!font-medium !text-blue-900">
        {userQuestion}
      </Text>
      <Img src="images/img_frame_blue_900_48x48.svg" alt="Decorative Image" className="h-[3.00rem] w-[3.00rem]" />
    </div>
  );
}
