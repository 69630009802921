import { Helmet } from "react-helmet";
import { Button, Img, Text, Heading, TextArea, Input } from "../../components";
import AdvisoryButtons from "../../components/AdvisoryButtons";
import AnnualReportButtons from "../../components/AnnualReportButtons";
import BlogSection from "../../components/BlogSection";
import BusinessCard from "../../components/BusinessCard";
import ButtonList from "../../components/ButtonList";
import ContactCard from "../../components/ContactCard";
import DateNavigation from "../../components/DateNavigation";
import EventDetails from "../../components/EventDetails";
import ExpertReviewSection from "../../components/ExpertReviewSection";
import FeatureCard from "../../components/FeatureCard";
import FeatureCard1 from "../../components/FeatureCard1";
import FeatureCardList from "../../components/FeatureCardList";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import InfoCard from "../../components/InfoCard";
import LocalBusinessProfile from "../../components/LocalBusinessProfile";
import MercuryStandardsInfo from "../../components/MercuryStandardsInfo";
import NaturalSolutionsProfile from "../../components/NaturalSolutionsProfile";
import OpEdArticle from "../../components/OpEdArticle";
import PodcastProfile from "../../components/PodcastProfile";
import PresidentBidenProfile from "../../components/PresidentBidenProfile";
import StatementRelease from "../../components/StatementRelease";
import StatisticsDisplay from "../../components/StatisticsDisplay";
import UserProfile from "../../components/UserProfile";
import UserProfile1 from "../../components/UserProfile1";
import UserProfile10 from "../../components/UserProfile10";
import UserProfile2 from "../../components/UserProfile2";
import UserProfile3 from "../../components/UserProfile3";
import UserProfile4 from "../../components/UserProfile4";
import UserProfile5 from "../../components/UserProfile5";
import UserProfile6 from "../../components/UserProfile6";
import UserProfile7 from "../../components/UserProfile7";
import UserProfile8 from "../../components/UserProfile8";
import UserProfile9 from "../../components/UserProfile9";
import UserProfileCard from "../../components/UserProfileCard";
import UserProfileList from "../../components/UserProfileList";
import VitalSignsProfile from "../../components/VitalSignsProfile";
import React, { Suspense } from "react";

const EventsPage = () => {

    const dateSelectionList = [
        { nov26: "Nov 26", nov29: "Nov 29", novCounterText: "Nov 30", decCounterText: "Dec 1" },
        { nov26: "Dec 2", nov29: "Dec 3", novCounterText: "Dec 4", decCounterText: "Dec 5" },
        { nov26: "Dec 6", nov29: "Dec 8", novCounterText: "Dec 9", decCounterText: "Dec 10" },
      ];
    
      const eventDetailsList = [
        {
          eventTitle: "Ecological Civilization and Beautiful China Practice",
          eventTimeLocation: "9:00 - 10:30 GST, China Pavilion",
          eventDescription:
            "This session will introduce China’s policies and actions to address climate change and tell China’s story in an international language.",
          eventDetailsLinkText: "See full event details",
        },
        {
          eventTitle: "Ecological Civilization and Beautiful China Practice",
          eventTimeLocation: "9:00 - 10:30 GST, China Pavilion",
          eventDescription:
            "Co-hosted by the Policy Research Center for Environment and Economy, Ministry of Ecology and Environment of the People’s Republic of China, Natural Resources Defense Council’s Beijing Representative Office, and EDF’s Beijing Representative Office, this event will have researchers and experts from universities and institutes, governments, and nongovernmental organizations, discussing China’s policies and actions for addressing climate change.",
          eventDetailsLinkText: "See full event details",
        },
        {
          eventTitle: "Ecological Civilization and Beautiful China Practice",
          eventTimeLocation: "9:00 - 10:30 GST, China Pavilion",
          eventDescription:
            "This event spotlights human rights and the role of communities in shaping a rights-based approach on ocean conservation. It brings attention to tenure rights, the importance of inclusionary local governance, and the significance of sustainable management of marine ecosystems for coastal communities well-being, livelihoods, and food security. It will serve to foster a comprehensive understanding of overlooked perspectives from women, local communities and Indigenous people from the Global South who are reliant on ocean resources for their livelihoods.",
          eventDetailsLinkText: "See full event details",
        },
      ];
  return (
    <div>
      <div className="flex flex-col items-center">
          <div className="self-stretch bg-text_and_main-white">      
                  <Header />
            </div>
            <div className="flex justify-center self-stretch bg-text_and_main-white py-[5.63rem] lg:py-[2.00rem] md:py-[1.25rem] sm:py-[1.00rem]">
            <div className="container-xs mt-[6.75rem] flex justify-center px-[3.50rem] lg:p-[1.25rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
              <div className="flex w-[80%] flex-col gap-[3.25rem] lg:w-full md:w-full sm:gap-[1.63rem]">
                <div className="flex flex-col items-center gap-[1.88rem]">
                  <Text as="h1" className="!text-color_black-900 lg:text-[3.00rem]">
                    EDF at COP28: Schedule of events
                  </Text>
                  <Text size="text3xl" as="p" className="self-stretch text-center leading-[140%] !text-gray-800_01">
                    COP28 will be held in Dubai from Nov. 30 to Dec. 12, 2023. Environmental Defense Fund is working
                    with partners to drive climate action at the events below. Please note, all sessions organized by
                    EDF are highlighted.
                  </Text>
                  <div className="flex justify-center self-stretch px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                    <div className="flex gap-[1.25rem] px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                      <Button
                        color="gray_100_01"
                        shape="round"
                        rightIcon={
                          <Img
                            src="images/img_arrowleft_gray_600.svg"
                            alt="Arrow Left"
                            className="h-[0.00rem] lg:h-[0.88rem] w-[0.00rem] lg:w-[0.88rem]"
                          />
                        }
                        className="min-w-[14.50rem] gap-[0.63rem] !rounded-[16px]"
                      >
                        Our work at COP28
                      </Button>
                      <Button
                        color="gray_100_01"
                        shape="round"
                        rightIcon={
                          <Img
                            src="images/img_arrowleft_gray_600.svg"
                            alt="Arrow Left"
                            className="h-[0.00rem] lg:h-[0.88rem] w-[0.00rem] lg:w-[0.88rem]"
                          />
                        }
                        className="min-w-[12.88rem] gap-[0.63rem] !rounded-[16px]"
                      >
                        Meet your team
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center gap-[1.00rem] px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                  <Text size="text6xl" as="h2" className="!text-blue-900 md:text-[2.00rem] sm:text-[1.63rem]">
                    View events by day:
                  </Text>
                  <div className="flex w-[76%] flex-col gap-[1.25rem] px-[1.63rem] lg:w-full md:w-full sm:px-[1.00rem]">
                    <div className="flex flex-col gap-[1.25rem]">
                      <Suspense fallback={<div>Loading feed...</div>}>
                        {dateSelectionList?.map((d, index) => (
                          <DateNavigation {...d} key={"dayList" + index} />
                        ))}
                      </Suspense>
                    </div>
                    <div className="flex justify-center gap-[1.88rem] px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                      <Button
                        shape="round"
                        rightIcon={
                          <Img
                            src="images/img_arrowleft.svg"
                            alt="Arrow Left"
                            className="h-[0.00rem] lg:h-[0.88rem] w-[0.00rem] lg:w-[0.88rem]"
                          />
                        }
                        className="min-w-[8.75rem] gap-[0.63rem] !rounded-[16px]"
                      >
                        Dec 11
                      </Button>
                      <Button
                        shape="round"
                        rightIcon={
                          <Img
                            src="images/img_arrowleft_gray_600.svg"
                            alt="Arrow Left"
                            className="h-[0.00rem] lg:h-[0.88rem] w-[0.00rem] lg:w-[0.88rem]"
                          />
                        }
                        className="min-w-[8.75rem] gap-[0.63rem] !rounded-[16px]"
                      >
                        Dec 12
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div className="flex justify-center self-stretch bg-gradient2 py-[3.25rem] md:py-[1.25rem] sm:py-[1.00rem]">
            <div className="container-xs mb-[6.63rem] flex justify-center lg:p-[1.25rem] md:p-[1.25rem]">
              <div className="flex w-full flex-col gap-[3.63rem] sm:gap-[1.81rem]">
                {/* <div className="flex items-center justify-center gap-[1.25rem] md:flex-col">
                  <div className="h-[0.06rem] w-full bg-blue_gray-400" />
                  <Text as="h1" className="!text-color_black-900 lg:text-[3.00rem]">
                    Thursday, November 30
                  </Text>
                  <div className="h-[0.06rem] w-full bg-blue_gray-400" />
                </div> */}
                <div className="flex items-center justify-center gap-[2.50rem] md:flex-col">
                  <div className="h-[0.06rem] flex-1 bg-blue_gray-400 md:self-stretch" />
                  <Text as="h1" className="!text-color_black-900 lg:text-[3.00rem]">
                  Sunday, November 26
                  </Text>
                  <div className="h-[0.06rem] flex-1 bg-blue_gray-400 md:self-stretch" />
                </div>
                <div className="mx-[13.50rem] flex flex-col gap-[3.13rem] md:mx-0">
                  <Suspense fallback={<div>Loading feed...</div>}>
                    {eventDetailsList?.map((d, index) => (
                      <>
                        <EventDetails {...d} key={"ecologicalList" + index} />
                        {index < eventDetailsList?.length - 1 ? (
                          <div className="h-[0.00rem] w-[2.81rem] bg-gray-300_01" />
                        ) : null}
                      </>
                    ))}
                  </Suspense>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center self-stretch bg-white-a700 py-[3.63rem] md:py-[1.25rem] sm:py-[1.00rem]">
            <div className="container-xs mt-[1.00rem] flex justify-center lg:p-[1.25rem] md:p-[1.25rem]">
              <div className="flex w-full flex-col gap-[3.63rem] sm:gap-[1.81rem]">
                <div className="flex items-center justify-center gap-[2.50rem] md:flex-col">
                  <div className="h-[0.06rem] flex-1 bg-blue_gray-400 md:self-stretch" />
                  <Text as="h1" className="!text-color_black-900 lg:text-[3.00rem]">
                    Wednesday, November 29
                  </Text>
                  <div className="h-[0.06rem] flex-1 bg-blue_gray-400 md:self-stretch" />
                </div>
                <div className="mx-[9.38rem] flex flex-col gap-[1.75rem] md:mx-0">
                  <div className="flex flex-col items-center gap-[1.25rem]">
                    <div className="flex flex-col items-center gap-[1.63rem] self-stretch px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                      <div className="mx-[8.50rem] flex w-[88%] flex-col items-center gap-[0.50rem] lg:w-full md:mx-0 md:w-full">
                        <Button
                          color="amber_300_33"
                          shape="round"
                          leftIcon={
                            <Img
                              src="images/img_settings_light_blue_a200.svg"
                              alt="Settings"
                              className="h-[0.06rem] lg:h-[1.50rem] w-[0.06rem] lg:w-[1.50rem]"
                            />
                          }
                          className="min-w-[14.75rem] gap-[0.63rem] !rounded-[16px] font-medium"
                        >
                          Organized by EDF
                        </Button>
                        <Text
                          size="text5xl"
                          as="h3"
                          className="self-stretch text-center leading-[140%] !text-blue-900 md:text-[1.75rem] sm:text-[1.38rem]"
                        >
                          S&P Global Commody Insights COP28 Forum | <br /> Energy Transition: How to Power Your Progress
                        </Text>
                        <Text size="textmd" as="p" className="!text-gray-600_01">
                          12:00 - 18:00 GST, Hilton Palm Jumeirah
                        </Text>
                      </div>
                      <Heading
                        as="h2"
                        className="self-stretch text-center !font-normal leading-[140%] !text-gray-800_01"
                      >
                        As energy markets continue to evolve, a lot hangs in the balance. The world needs a new energy
                        system that’s secure, sustainable and affordable. Join us for an afternoon of insights and
                        discussion into how you can be part of the energy solution. For 2023, the event will focus on
                        the theme of energy transition: strategic choices and demonstrating progress.
                      </Heading>
                    </div>
                    <div className="flex">
                      <Heading size="headings" as="h3">
                        See full event details
                      </Heading>
                      <Img
                        src="images/img_arrow_down_blue_gray_900_02_30x30.svg"
                        alt="Details Arrow"
                        className="h-[1.88rem] w-[1.88rem]"
                      />
                    </div>
                  </div>
                  <div className="flex flex-col items-center gap-[0.38rem] px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                    <div className="flex">
                      <Heading size="headingxs" as="h4">
                        Opening remarks:
                      </Heading>
                    </div>
                    <Text size="textmd" as="p" className="leading-[140%] !text-gray-800_01">
                      <>
                        Simon Stiell, Executive Secretary, United Nations Framework Convention on Climate Change
                        <br />
                        Sultan Al Jaber, President, United Nations Conference on Climate Change in Dubai
                        <br />
                        Xie Zhenhua, Special Envoy of China on Climate Change
                      </>
                    </Text>
                  </div>
                  <div className="flex flex-col items-center gap-[0.38rem] px-[3.50rem] md:px-[1.25rem] sm:px-[1.00rem]">
                    <div className="flex">
                      <Heading size="headingxs" as="h5">
                        Keynote Speakers:
                      </Heading>
                    </div>
                    <Text size="textmd" as="p" className="w-[92%] leading-[140%] !text-gray-800_01 lg:w-full md:w-full">
                      <>
                        Fred Krupp, President, EDF
                        <br />
                        Hannah Hauman, Global Head of Carbon Trading, Trafigura Group Pte. Ltd.
                        <br />
                        Brad Crabtree, Assistant Secretary for Fossil Energy and Carbon Management, U.S. Department of
                        Energy
                        <br />
                        Marie-Louise Du Bois, Global Head of Carbon Pricing, Energy Transition, S&P Global Commodity
                        Insights (Moderator)
                      </>
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center self-stretch bg-gradient2 py-[3.25rem] md:py-[1.25rem] sm:py-[1.00rem]">
            <div className="container-xs mb-[6.63rem] flex justify-center lg:p-[1.25rem] md:p-[1.25rem]">
              <div className="flex w-full flex-col gap-[3.63rem] sm:gap-[1.81rem]">
                {/* <div className="flex items-center justify-center gap-[1.25rem] md:flex-col">
                  <div className="h-[0.06rem] w-full bg-blue_gray-400" />
                  <Text as="h1" className="!text-color_black-900 lg:text-[3.00rem]">
                    Thursday, November 30
                  </Text>
                  <div className="h-[0.06rem] w-full bg-blue_gray-400" />
                </div> */}
                <div className="flex items-center justify-center gap-[2.50rem] md:flex-col">
                  <div className="h-[0.06rem] flex-1 bg-blue_gray-400 md:self-stretch" />
                  <Text as="h1" className="!text-color_black-900 lg:text-[3.00rem]">
                  Thursday, November 30
                  </Text>
                  <div className="h-[0.06rem] flex-1 bg-blue_gray-400 md:self-stretch" />
                </div>
                <div className="mx-[13.50rem] flex flex-col gap-[3.13rem] md:mx-0">
                  <Suspense fallback={<div>Loading feed...</div>}>
                    {eventDetailsList?.map((d, index) => (
                      <>
                        <EventDetails {...d} key={"ecologicalList" + index} />
                        {index < eventDetailsList?.length - 1 ? (
                          <div className="h-[0.00rem] w-[2.81rem] bg-gray-300_01" />
                        ) : null}
                      </>
                    ))}
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
    </div>
  )
}

export default EventsPage;
