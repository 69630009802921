import { Text, Img, Heading, Input } from "./..";
import React from "react";
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InputSubscribeTab from "./inputsubscribetab";

export default function Footer({ ...props }) {
  return (
    <footer {...props} className={`${props.className} flex self-stretch bg-white-a700`}>
      <div className="mb-[2.50rem] flex w-full flex-col items-center">
        <div className="relative self-stretch">
          <div className="relative z-[19] h-[6.13rem] lg:h-[4.13rem]">
            {/* <div className="absolute left-0 right-0 top-[45%] m-auto h-[0.06rem] w-full flex-1 bg-gray-200" /> */}
            {/* <div className="absolute bottom-[125px] left-0 right-0 top-0 m-auto flex h-max w-[38%] justify-center gap-[0.88rem] rounded-[60px] bg-blue-900 px-[1.88rem] py-[2.13rem] shadow-2xl md:relative md:flex-col sm:p-[1.00rem]">
              <Input
                name="Email Input"
                placeholder={`Enter your Email here...`}
                className="flex-grow rounded-[30px] !text-gray-800_01 md:p-[1.25rem]"
              />
              <div className="flex w-[42%] items-center justify-center gap-[0.56rem] rounded-[30px] bg-amber-300 p-[0.63rem] md:w-full md:p-[1.25rem]">
                <Heading size="headings" as="h5">
                  Subscribe Now
                </Heading>
                <Img src="../../images/img_arrow_blue_gray_900_02.svg" alt="Arrow Image" className="h-[2.38rem]" />
              </div>
            </div> */}
            <InputSubscribeTab />
          </div>
          {/* <div className="relative mt-[-4.50rem] flex items-center"> */}
          <div className="container-xs flex flex-col px-[0.50rem] md:p-[1.25rem] md:px-[1.25rem] sm:px-[0rem]">
            <div className="  items-end  gap-[3.69rem] my-[3.00rem]  lg:pr-[1.00rem] md:flex-col md:p-[1.25rem] sm:mb-[0rem] sm:p-[2rem]">
              <div className=" flex w-[100%] scroll-margin: 0px; items-start  gap-[5.5rem] md:mt-0 md:w-full md:flex-col md:p-[1.25rem] md:gap-[3.5rem] sm:gap-[1.5rem] sm:p-[0rem]">
                <div className="w-[20%] md:w-[100%] sm:w-[100%]">
                  <div className="flex md:justify-center" >
                    <Img
                      src="../../images/Frame logo.svg"
                      alt="Footer Image"
                      className=" mb-[3.00rem] lg:mb-[2.00rem]  w-[30%] md:w-[13%] self-start object-contain"
                    />
                    <Img
                      src="../../images/Frame name blue.svg"
                      alt="Footer Image"
                      className=" mb-[3.00rem] lg:mb-[2.00rem]  ml-[0.75rem]  w-[60%] md:w-[32%] self-start object-contain"
                    />
                  </div>
                  <div className="flex gap-[2rem] justify-center">
                    <p><TwitterIcon sx={{ fontSize: 27 }} /></p>
                    {/* <p><FacebookIcon sx={{ fontSize: 27 }} /></p> */}
                    {/* <p><InstagramIcon sx={{ fontSize: 27 }} /></p> */}
                    <p><LinkedInIcon sx={{ fontSize: 27 }} /></p>
                  </div>
                </div>

                <div className="flex w-[22%] flex-col items-start gap-[1.00rem]  md:w-full md:items-center md:gap-[0rem] sm:w-full">

                  <Text size="textxl" as="p" className="!text-gray-800_01  md:mb-[0.8rem] sm:mb-4">
                    About
                  </Text>
                  <Text size="textxl" as="p" className="!text-[1rem] leading-[1.2rem] sm:leading-[2rem] sm:leading-[2rem]  !font-semibold tracking-[0.00rem] !text-gray-900_01">
                    Commitment to diversity
                  </Text>
                  <Text size="textxl" as="p" className="!text-[1rem] leading-[1.2rem] sm:leading-[2rem] !font-semibold  tracking-[0.00rem] !text-gray-900_01">
                    Careers
                  </Text>
                  <Text size="textxl" as="p" className="!text-[1rem] leading-[1.2rem] sm:leading-[2rem] !font-semibold  tracking-[0.00rem] !text-gray-900_01">
                    Offices
                  </Text>
                </div>
                <div className="flex w-[22%] flex-col items-start gap-[1.00rem] md:w-full md:items-center md:gap-[0rem]">

                  <Text size="textxl" as="p" className="!text-gray-800_01  md:mb-[0.8rem]">
                    {/* News and resources */}
                    {/* News and articles */}
                    {/* News and Stories */}
                    Resources


                  </Text>
                  <Text size="textxl" as="p" className="!text-[1rem] leading-[1.2rem] sm:leading-[2rem] !font-semibold tracking-[0.00rem] !text-gray-900_01">
                    {/* News and updates */}
                    News and Stories
                  </Text>
                  <Text size="textxl" as="p" className="!text-[1rem] leading-[1.2rem] sm:leading-[2rem] !font-semibold tracking-[0.00rem] !text-gray-900_01">
                    {/* Reports and publications */}
                    {/* Knowledge resource */}
                    Reports and Publications
                  </Text>
                  <Text size="textxl" as="p" className="!text-[1rem] leading-[1.2rem] sm:leading-[2rem] !font-semibold tracking-[0.00rem] !text-gray-900_01">
                    For the media
                  </Text>
                </div>
                <div className="flex w-[22%] flex-col items-start gap-[1.00rem] md:w-full md:items-center md:gap-[0rem]">

                  {/* <Text size="textxl" as="p" className="!text-gray-800_01  md:mb-[0.8rem]">
                    Members
                  </Text>
                  <Text size="textxl" as="p" className="!text-[1rem] leading-[1.2rem] sm:leading-[2rem] !font-semibold tracking-[0.00rem] !text-gray-900_01">
                    Become a member
                  </Text>
                  <Text size="textxl" as="p" className="!text-[1rem] leading-[1.2rem] sm:leading-[2rem] !font-semibold tracking-[0.00rem] !text-gray-900_01">
                    Take action
                  </Text> */}
                  <Text size="textxl" as="p" className="!text-[1rem] leading-[1.2rem] sm:leading-[2rem] !font-semibold tracking-[0.00rem] !text-gray-900_01">
                    Contact
                  </Text>

                </div>
              </div>

            </div>

          </div>
        </div>
  
        <div className="hidden lg:hidden md:block sm:block border-b-2 border-black w-[80%] mb-[2rem] "><hr></hr> </div>
        <div className="container-xs  z-20 mt-[0.87rem] flex flex-col items-center   md:p-[1.25rem] md:px-[1.25rem] sm:px-[1.00rem]">
          <div className="flex w-[98%] items-start justify-center gap-[0.88rem] lg:w-full md:w-full md:flex-col md:text-center">
            <Text
              size="textxs"
              as="p"
              className="w-[30%] self-center leading-[150%] !text-gray-800_01 lg:w-[30%] md:w-full"
            >
              <a href="#" className="text-gray-800_01 underline">
                Copyright © 2024
              </a>
              <span className="text-gray-800_01"> Environmental Defense&nbsp;</span>
              <span className="text-gray-800_01">India&nbsp;</span>
              <span className="text-gray-800_01">
                <>
                  Fund. <br /><br></br>
                  All Rights Reserved.
                  <br />
                </>
              </span><br></br>
              {/* <a href="#" className="text-gray-800_01 underline">
                Privacy statement&nbsp;
              </a>
              <span className="text-gray-800_01">&nbsp;&nbsp;</span>
              <span className="text-gray-800_01">&nbsp;</span>
              <a href="#" className="text-gray-800_01 underline">
                <>
                  Terms of Use
                  <br />
                </>
              </a> */}
            </Text>
            <Text size="textxs" as="p" className="w-[70%] leading-[150%] !text-gray-800_01 lg:w-[70%] md:w-full">
              <span className="text-gray-800_01">Environmental Defense India Foundation (EDIF) is an independent, non-profit, tax-exempt legal entity established under Section 8 of the India Companies Act, 2013. It is headquartered in Delhi and operates charitable activities exclusively within India.&nbsp;</span>
              {/* <span className="text-gray-800_01">Environmental Defense&nbsp;</span>
              <span className="text-gray-800_01">India&nbsp;</span>
              <span className="text-gray-800_01">
                <>
                  Fund is a nonprofit, tax-exempt charitable organization under Section 501(c)(3) of the Internal
                  Revenue Code.
                   Donations are tax-deductible as allowed by law. Tax identification number 11-6107128.
                  <br /><br></br>
                  EDF operates with the highest
                </>
              </span> */}
              {/* <span className="text-gray-800_01">standards of ethics</span>
              <span className="text-gray-800_01">
                and open, honest communication. Read our whistleblower policy or report a concern through our
              </span>
              <a href="#" className="text-gray-800_01 underline">
                confidential, third-party compliance site
              </a>
              <a href="#" className="text-gray-800_01 underline">
                .
              </a> */}
            </Text>
          </div>
        </div>
      </div>
    </footer>
  );
}
