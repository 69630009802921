import { Button, Img } from "./..";
import React from "react";

export default function ButtonList({
  albertsonsButton = "Albertsons",
  americanRedCrossButton = "American Red Cross",
  bydButton = "BYD",
  cityOfHoustonButton = "City of Houston",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} flex md:flex-col items-center gap-[1.50rem] flex-1`}>
      <Button
        size="lg"
        rightIcon={
          <Img
            src="images/img_arrowleft.svg"
            alt="Arrow Left"
            className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
          />
        }
        className="w-full gap-[0.63rem] rounded-[30px]"
      >
        {albertsonsButton}
      </Button>
      <Button
        size="lg"
        rightIcon={
          <Img
            src="images/img_arrowleft_gray_600.svg"
            alt="Arrow Left"
            className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
          />
        }
        className="w-full gap-[0.63rem] rounded-[30px]"
      >
        {americanRedCrossButton}
      </Button>
      <Button
        size="lg"
        rightIcon={
          <Img
            src="images/img_arrowleft_gray_600.svg"
            alt="Arrow Left"
            className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
          />
        }
        className="w-full gap-[0.63rem] rounded-[30px]"
      >
        {bydButton}
      </Button>
      <Button
        size="lg"
        rightIcon={
          <Img
            src="images/img_arrowleft.svg"
            alt="Arrow Left"
            className="h-[0.00rem] h-[0.88rem] w-[0.00rem] w-[0.88rem]"
          />
        }
        className="w-full gap-[0.63rem] rounded-[30px]"
      >
        {cityOfHoustonButton}
      </Button>
    </div>
  );
}
